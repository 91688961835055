import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AppMainComponent } from './app-main.component';
import {ActivityLogComponent} from './components/activity-log/activity-log.component'
import { PeopleComponent } from './components/people/people.component';
import { ProjectdetailComponent } from './components/projectdetail/projectdetail.component';
import { HierarchyStructureComponent } from './components/hierarchy-structure/hierarchy-structure.component';
const internalRoutes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '', component: AppMainComponent,
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'logs', component: ActivityLogComponent, canActivate: [AuthGuardService] },
      { path: 'people', component: PeopleComponent, canActivate: [AuthGuardService] },
      { path: 'hierarchy', component: HierarchyStructureComponent, canActivate: [AuthGuardService] },
      { path: 'projectdetail/:id', component: ProjectdetailComponent, canActivate: [AuthGuardService]},
      // { path: 'callback', component: CallbackComponent },
      // { path: 'login', component: LoginComponent },      

      // { path: '**', redirectTo: '', pathMatch: 'full' },
      // { path: '*', redirectTo: '', pathMatch: 'full' },
    ]
  },
  { path: 'callback', component: CallbackComponent },

  //{ path: 'risk-deal-review', component: RiskDealReviewComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: '*', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(internalRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }