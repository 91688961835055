<div class="dx-content">
    <div class="dx-dashboard">
        <div class="header popup-section">
            <div class="changesupervisortext">
                <span class="material-symbols-outlined">
                    info
                    </span>
                   <ul>
                    <li>1) If employee has multiple project allocations, only project with highest allocation % will be displayed here</li>
                    <li>2) If employee is allocated equally across multiple projects, the first project allocated will be displayed here</li>
                    <li>3) If supervisor mapping for any employee needs to be updated, the project code owner displayed against the respective project can be reached offline to discuss changes</li>
                   
                   </ul>
                 
                </div>
        </div>
         <div class="container-fluid">
          <ng-container *ngIf="(ReportFormTemplate != null && ReportFormTemplate != undefined && ReportFormTemplate.length > 0)">
            <customform [FormTemplate]="ReportFormTemplate" [FormData]="ReportsFilterData"
            [FormProp]="FormPropReports" [FormConfig]="FormConfig" [GlobalConfig]="GlobalConfig"
                (FieldChange)="onChange($event)">
            </customform>
          </ng-container>
        </div>
        <div class="button-container" style="padding-top: 10px;">
          <button class="float-right flex-vert-align-center" (click)="filtersearch()"> Search</button>
        </div>
        <div *ngIf="(this.reportsvalidationinfo != null && this.reportsvalidationinfo != undefined)">
          <span class="requiredreports"> {{this.reportsvalidationinfo}}</span>
        </div>
        <div style="padding-bottom: 10px;"></div>
        <div class="button-container" *ngIf="(empListData != null && empListData != undefined && empListData.length > 0)">
          <button class="float-right flex-vert-align-center" (click)="downloadsupervisorhistorypopup()"> <span class="material-symbols-outlined">
            download
            </span>Download Supervisor History</button>
        </div>
            <div class="legend-info-wrap" *ngIf="(empListData != null && empListData != undefined && empListData.length > 0)">
        <ul>
          <li>Legends:</li>
        
         
            <li>
              <i class="icon-flipped fa fa-history"></i>
              Supervisor History</li>
        </ul>
      </div>
        <div *ngIf="(empListData != null && empListData != undefined && empListData.length > 0)" [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
                <app-griddisplay     class="last-child-col-freeze" *ngIf="(empListData != null && empListData != undefined && empListData.length > 0)"
                    #overallgrid 
                    [FieldTemplate]="empListTemplate" 
                    [GridData]="empListData"[GridDataOrg]="empListDataOrg"
                    [GridProp]="GridProp"
                    [IsPagination]="IsPagination"
                    [Isrecord]="Isrecord"
                    (onFilter)="onFilter($event)"
                    (FieldClick)="onClick($event)"
                    [GridDataTotalCount]="_gridTotalRecords" (PageChange)="onPageChange($event)" (SortingChange)="onSortingChange($event)"
                    (FieldChange)="onChange($event)">
                </app-griddisplay>           
    </div>
</div>
<modal-popup id="supervisorhistory" style="display: none;" [modaldetail]="modalInf">
    <div>
        <div>
            <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
                    <app-griddisplay    
                        #overallgrid 
                        [FieldTemplate]="supervisorhistoryTemplate" 
                        [GridData]="supervisorhistoryData"[GridDataOrg]="supervisorhistoryData"
                        [GridProp]="GridProp"
                        [IsPagination]="IsPagination"
                        [Isrecord]="Isrecord"
                        (onFilter)="onFilter($event)"
                        (FieldClick)="onClick($event)"
                        (FieldChange)="onChange($event)">
                    </app-griddisplay>               
        </div>
    </div>
</modal-popup>
  <modal-popup id="downloadsupervisorhistorypopup" style="display: none;" [modaldetail]="modalInf" (NoEvent)="closeModal('downloadsupervisorhistorypopup')" (YesEvent)="downloadsupervisorhistory()">
    <div class="add-adjust-emp-wrap">
      <div class="">
        <div class="">
          <div class="content">  
            <div class="body">
                <form class="row g-3">
                    <!-- <div class="" style="margin-top: 15px;"><label class="form-label"> Employee </label></div>
                    <div class="input-group" style="margin-top: 0px;">
                        <input type="text" class="form-control" placeholder="Employee GGID/Email" name="search" [(ngModel)]="searchEmployee">
                    </div> -->
                    <div class="col-md-6 mb-3">
                      <label for="searchtext" class="form-label"> SBU </label>
                      <input type="text" class="form-control" id="sbu" name="sbu" disabled="disabled"
                      [(ngModel)]="ReportsFilterData.SBUDesc" placeholder="" />
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="bu" class="form-label"> BU </label>
                      <input type="text" class="form-control" id="bu" name="bu" disabled="disabled"
                      [(ngModel)]="ReportsFilterData.BUDesc" placeholder="" />
                    </div>                    
                    <div class="col-md-6 mb-3">
                      <label for="searchby" class="form-label"> Search By </label>
                      <input type="text" class="form-control" id="searchby" name="searchby" disabled="disabled"
                      [(ngModel)]="ReportsFilterData.SearchByDesc" placeholder="" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="searchtext" class="form-label"> GGID/Email </label>
                        <input type="text" class="form-control" id="searchtext" name="searchtext" disabled="disabled"
                        [(ngModel)]="ReportsFilterData.SearchText" placeholder="" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="startdate" class="form-label"><span class="required">*</span> Start Date </label>
                        <input type="date" class="form-control" id="startdate" name="startdate" (input)="oninput($event, 'StartDateSelected')"
                        [(ngModel)]="StartDateSelected" [min]="StartDate" [max]="EndDate" placeholder="" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="enddate" class="form-label"><span class="required">*</span> End Date </label>
                        <input type="date" class="form-control" id="enddate" name="enddate" (input)="oninput($event, 'EndDateSelected')"
                        [(ngModel)]="EndDateSelected" [min]="StartDate" [max]="EndDate" placeholder="" />
                    </div>
                    <div *ngIf="(this.downloadvalidationinfo != null && this.downloadvalidationinfo != undefined)">
                    <span class="required_withBG"> {{this.downloadvalidationinfo}}</span>
                    </div>
                </form>
            </div>
            <!-- <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="closeModal('downloadsupervisorhistorypopup')">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="downloadsupervisorhistory()">Download Supervisor History</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>  
  </modal-popup>