import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environment/environment';
// import { FieldControlModule, ElioScopeService, ElioUtilityService } from 'fieldcontrol-lib';
import { FieldControlModule } from '../fieldcontrol/fieldcontrol.module';
import { ElioScopeService } from '../_services/ElioScope.service';
import { UtilityService } from '../_services/utility.service';
import { FormComponent } from './form.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [CommonModule, FieldControlModule,TooltipModule,
    TranslateModule.forRoot()
  ],
  declarations: [FormComponent],
  providers: [ElioScopeService, UtilityService],
  exports: [FormComponent]
})

export class FormModule { }
