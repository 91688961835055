import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgIdleModule } from '@ng-idle/core';
import { NgSelectModule } from '@ng-select/ng-select';

//Mat Libraries
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorModule} from '@angular/material/paginator';
import { HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Application libraries
import { HomeComponent } from './components/home/home.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CustomPipeModule } from '../modules/custompipe/custompipe.module';
import { AuthenticationService } from './auth/authentication.service';
import { AuthGuardService } from './auth/auth-guard.service';
// import { ToastService } from './sharedservices/toast.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomHttpInterceptor } from './auth/customhttpinterceptor';
//import { TranslateService } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CallbackComponent } from './callback/callback.component';
import { AppMainComponent } from './app-main.component';
import { AppSharedService, ClickOutsideCloseDirective } from './shared/app.sharedservice';
import { CommonAPIService } from './shared/app.commonservice';
import { HomeModule } from './components/home/home.module';
import { HomeService } from './components/home/home.service';
import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { ResizeService } from './screen-size-detector/resize.service';
import { GriddisplayModule } from 'src/modules/griddisplay/griddisplay.module';
import { OrderByPipe } from '../modules/custompipe/custompipe.module';
import { FormsModule } from '@angular/forms'; 
import { MenuBarModule } from 'src/modules/menubar/menubar.module';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormModule } from 'src/modules/form/form.module';
import { ModalModule } from 'src/modules/modal/modal.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ReviewSearchByModule } from 'src/modules/reviewsearchby/reviewsearchby.module';
import { MyReviewGriddisplayModule } from 'src/modules/myreviewgriddisplay/myreviewgriddisplay.module';
import { SessionModalComponent } from './session/session.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ComponentsModule } from './components/components.module';
import { ProjectdetailModule } from './components/projectdetail/projectdetail.module';
import { NotificationModule } from "../modules/notification/notification.module";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SwitchUserModule } from './switchuser/switchuser.module';
@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    AppMainComponent,
    HomeComponent,
    ClickOutsideCloseDirective, SessionModalComponent,
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,    
    MatMenuModule,    
    MatPaginatorModule,
    MatDatepickerModule,
    HttpClientModule,
    CommonModule,
    TooltipModule,
    CustomPipeModule,
    MenuBarModule,
    LoadingModule,
    ProjectdetailModule,
    ComponentsModule,
    NgMultiSelectDropDownModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true
      }
    }),

    NgIdleModule.forRoot(),
    HomeModule,
    SearchByModule,
    GriddisplayModule,
    SwitchUserModule,
    FormModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    ModalModule,
    NgSelectModule,
    ReviewSearchByModule,
    NotificationModule,
    MyReviewGriddisplayModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      autoDismiss: true,
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      enableHtml: true
    }),
    ],
    exports:[
      
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ HomeService,ResizeService, CommonAPIService, AppSharedService, AuthenticationService,AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: PathLocationStrategy
    // },
    // {
    //   provide: APP_BASE_HREF,
    //   useValue: '/' + (window.location.pathname.split('/')[1] || '')
    // },
    // CanDeactivateGuard,
    // ResizeService
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
