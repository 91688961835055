<!-- <div class="dx-content">
    <div class="dx-dashboard">
       
<div class="card-content"  *ngFor="let item of activityLogData"> -->
   <!-- <div class="icon-img" [attr.data-initials]="item.EmployeeInitials"> -->
   <!--  <img src="../../../assets/images/icon-add.png"/> -->
  
   <!-- </div> -->
   <!-- <div class="content-margin">
    <div class="d-flex">
    <p [ngClass]="item.Title == 'Delegate User' ? 'font-orange' : 'font-green'">{{item.Title}}</p>
    <span class="font-grey"><b>.</b></span>
    <p class="font-grey">{{item.Date}}</p>
    </div>
    <p class="description">{{item.Description}}</p>
   </div>
</div>

    </div>
</div>

<div>

</div> -->

<div class="dx-content">
   <div class="dx-dashboard">
       <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
               <app-griddisplay    
                   #overallgrid 
                   [FieldTemplate]="activityLogTemplate" 
                   [GridData]="activityLogData"[GridDataOrg]="activityLogData"
                   [GridProp]="GridProp"
                   [IsPagination]="IsPagination"
                   [Isrecord]="Isrecord"
                   (onFilter)="onFilter($event)"
                   (FieldClick)="onClick($event)"
                   (FieldChange)="onChange($event)">
               </app-griddisplay>           
   </div>
</div>


    <!--  Code for email template starts here -->

    <!-- <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#fff">
        <tr>
           <td align="center" valign="top">
              <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                 <tr>
                    <td class="td container" style="width:100%; min-width:100%; font-size:0pt; line-height:0pt; margin:0; font-weight:normal; padding:0px 0px 40px 0px;">
                                 
                       <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tr>
                           <td class="p5-15" style="padding: 5px 0; font-family:'Roboto', Arial,sans-serif; font-size:16px; line-height:24px; text-align:left;" bgcolor="#ffffff">
                              <table width="100%" border="0" cellspacing="0" cellpadding="1">
                                  <tr style="background: #003F61;">
                                      <td>
                                         <p style="color: #fff;font-size: 24px;text-align: center;padding: 10px 35px;">Supervisor Mapping</p>
                                      </td>
                                   </tr>
                                   </table>
                                   </td>
                                   </tr>
                                   </table>
                       
                                      
                       <table width="80%" border="0" cellspacing="0" cellpadding="0" style="margin: auto;">
                          <tr>
                             <td class="p5-15" style="padding: 5px 0; font-family:'Roboto', Arial,sans-serif; font-size:16px; line-height:24px; text-align:left;" bgcolor="#ffffff">
                                <table width="100%" border="0" cellspacing="0" cellpadding="1">
                                  
                                    <tr>
                                      <td style="padding: 15px 50px;text-align: justify;font-size: 16px;">
                                         <p>Dear User,</p>
                                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                      </td>
                                   </tr>
                                   <tr>
                                      <td>&nbsp;</td>
                                   </tr>
                                   <tr>
                                      <td style="padding-top:10px;">
                                         <table width="60%" cellspacing="0" cellpadding="3" style="margin: auto;border: 2px solid #ececec;padding: 10px;">
                                           
                                                <tr style="border-bottom: 2px solid #ececec;">
                                                    <p style="font-size: 16px;
                                                    padding: 10px;
                                                ">Delegate access status</p>
                                                </tr>
                                                                                           
                                                <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Name</td>
                                                    <td style="padding:10px;font-size: 14px;color:#0070ad">#Supervisor Mapping</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Start Date</td>
                                                    <td style="padding:10px;font-size: 14px;">24/05/1993</td>
                                                </tr>
                                                 <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Name</td>
                                                    <td style="padding:10px;font-size: 14px;">Supervisor Mapping</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Start Date</td>
                                                    <td style="padding:10px;font-size: 14px;">24/05/1993</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Name</td>
                                                    <td style="padding:10px;font-size: 14px;">Supervisor Mapping</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding:10px;font-size: 14px;">Project Start Date</td>
                                                    <td style="padding:10px;font-size: 14px;">24/05/1993</td>
                                                </tr>
                                           
                                           
                                         </table>
                                      </td>
                                   </tr>
                                   <tr>
                                    <hr>
                                   </tr>
                                   <tr>
                                      <td class="text-footer2" style="font-family:'Roboto', Arial,sans-serif; font-size:12px; line-height:26px; text-align:left; padding-top:15px; "> 
                                        Disclaimer:This is a system generated mail, please do not reply
                                    </td>
                                   </tr>
                                </table>
                             </td>
                          </tr>
                       </table>
                      
                    </td>
                 </tr>
              </table>
           </td>
        </tr>
     </table> -->

     <!--  Code for email template ends here -->


