import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '../../node_modules/@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { AuthenticationService } from './auth/authentication.service';
import { AppSharedService, isNullOrUndefined } from './shared/app.sharedservice';
import { authConfig } from './sso.config';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Supervisor Mapping';
  IsInternalUser: boolean = false;

  constructor(public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    public oauthService: OAuthService, public router: Router) {
    // debugger;
    this.configureSingleSignOn();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this._appSharedService.UserInfo))
      this.IsInternalUser = this._appSharedService.UserInfo.IsInternalUser;

  }

  configureSingleSignOn() {
    //debugger;
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.timeoutFactor = 0.75; // Token get refresh 75% (at 45th mins of every 1 hour)
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .then((hasReceivedTokens: any) => {

        // this would have stored all the tokens needed
        if (hasReceivedTokens) {
          // get claims on receiving the token successfully
          let claims: any = this.oauthService.getIdentityClaims();

          //let access_token: any = this.oauthService.getAccessToken();

          //let access_token: any = this.oauthService.getIdToken();

          console.log('token >> ' + JSON.stringify(claims));

          if (!isNullOrUndefined(claims)) {

            this._appSharedService.IsAuthUser = true;
            this._appSharedService.IsAuthUser$.next(this._appSharedService.IsAuthUser);

            this._appSharedService.setUserInfo(claims);

            // Get the API token
            this._authService.getAPIToken(claims.email, '', claims.GGID).then(result => {

              if (result) {
                this._appSharedService.PreviousURL = environment.appURLUI;
                this.router.navigate(['/home']);
                // window.location.href = window.location.href;
                // alert('Successfully switched user, Please refresh to proceed');
              }
              else {
                alert('Sorry, unable to complete the API token generation.');
                // show as invalid mailid
              }

            });
          }


          // carry on with your app
          return Promise.resolve();
        }
        else {
          // may want to check if you were previously authenticated
          if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            return Promise.resolve();
          } else {
            // to safe guard this from progressing through the calling promise,
            // resolve it when it directed to the sign up page
            return new Promise(resolve => {
              this.oauthService.initLoginFlow();
              // example if you are using explicit flow
              window.addEventListener('unload', () => {
                resolve(true);
              });
            });
          }
        }
      });
  }

  logout() {
    this.oauthService.logOut();
  }

}