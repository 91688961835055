<!-- <p-organizationChart [value]="data">
    <ng-template let-node pTemplate="default">
        <div class="header-sec">
            <p class="font-blue"><b>{{node.label}}</b></p>
            <p class="designation">{{node.data.Designation}}</p>
        </div>
        <div class="content-sec">
            <p>Employee Id : <span><b>{{node.data.EmployeeId}}</b></span></p>
            <p>Email :<span><b>{{node.data.Email}}</b></span></p>
            <p>PCO : <span><b>{{node.data.PCO}}</b></span></p>
        </div>
    </ng-template>
</p-organizationChart> -->

<!-- 
<div class="og-profile-wrap">
    <div class="og-parent-node-wrap">
        <div class="parent-profile">
            <div class="header-sec">
                <p class="name">Kishore</p>
                <p class="designation">Senior Manager</p>
            </div>
            <div class="content-sec">
                <p><span>Employee Id :</span> <span>xyz1234</span></p>
                <p><span>Email :</span><span>kishore@capgemini.com</span></p>
                <p><span>PCO :</span> <span>bharat lal man</span></p>
            </div>

        </div>
    </div>
    <div class="og-parent-node-wrap">
        <div class="parent-profile">
            <div class="header-sec">
                <p class="name">Vara</p>
                <p class="designation">Program Manager</p>
            </div>
            <div class="content-sec">
                <p><span>Employee Id :</span> <span>xyz1234</span></p>
                <p><span>Email :</span><span>kishore@capgemini.com</span></p>
                <p><span>PCO :</span> <span>bharat lal man</span></p>
            </div>

        </div>
    </div>
    <div class="og-parent-node-wrap">
        <div class="parent-profile">
            <div class="header-sec">
                <p class="name">Amit</p>
                <p class="designation">Manager</p>
            </div>
            <div class="content-sec">
                <p><span>Employee Id :</span> <span>xyz1234</span></p>
                <p><span>Email :</span><span>amit.a.agrahari@capgemini.com</span></p>
                <p><span>PCO :</span> <span>bharat lal man</span></p>
            </div>

        </div>ks
        <div class="og-child-node-wrap">
            <div class="child-profile">
                <div class="header-sec">
                    <p class="name">Sourabh Patrikar</p>
                    <p class="designation">Manager</p>
                </div>
                <div class="content-sec">
                    <p><span>Employee Id :</span> <span>xyz1234</span></p>
                    <p><span>Email :</span><span>sourabh.patrikar@capgemini.com</span></p>
                    <p><span>PCO :</span> <span>bharat lal man</span></p>
                </div>

            </div>


            <div class="child-profile">
                <div class="header-sec">
                    <p class="name">Sheeba Pauline</p>
                    <p class="designation">Senior Consultant</p>
                </div>
                <div class="content-sec">
                    <p><span>Employee Id :</span> <span>xyz1234</span></p>
                    <p><span>Email :</span><span>sheeba.pauline@capgemini.com</span></p>
                    <p><span>PCO :</span> <span>bharat lal man</span></p>
                </div>

            </div>



            <div class="child-profile">
                <div class="header-sec">
                    <p class="name">AKANSHA DUBEY</p>
                    <p class="designation">Senior Consultant</p>
                </div>
                <div class="content-sec">
                    <p><span>Employee Id :</span> <span>xyz1234</span></p>
                    <p><span>Email :</span><span>akansha.a.dubey@capgemini.com</span></p>
                    <p><span>PCO :</span> <span>bharat lal man</span></p>
                </div>

            </div>



            <div class="child-profile">
                <div class="header-sec">
                    <p class="name">Shankar Maddila</p>
                    <p class="designation">Senior Consultant</p>
                </div>
                <div class="content-sec">
                    <p><span>Employee Id :</span> <span>xyz1234</span></p>
                    <p><span>Email :</span><span>shankar.maddila@capgemini.com</span></p>
                    <p><span>PCO :</span> <span>bharat lal man</span></p>
                </div>

            </div>


        </div>
    </div>
</div> -->
<div class="dx-content">
<div class="dx-dashboard">
    <!-- <div class="button-container">
        <button class="float-right" (click)="downloadsupervisorhistorypopup()">Download Supervisor History</button>
    </div> -->
    <div class="d-flex justify-content-between ">
        <div class="legend-info-wrap">
            <ul>
                <li>Legends:</li>
              
                <li>
                  <i class="icon-flipped fa fa-sitemap"></i>
                  Hierarchy</li>
                  <li>
                    <i class="icon-flipped fa fa-history"></i>
                    Supervisor History</li>
              </ul>
          </div>
        <button *ngIf="(this._searchText != null && this._searchText != undefined)" class="cg-btn bluebtn flex-vert-align-center" (click)="GetSupervisorHierarchyToExcel()">
            <span class="material-symbols-outlined">
                download
                </span>
                <strong> Download Supervisor Hierarchy </strong></button>
    </div>
    <div class="hierarchy_search_wrap">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="Search by Employee GGID/Email" name="search" [(ngModel)]="_searchText">
            <div class="input-group-btn">
              <button class="btn btn-default bordergrey" type="submit" (click)="_onSearchEmployee()">
                <i class="fa fa-search inputgroupsearch" aria-hidden="true"
                  ></i></button>
            </div>
          </div>
    </div>
<ng-container *ngIf="_hierarchyData != null && _hierarchyData.length > 0">
    <div class="og-profile-wrap">
        <ng-container *ngFor="let item of _hierarchyData">
            <div class="og-parent-node-wrap">
                <div class="parent-profile">
                    <div class="header-sec">
                        <p class="name">{{item.EmployeeName}}</p>
                        <p class="designation">{{item.Designation}}</p>
                       
                        <div class="subR_sec">
                           <button class="surface-button-secondary">  <i aria-hidden="true" class="fa fa-history" (click)="supervisorhistory(item)"></i></button>
                            <button *ngIf="item.ReportiesCount != null && item.ReportiesCount > 0" class="icon-wrap-vert surface-button-secondary">
                            <i aria-hidden="true" class="fa fa-sitemap" (click)="_onclickEmployee('PARENT',item)"></i>
                            <span class="hierarchy-count">{{item.ReportiesCount}}</span>
                            </button></div>
                    </div>
                    <div class="content-sec">
                        <p><span>Employee GGID :</span> <span>{{item.GGID}}</span></p>
                        <p><span>Email :</span><span>{{item.Email}}</span></p>
                        <!-- <p><span>PCO :</span> <span>{{item.PCO}}</span></p> -->
                    </div>

                </div>
                <ng-container *ngIf="item.ReportiesList != null && item.ReportiesList.length > 0">
                    <div class="og-child-node-wrap">
                        <ng-container *ngFor="let child of item.ReportiesList">
                            <div class="child-profile">
                                <div class="header-sec">
                                    <p class="name">{{child.EmployeeName}} </p>
                                    <p class="designation">{{child.Designation}}</p>
                                    
                                    <div class="subR_sec">
                                        <button class="surface-button-secondary"> <i aria-hidden="true" class="fa fa-history" (click)="supervisorhistory(child)"></i></button>
                                       
                                        <button *ngIf="child.ReportiesCount != null && child.ReportiesCount > 0" class="icon-wrap-vert surface-button-secondary">
                                        <i aria-hidden="true" class="fa fa-sitemap" (click)="_onclickEmployee('CHILD',child)"></i>
                                    <span class="hierarchy-count">{{child.ReportiesCount}}</span>
                                    </button></div>
                                </div>
                                <div class="content-sec">
                                    <p><span>Employee GGID :</span> <span>{{child.GGID}}</span></p>
                                    <p><span>Email :</span><span>{{child.Email}}</span></p>
                                    <!-- <p><span>PCO :</span> <span>{{child.PCO}}</span></p> -->
                                </div>

                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="_hierarchyData == null || _hierarchyData.length == 0">
    <div class="no-records-found">
        No records found.
    </div>
</ng-container>

<div [hidden]="!IsLoading">
    <loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading>
</div></div>
</div>
<modal-popup id="supervisorhistory" style="display: none;" [modaldetail]="modalInf">
    <div>
        <div>
            <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
                    <app-griddisplay    
                        #overallgrid 
                        [FieldTemplate]="supervisorhistoryTemplate" 
                        [GridData]="supervisorhistoryData"[GridDataOrg]="supervisorhistoryData"
                        [GridProp]="GridProp"
                        [IsPagination]="IsPagination"
                        [Isrecord]="Isrecord"
                        (onFilter)="onFilter($event)"
                        (FieldClick)="onClick($event)"
                        (FieldChange)="onChange($event)">
                    </app-griddisplay>               
        </div>
    </div>
</modal-popup>
  <modal-popup id="downloadsupervisorhistorypopup" style="display: none;" [modaldetail]="modalInf">
    <div class="add-adjust-emp-wrap">
      <div class="">
        <div class="">
          <div class="content">  
            <div class="body">
                <form class="row g-3">
                    <div class="" style="margin-top: 15px;"><label class="form-label"><span class="required">*</span> Employee </label></div>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Employee GGID/Email" name="search" [(ngModel)]="searchEmployee">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="startdate" class="form-label"><span class="required">*</span> Start Date </label>
                        <input type="date" class="form-control" id="startdate" name="startdate" (input)="oninput($event, 'StartDateSelected')"
                        [(ngModel)]="StartDateSelected" [min]="StartDate" [max]="EndDate" placeholder="" />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="enddate" class="form-label"><span class="required">*</span> End Date </label>
                        <input type="date" class="form-control" id="enddate" name="enddate" (input)="oninput($event, 'EndDateSelected')"
                        [(ngModel)]="EndDateSelected" [min]="StartDate" [max]="EndDate" placeholder="" />
                    </div>
                    <div *ngIf="(this.downloadvalidationinfo != null && this.downloadvalidationinfo != undefined)">
                    <span class="required_withBG"> {{this.downloadvalidationinfo}}</span>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="closeModal('downloadsupervisorhistorypopup')">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="validatesupervisorhistory()">Download Supervisor History</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-popup>