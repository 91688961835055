import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReviewSearchByComponent } from './reviewsearchby.component';
import { SearchByService } from './searchby.service';
import { LoadingModule } from '../loading/loading.module';



@NgModule({
  imports: [CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    LoadingModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    NgbModule
  ],
  declarations: [ReviewSearchByComponent],
  providers: [SearchByService],
  exports: [ReviewSearchByComponent]
})
export class ReviewSearchByModule { }

