<!-- Modal section -->

<loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>

<div id="switchUserDialog" role="alert" class="modal modal-switch-user" tabindex="-1" style="display:block;"
  #dragboundary>
  <div class="modal-dialog modal-dialog-centered">
    <!-- Modal content -->
    <div class="ng-draggable modal-content" ngDraggable [inBounds]="true" [bounds]="dragboundary" [handle]="draghandle">
      <!-- Modal header -->
      <div class="modal-header" #draghandle>
        <h3 class="modal-title">
          Switch User
        </h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeSwitchUser()">

        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="dx-carry-form">
          <form id="loginform" class="form-horizontal ng-pristine ng-invalid ng-invalid-required" role="form"
            (keydown)="keyDown($event)">
            <div class="col-12">
              <div class="form-group">
                <label for="txtUName" class="form-label required">Email</label>
                <div class="input-group">
                  <div class="emailinpt">
                    <input type="text" name="txtUname" id="txtUName" class="form-control" placeholder="Please enter email" aria-label="Email" aria-describedby="basic-addon1" [(ngModel)]="emailid">
                  </div>
                  <div class="righticon" style="display:none;">
                    <span class="input-group-text" id="basic-addon1"><i class="ion ion-md-person"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="dx-box-btn righter">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal"
            (click)="closeSwitchUser()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="switchUser()">Submit</button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal !-->


<div class="modal-backdrop fade show"></div>