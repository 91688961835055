import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, AutoUnsubscribe } from 'src/app/shared/app.sharedservice';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { FieldTemplate } from 'src/modules/_classes/utility.interface';
import { HomeService } from '../home/home.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';

@AutoUnsubscribe
@Component({
  selector: 'app-hierarchy-structure',
  templateUrl: './hierarchy-structure.component.html',
  styleUrls: ['./hierarchy-structure.component.scss']
})
export class HierarchyStructureComponent implements OnInit {

  public IsLoading: boolean = false;
  public _hierarchyData: any = [];
  public subscriptionList$: Subscription[] = [];
  public _searchText: string = '';
  public selectedempData: any = {};
  public supervisorhistoryData: any[] = [];
  public supervisorhistoryTemplate: FieldTemplate[] = [];
  public modalInf: ModalInterface = new ModalInterface();
  public IsPagination: boolean = false;
  public templateHeader!: ComponentConfig;
  public Isrecord: boolean = false;
  public searchEmployee: any;
  public downloadvalidationinfo:any;
  public StartDate: any;
  public EndDate: any;
  public StartDateSelected: any;
  public EndDateSelected: any;
  public MonthsCount: any;
  public employeeexistscount: any;
  public exceluserid: any;
  public excelmode: any;
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    IsColResizable: false
  }

  constructor(public _appSharedService: AppSharedService, private _homeService: HomeService, private _commonAPIService: CommonAPIService, private modalService: ModalService, public datepipe: DatePipe) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      console.log("app shared");
      console.log(this._appSharedService.currentRoleData$);


      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit() {


    this._searchText = this._appSharedService.currentRoleData.ActingEmail;
    this.exceluserid = this._appSharedService.currentRoleData.UserId;
    this.excelmode = '';
    let odata: any = {
      UserId: this._appSharedService.currentRoleData.UserId, SearchText: this._searchText,Mode:''
    };
    this._GetHierarchyData(false,odata);
    
    this.StartDate = this._appSharedService.currentRoleData.StartDate;
    this.EndDate = this._appSharedService.currentRoleData.EndDate;
    this.MonthsCount = this._appSharedService.currentRoleData.MonthsCount;

    // let data = JSON.parse(`[{"GGID":1597899,"EmployeeName":"Chilukuri, Krishna Kishore","Designation":"Senior Director","Email":"krishna.kishore@capgemini.com","PCO":"Mittal, Ajay","Order":1,"Category":"PARENT","PeopleManagerGGID":1604908,"ReportiesList":[]},{"GGID":1601291,"EmployeeName":"Lankoji, Vara","Designation":"Program Manager","Email":"vara.lankoji@capgemini.com","PCO":"Chilukuri, Krishna Kishore","Order":2,"PeopleManagerGGID":1597899,"Category":"PARENT","ReportiesList":[]},{"GGID":46078506,"EmployeeName":"Sinnamani, Sasitharan","Designation":"Senior Consultant","Email":"sasitharan.a.sinnamani@capgemini.com","PCO":"Lankoji, Vara","Order":1,"Category":"CHILD","PeopleManagerGGID":1601291,"ReportiesList":[]},{"GGID":1601799,"EmployeeName":"Tharun Kumar Reddy, Sankati","Designation":"Senior Consultant","Email":"tharun.reddy@capgemini.com","PCO":"Lankoji, Vara","Order":2,"Category":"CHILD","PeopleManagerGGID":1601291,"ReportiesList":[]}]`);

    // // 1st Make Parent Node And order by column "Order"
    // let parentData = data.filter((x: any) => x.Category === "PARENT").sort((a: any, b: any) => a.Order - b.Order);

    // // 2nd Make Child Node And order by column "Order"
    // let childData = data.filter((x: any) => x.Category === "CHILD").sort((a: any, b: any) => a.Order - b.Order);

    // // 3rd Add Child Node to Parent Node
    // parentData.forEach((element: any) => {
    //   element.ReportiesList = childData.filter((x: any) => x.PeopleManagerGGID === element.GGID);
    // });

    // // 4th Assign to _hierarchyData
    // this._hierarchyData = parentData;

  }

  _GetHierarchyData(bFlag: boolean, odata: any) {
    this._hierarchyData = [];

    if (!bFlag) {
      this.IsLoading = true;
    }

    let subscribe$: Subscription = this._homeService._GetHierarchyData(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200 && res.Data !== undefined && res.Data !== null && res.Data.length > 0) {
        let data = res.Data;

        // 1st Make Parent Node And order by column "Order"
        let parentData = data.filter((x: any) => x.Category === "PARENT").sort((a: any, b: any) => a.Order - b.Order);

        // 2nd Make Child Node And order by column "Order"
        let childData = data.filter((x: any) => x.Category === "CHILD").sort((a: any, b: any) => a.Order - b.Order);

        // 3rd Add Child Node to Parent Node
        parentData.forEach((element: any) => {
          element.ReportiesList = childData.filter((x: any) => x.PeopleManagerGGID === element.GGID);
        });

        // 4th Assign to _hierarchyData
        this._hierarchyData = parentData;

      }

      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  _onclickEmployee(category: any, selectedData: any) {

    let odata: any = {
      UserId: selectedData.GGID, SearchText: this._searchText, Mode: 'ONCLICK'
    };
    this.exceluserid = selectedData.GGID;
    this.excelmode = 'ONCLICK';

    this._GetHierarchyData(false, odata);

  }

  _onSearchEmployee() {
    let odata: any = {
      UserId: this._appSharedService.UserInfo.GGID, SearchText: this._searchText, Mode: 'SEARCH'
    };
    this.exceluserid = this._appSharedService.UserInfo.GGID;
    this.excelmode = 'SEARCH';

    this._GetHierarchyData(false, odata);
  }

  supervisorhistory(event: any): void {
    this.selectedempData = event;
    this.supervisorhistoryData = [];
    if (event.Email != undefined && event.GGID != undefined) {
      this.getComponentTemplateforsupervisorhistory();
        this.modalInf =
        {
          header: 'Supervisor History for : "' + event.EmployeeName + '"',
          size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
        };
        this.openModal('supervisorhistory');
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  getComponentTemplateforsupervisorhistory(): void {
    let odata: ComponentConfig = {
      Component: 'People', ComponentCode: ComponentCodes.PeopleComponent, ReferenceValueType: "1"
    };
    this.templateHeader = odata;
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.supervisorhistoryTemplate = template.filter(x => x.Group === 'SupervisorHistory');
        this.getsupervisorhistory();
      }
    },
      err => {
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  getsupervisorhistory() {
    this.IsLoading = true;
    this._homeService.getsupervisorhistory(this.selectedempData.Email).subscribe(res => {
      if (res.Data != null) {
        this.supervisorhistoryData = res.Data;
        this.IsPagination = true;
      }
      if (res.Data == null) {
        this.supervisorhistoryData = [];
        this.IsPagination = false;
      }
      this.IsLoading = false;
    },
    err => {
      this.IsLoading = false;
      console.log('Error on getsupervisorhistory.');
    });
  }

  onFilter(value: any) {
  }
  onClick(event: any): void {
  }
  onChange(event: any) {
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  downloadsupervisorhistorypopup(){
    this.modalInf =
    {
      header: 'Download Supervisor History',
      size: 'modal-lg custom-modal-width modal-dialog-centered modal-dialog-scrollable'
    };
    this.searchEmployee = '';
    this.downloadvalidationinfo = '';
    this.StartDateSelected = '';
    this.EndDateSelected = '';
    this.openModal('downloadsupervisorhistorypopup');
  }
  oninput(event: any, string: any): void {
    if(string == "StartDateSelected")
    {
      this.StartDateSelected = event.target.value;
    }
    else if(string == "EndDateSelected")
    {
      this.EndDateSelected = event.target.value;
    }
  }
  validatesupervisorhistory(){
    if(this.searchEmployee == '')
    {
      this.downloadvalidationinfo = "Please enter Employee GGID/Email";
    }
    else if(this.searchEmployee !== '')
    {
      this._homeService.checkifemployeesupervisorvalid(this.searchEmployee).subscribe(res => {
        if (res.Data != null) {
          if(res.Data[0].SupervisorEditFlag == 1)
          {
            this.employeeexistscount = this.employeeexistscount + 1;
            this.downloadsupervisorhistory();
          }
          else if(res.Data[0].SupervisorEditFlag == 0){
            this.downloadvalidationinfo = "You are unauthorized to download supervisor history of Employee : " + this.searchEmployee;
          }
        }
        else if (res.Data == null) {
          this.employeeexistscount = 0;
        }
      },
      err => {
        console.log('Error');
      });
    }
  }
  downloadsupervisorhistory(){
    this.IsLoading = true;
    if(this.searchEmployee == '')
    {
      this.downloadvalidationinfo = "Please enter Employee GGID/Email";
    }
    else if(this.StartDateSelected == '')
    {
      this.downloadvalidationinfo = "Please select Start Date";
    }
    else if(this.EndDateSelected == '')
    {
      this.downloadvalidationinfo = "Please select End Date";
    }
    else if(this.EndDateSelected > this.EndDate)
    {
      this.downloadvalidationinfo = "End Date should be less than or equal to current date";
    }
    else if(this.StartDateSelected < this.StartDate)
    {
      this.downloadvalidationinfo = "Only " + this.MonthsCount + " months data can be downloaded. Please select Start Date within " + this.MonthsCount + " months range from current date";
    }
    else if(this.StartDateSelected > this.EndDateSelected)
    {
      this.downloadvalidationinfo = "Start Date should be less than or equal to End Date";
    }
    else if(this.searchEmployee !== '' && this.employeeexistscount == 0)
    {
      this.downloadvalidationinfo = "You are unauthorized to download supervisor history of Employee : " + this.searchEmployee;
    }
    else
    {
      let subscribe$: Subscription = this._homeService.DownloadSupervisorHistory('', '', '', '', this.searchEmployee, this.StartDateSelected, this.EndDateSelected, this._appSharedService.currentRoleData.UserId, this._appSharedService.currentRoleData.UserRoleId).subscribe(result => {
        let date: any = new Date();
        let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
        this.downloadFile(result, 'Supervisor History' + '_' + latest_date);
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
      this.closeModal('downloadsupervisorhistorypopup');
    }
    this.IsLoading = false;
  }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');
  }
  GetSupervisorHierarchyToExcel(){
    this.IsLoading = true;
    let subscribe$: Subscription = this._homeService.GetSupervisorHierarchyToExcel(this._searchText, this.excelmode, this.exceluserid).subscribe(result => {
      let date: any = new Date();
      let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
      this.downloadFile(result, 'Supervisor Hierarchy' + '_' + latest_date);
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

}
