import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environment/environment';
import { FieldTemplate } from '../../modules/_classes/utility.interface';
import { ComponentConfig, ProcessResponse } from '../app.interface';
import { isNullOrUndefined } from './app.sharedservice';
import { AppSharedService } from './app.sharedservice';
import * as XLSX from 'xlsx';


@Injectable()
export class CommonAPIService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private APIUrl: string;

    constructor(private _http: HttpClient, private _appSharedService: AppSharedService) {
        this.APIUrl = environment.UIConfigURL;

    }


    private handleError(error: any): Promise<any> {
        //console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }



    getComponentConfig(reqdata: any): Observable<ProcessResponse<ComponentConfig>> {
        //console.log(reqdata);
        let Params = new HttpParams();
        Params = Params.append('Component', reqdata.Component);
        Params = Params.append('SubComponent', reqdata.SubComponent);
        Params = Params.append('ReferenceValueType', reqdata.ReferenceValueType);
        Params = Params.append('ReferenceValue', reqdata.ReferenceValue);
        Params = Params.append('ComponentCode', reqdata.ComponentCode);

        return this._http.get(this.APIUrl + 'getComponentConfig', { headers: this.headers, params: Params })
            .pipe(map((result: any) => {

                // Creating full object for FieldTemplate since the server returns only partial object
                if (!isNullOrUndefined(result.Data.FieldTemplate) && result.Data.FieldTemplate.length > 0) {
                    let fieldtmplt = new Array<FieldTemplate>();
                    result.Data.FieldTemplate.forEach((field: FieldTemplate) => fieldtmplt.push(new FieldTemplate(field)));
                    result.Data.FieldTemplate = fieldtmplt;
                }

                return result;
            }))
            .pipe(
                catchError(this.handleError)
            );

    }
    
    GetCodeList(CodeType: string): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('CodeType', CodeType);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetCodeList',
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    GetOpportunityDetailsById(OPMID: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        return this._http.get(environment.apiurl + '/Dashboard/OpportunityDetailsById',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    GetDealCategoryValues(OPMID: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDealCategorization',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    GetDealCategoryValues1(OPMID: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReValidateDealCategorization',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetDependentDropDownData(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('ParentCodeType', data.parentcodetype);
        Params = Params.append('ServiceType', data.servicetype);
        Params = Params.append('param1', data.clienttier);
        Params = Params.append('param2', data.tcv);
        Params = Params.append('param3', data.isthedealunder);
        Params = Params.append('param4', data.isitsingletower);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDependentDropDownData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    GetDependentDropDownDataRole(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('ParentCodeType', data.PCT);
        Params = Params.append('ServiceType', data.ST);
        Params = Params.append('param1', data.Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDependentDropDownData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    GetPickCategoryDependentDropDownData(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('ParentCodeType', data.parentcodetype);
        Params = Params.append('ServiceType', data.servicetype);
        Params = Params.append('param1', data.id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDependentDropDownData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    insertdealcategoryvalues(odata: any, Flag: number, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        Params = Params.append('ClientTier', odata.ClientTier);
        Params = Params.append('TCV', odata.TCV);
        Params = Params.append('IsTheDealUnderStandardTC', odata.IsTheDealUnderStandardTC);
        Params = Params.append('IsTheDealUnderStandardTCDesc', odata.IsTheDealUnderStandardTCDesc);
        Params = Params.append('IsItSingleTower', odata.IsItSingleTower);
        Params = Params.append('IsItSingleTowerDesc', odata.IsItSingleTowerDesc);
        Params = Params.append('DealCategory', odata.DealCategory);
        Params = Params.append('DealCategoryDesc', odata.DealCategoryDesc);
        Params = Params.append('OpportunityMasterID', odata.OpportunityMasterID);
        Params = Params.append('Flag', Flag);
        Params = Params.append('UserEmail', UserEmail);


        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateDealCategory',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    inserteditdealcategoryvalues(odata: any, Flag: number, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Flag', Flag);
        Params = Params.append('UserEmail', UserEmail);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateDealCategory',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    insertRevalidatedealcategoryvalues(odata: any, Flag: number): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        //Params = Params.append('ClientTier', odata.Clienttier);
        Params = Params.append('OpportunityMasterID', odata.Opportunitymasterid);
        Params = Params.append('pickcategory', odata.pickcategorydropdownvalue);
        Params = Params.append('comments', odata.pickcaegorycomments);
        Params = Params.append('Flag', Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateReValidateDealCategory',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    checkRevalidateDealCategory(OPMID: any,UserEmail:any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/CheckRevalidateDealCategory',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getDealcategoryHistory(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDealCategoryHistory',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getDealHistory(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetManageDealStatusHistory',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    getDealStatus(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDealStatus',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    getFolder(data: any): Observable<any> {

        let Params = new HttpParams();
        Params = Params.append('YearValue', data.year);
        Params = Params.append('DealId', data.dealid);
        //Params = Params.append('Env', data.env);

        return this._http.get(environment.apiurl + '/SharePoint/GetFolder',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    CheckGetFolder(data1: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('YearValue', data1.Year);
        Params = Params.append('DealId', data1.DealId);
        //Params = Params.append('Env', data.env);

        return this._http.get(environment.apiurl + '/SharePoint/GetFolder',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getfiles(data: any): Observable<any> {
        console.log(data);
        let Params = new HttpParams();
        Params = Params.append('YearValue', data.year);
        Params = Params.append('DealId', data.Dealid);
        Params = Params.append('Folder', data.folder);
        //Params = Params.append('Env', data.env);

        return this._http.get(environment.apiurl + '/SharePoint/GetFiles',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getsinglefile(data: any): Observable<any> {
        console.log(data);
        let Params = new HttpParams();
        Params = Params.append('Etag', data);
       

        return this._http.get(environment.apiurl + '/SharePoint/GetSingleFile',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    DeleteFile(data: any) {
        console.log(data);
        return this._http.post<any>(environment.apiurl + '/SharePoint/DeleteFile', data,
            { headers: this.headers, })
            .pipe(catchError(this.handleError));
    }
    
    uploadfile(data: any, file: File, filename: any) {
        const formData: any = new FormData();
        formData.append("file", file);
        console.log(formData);
        
        return this._http.post(environment.apiurl + '/SharePoint/UploadFileFromIFORMFILE' + '?YearValue=' + data.YearValue + '&DealId=' + data.DealID + '&Folder=' + data.Folder
            + '&DocumentType=' + data.DocumentType + '&ReplaceFile=' + data.ReplaceFile + '&UploadedBy=' + data.UploadedBy + '&Reference1=' + (data?.Reference1?data.Reference1:null),
            formData)
    }




    getOpportunityTeamDetails(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetOpportunityTeamDetails',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    InsertEditDealStatusValues(odata: any, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        //Params = Params.append('ClientTier', odata.Clienttier);
        Params = Params.append('DealStatusDropDownValue', odata.DropDownValue);
        Params = Params.append('Comments', odata.Comments);
        Params = Params.append('Status', odata.ChangingStatus);
        Params = Params.append('OpportunityMasterID', odata.Opportunitymasterid);
        Params = Params.append('Flag', odata.Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateDealStatus',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    getAddTeamDetails(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetAddTeamDetails',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getUserNameDetails(Name: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('UserName', Name);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetUserList',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    InsertUpdateTeamMembers(data: any, Flag: any, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('OpportunitymasterID', data.OpportunityMasterID);
        Params = Params.append('Name', data.Name);
        Params = Params.append('NameDesc', data.NameDesc);
        Params = Params.append('Role', data.Role);
        Params = Params.append('RoleDesc', data.RoleDesc);
        Params = Params.append('Admin', data.Admin);
        Params = Params.append('AdminDesc', data.AdminDesc);
        Params = Params.append('RoleType', data.RoleType);
        Params = Params.append('Flag', Flag);


        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateTeamMembers',
            data, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    
    
    getTeamHistory(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetTeamMemberChangeHistory',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    getQuestionarrie(tabName: string, oppurtunityMasterID: number, Reference1: string): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('TabName', tabName);
        Params = Params.append('OpportunityMasterID', oppurtunityMasterID);
        Params = Params.append('Reference1', Reference1);

        return this._http.get(environment.apiurl + '/Reviews/GetQuestionnaireData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    getCodes(codeType: string): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('codeType', codeType);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetCodeList',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetMitigateDetailsById(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', data.OpportunityMasterID);
        Params = Params.append('QuestionCode', data.QuestionCode);
        return this._http.get(environment.apiurl + '/Reviews/GetMitigateRiskAssessmentById',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    InsertUpdateRiskAssessment(data: any, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();


        Params = Params.append('OpportunityMasterID', data.OpportunityMasterID);
        Params = Params.append('QuestionCode', data.QCode);
        Params = Params.append('Answers', data.Answers);
        Params = Params.append('SPOC', data.SPOC);
        Params = Params.append('SPOCDesc', data.SPOCDesc);
        Params = Params.append('RiskMitigationStrategy', data.RiskMitigationStrategy);
        Params = Params.append('RiskMitigationStrategyDesc', data.RiskMitigationStrategyDesc);
        Params = Params.append('Status', data.Status);
        Params = Params.append('StatusDesc', data.StatusDesc);
        Params = Params.append('Comments', data.Comments);
        Params = Params.append('Rating', data.Rating);
        Params = Params.append('RatingDesc', data.RatingDesc);
        Params = Params.append('Documents', '');
        Params = Params.append('Flag', data.Flag);
        Params = Params.append('UserEmail', UserEmail);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/InsertUpdateRiskAssessment',
            data, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    DownloadAll(data: any) {
        return this._http.post(environment.apiurl + '/SharePoint/DownLoadAll' + '?YearValue=' + data.YearValue + '&DealID=' + data.DealID,
            { headers: this.headers })
            .pipe(catchError(this.handleError));
    }
    CallSharePointApi(url: any, data1: any, Accesstoken: any) {
        const headers2 = new HttpHeaders().set('Authorization', Accesstoken);
        return this._http.post(url, data1,
            { headers: headers2, responseType: 'blob' })
            .pipe(catchError(this.handleError));
    }

    getMitigateRiskHistory(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);

        return this._http.get(environment.apiurl + '/Reviews/GetRiskMitigatedHistory',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    // GetReviewersApproversDetailsByOppId(Id: any): Observable<any> {
    //     let Params = new HttpParams();
    //     Params = Params.append('OpportunityMasterID', Id);
    //     return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewersApproversDetailsByOpportunityId',
    //         { headers: this.headers, params: Params })
    //         .pipe(catchError(this.handleError));
    // }

    GetContractualReviewStatus(Id: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', Id);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetContractualReviewStatus',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    InsertUpdateDealQualification(data: any, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();


        Params = Params.append('OpportunityMasterID', data.OpportunityMasterID);
        Params = Params.append('QCode', data.QCode);
        Params = Params.append('Answers', data.Answers);
        Params = Params.append('AutomatedScore', data.AutomatedScore);
        Params = Params.append('Documents', '');
        Params = Params.append('Flag', data.Flag);
        Params = Params.append('UserEmail', UserEmail);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/InsertUpdateRiskAssessment',
            data, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    insertNewRevalidatedealcategoryvalues(odata: any, Flag: number, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Flag', Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateReValidateDealCategory',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    GetOfferLineDetailsById(OPMID: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetOfferLineDetails',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    InsertUpdateSubmissionDates(odata: any, Flag: number, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Flag', Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateOpportunityDetails',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    InsertUpdateOppDetails(odata: any, Flag: string, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Flag', Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateOpportunityDetails',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    GetMUBLDetails(SearchText: string, Flag: string, ID: number): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('SearchText', SearchText);
        Params = Params.append('Flag', Flag);
        Params = Params.append('OpportunityMasterID', ID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetMUBLDetails',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetOpportunityList(SearchValue: string): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('SearchValue', SearchValue);
        return this._http.get(environment.apiurl + '/Report/GetOpportunityList',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    InsertupdateInvolvedMUData(odata: any, Flag: string, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Flag', Flag);
        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertupdateInvolvedMUData',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    DeleteMUBLData(ID: number, SellingUnit: string, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', ID);
        Params = Params.append('SellingUnit', SellingUnit);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();


        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/DeleteMUBLDetails',
            ID, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }


    downloadmultipleitems(dataArray: any, data: any) {
        console.log(data);
        //let url = environment.apiurl + '/SharePoint/DownLoadMultipleItems?YearValue=${data.YearValue}&DealID=${data.DealID}';
        const url = `${environment.apiurl}/SharePoint/DownLoadMultipleItems?YearValue=${data.YearValue}&DealID=${data.DealID}`;
        console.log(url);
        console.log(dataArray);
        return this._http.post<any>(url, dataArray,
            { headers: this.headers, })
            .pipe(catchError(this.handleError));
    }
    InsertUpdateNewDealQulaification(odata: any,Tab:string, Flag: string,Score:number, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        Params = Params.append('Tab', Tab);
        Params = Params.append('isFinalized', Flag);
        Params = Params.append('overallScore', Score);
        Params = Params.append('UserEmail', UserEmail);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/InsertUpdateMultipleRiskAssessment',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    
    GetReviewCycleForOpportunity(OPMID: any, stage: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        Params = Params.append('Stage', stage);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewCycleForOpportunity',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetReviewersApproversDetailsByStage(OPMID: any, category: any, stage: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        Params = Params.append('Category', category);
        Params = Params.append('Stage', stage);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewersApproversDetailsByStage',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetReviewersApproversDetailsByReviewCycle(reviewCycleId: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('reviewCycleId', reviewCycleId);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewersApproversDetailsByReviewCycle',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetReviewersApproversDetailsHistoryByReviewCycle(reviewCycleId: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('reviewCycleId', reviewCycleId);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewersApproversDetailsHistoryByReviewCycle',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    GetReviewDueDateByOpportunityId(OPMID: any, DealStage: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        Params = Params.append('DealStage', DealStage);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetReviewDueDateByOpportunityId',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    SaveReminderForOpportunity(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/SaveReminderForOpportunity',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }


    SendManualReminder(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/SendManualReminder',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    GetRequestMoreDetails(WorkflowTransactionId: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('WorkflowTransactionId', WorkflowTransactionId);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetRequestMoreDetails',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    EnableContractualReview(OpportunityMasterID: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OpportunityMasterID);
        return this._http.get(environment.apiurl + '/OpportunityDetails/EnableContractualReview',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
   
    GetRoleListForOppId(OPMID: any, DealStage: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('ParentCodeType', "AddTeamMemberByStage");
        Params = Params.append('ServiceType', "TeamRole");
        Params = Params.append('param1', OPMID);
        Params = Params.append('param2', DealStage);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDependentDropDownData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    SaveAdditionalReviewers(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/SaveAdditionalReviewers',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    DeleteAdditionalReviewers(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/DeleteAdditionalReviewers',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    InitiateReviewSetup(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/InitiateReviewSetup',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    SubmitWorkflowAction(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/SubmitWorkflowAction',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    InsertRequestMoreDetailsForWorkflowTransaction(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertRequestMoreDetailsForWorkflowTransaction',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }
    
    

    getUserDetailsFromUserName(Name: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('UserName', Name);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetUserDetailsFromUserName',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    getConsolidatedReportInput(): Observable<any> {
        let Params = new HttpParams();
        return this._http.get(environment.apiurl + '/Report/GetConsolidatedReportInput',
            { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    // getConsolidatedReportList(DealStatus: any,ReviewStatus: any,AccountType: any,DealCategory: any,ClientName: any,DealOrchestrator: any,DateType: any,Date: any): Observable<any> {
    //     let Params = new HttpParams();
    //     Params = Params.append('DealStatus', DealStatus);
    //     Params = Params.append('ReviewStatus', ReviewStatus);
    //     Params = Params.append('AccountType', AccountType);
    //     Params = Params.append('DealCategory', DealCategory);
    //     Params = Params.append('ClientName', ClientName);
    //     Params = Params.append('DealOrchestrator', DealOrchestrator);
    //     Params = Params.append('DateType', DateType);
    //     Params = Params.append('Date', Date);

    //     return this._http.get(environment.apiurl + '/Report/GetConsolidatedReportData',
    //         { headers: this.headers, params: Params })
    //         .pipe(catchError(this.handleError));
    // }

    getConsolidatedReportList(data: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Report/GetConsolidatedReportData',
        data, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    getGetDetailedReportList(data: { UserId: string, OpportunityID?: string, OpportunityName?: string }): Observable<any> {
        let Params = new HttpParams()
        .set('UserId', data.UserId)
        .set('OpportunityID', data.OpportunityID || '')  
        .set('OpportunityName', data.OpportunityName || '');  
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Report/GetDetailedReportData',
        data, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }


    SubmitWorkflowDelegation(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/SubmitWorkflowDelegation',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }    

    SubmitWorkflowEditName(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/SubmitWorkflowEditName',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    } 

    getDocumentDetails(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterId', data.OpportunityMasterId);
        Params = Params.append('FolderName', data.FolderName);
        Params = Params.append('DocumentType', data.DocumentType);
        Params = Params.append('Reference1', data.Reference1);
        
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDocumentDetails',
        { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    InsertUpdateOpportunityTeamMember(odata: any, UserEmail: string): Observable<ProcessResponse<any>> {
        let Params = new HttpParams();

        // Params = Params.append('Flag', Flag);

        // Here header should not contain content-type.
        let headers1 = new HttpHeaders();

        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/OpportunityDetails/InsertUpdateOpportunityTeamMember',
            odata, { headers: headers1, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    _getDependentDropDownData(data: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('ParentCodeType', data.ParentCodeType);
        Params = Params.append('ServiceType', data.ServiceType);
        Params = Params.append('param1', data.param1);
        Params = Params.append('param2', data.param2);
        Params = Params.append('param3', data.param3);
        Params = Params.append('param4', data.param4);
        Params = Params.append('param5', data.param5);
        Params = Params.append('param6', data.param6);
        Params = Params.append('param7', data.param7);
        Params = Params.append('param8', data.param8);

        return this._http.get(environment.apiurl + '/OpportunityDetails/GetDependentDropDownData',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }
    _getManageDealStatus(OPMID: any,opportunityId :any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('OpportunityMasterID', OPMID);
        Params = Params.append('opportunityId', opportunityId);
        return this._http.get(environment.apiurl + '/OpportunityDetails/GetManageDealStatus',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

    InsertAdditionalDetailsValidation(odata: any): Observable<any> {
        let Params = new HttpParams();
        return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Reviews/InsertAdditionalDetailsValidation',
            odata, { headers: this.headers, params: Params })
            .pipe(
                catchError(this.handleError)
            );
    }

    getRiskMitigationNote(QuestionCode: any,Answer: any): Observable<any> {
        let Params = new HttpParams();
        Params = Params.append('QuestionCode', QuestionCode);
        Params = Params.append('Answer', Answer);

        return this._http.get(environment.apiurl + '/Reviews/GetRiskMitigationActionNote',
            { headers: this.headers, params: Params })
            .pipe(catchError(this.handleError));
    }

}
