import { Component,OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { FieldTemplate, FormProperty, GridConst } from 'src/modules/_classes/utility.interface';
import { HomeService } from '../home/home.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environment/environment';


@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit { 
  
  public subscriptionList$: Subscription[] = [];
  public IsLoading: boolean = false;
  public IsPagination: boolean = false;
  public Isrecord: boolean = false;
  public empListTemplate: FieldTemplate[] = [];
  public supervisorhistoryTemplate: FieldTemplate[] = [];
  public empListData: any[] = [];
  public empListDataOrg: any[] = [];
  public empListDatabackupforfilter: any[] = [];
  public empListDataFilteredEmployeeName: any[] = [];
  public empListDataFilteredEmailAddress: any[] = [];
  public templateHeader!: ComponentConfig;
  public userroleid: any = 0;
  public selectedempData: any = {};
  public modalInf: ModalInterface = new ModalInterface();
  public supervisorhistoryData: any[] = [];
  public searchEmployee: any;
  public downloadvalidationinfo:any;
  public StartDate: any;
  public EndDate: any;
  public StartDateSelected: any;
  public EndDateSelected: any;
  public MonthsCount: any;
  public ReportFormTemplate: FieldTemplate[] = [];
  public ReportsFilterData: any = {IsEditable:1};
  public _gridTotalRecords: number = 0;
  public FormConfig: any = {
    BaseUrl: environment.baseURL
  };
  public GlobalConfig: any = {};
  public reportsvalidationinfo:any;
  public DataInput: any = { PageIndex: 1, PageSize: 0, SearchValue: '', OrderColumn: '', OrderDirection: '', Filters: '' };
  public FormPropReports: FormProperty = {
    'ColSize': 4,
    'CaptionBold': false,
    'CaptionTop': true,
    'IsEditable': true,
    'ShowControlAlways': true
  };
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10,
      ServerLoad: true
    },
    IsColResizable: false
  }
  constructor(private _appSharedService: AppSharedService, private homeService: HomeService, private _commonAPIService: CommonAPIService
    , private modalService: ModalService, public datepipe: DatePipe) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void { 
    this.getComponentTemplate();
    this.userroleid = this._appSharedService.currentRoleData.UserRoleId;
  }
  getComponentTemplate(): void {
    this.IsLoading = true;
    this.empListTemplate = [];
    this.ReportFormTemplate = [];
    let odata: ComponentConfig = {
      Component: 'People', ComponentCode: ComponentCodes.PeopleComponent, ReferenceValueType: "1"
    };

    this.IsLoading = true;
    this.templateHeader = odata;
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.empListTemplate = template.filter(x => x.Group === 'MyPeopleDashboard');
        this.supervisorhistoryTemplate = template.filter(x => x.Group === 'SupervisorHistory');
        this.ReportFormTemplate = template.filter(x => x.Group === 'ReportFormTemplate');
        if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
            !isNullOrUndefined(this.GridProp.PaginationConfig.ServerLoad) &&
            this.GridProp.PaginationConfig.ServerLoad) {
            this.DataInput['PageSize'] = isNullOrUndefined(this.GridProp.PaginationConfig.ItemsPerPage) ?
              GridConst.DEFAULTITEMSPERPAGE : this.GridProp.PaginationConfig.ItemsPerPage;
        }
        // this.getEmpListforMyPeopleDashboard();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);
  }
  getEmpListforMyPeopleDashboard() {
    this.IsLoading = true;
    this.reportsvalidationinfo = null;
    this.ReportsFilterData.SearchBy = (this.ReportsFilterData.SearchBy != null || this.ReportsFilterData.SearchBy != undefined) ? this.ReportsFilterData.SearchBy : '';
    this.ReportsFilterData.SearchText = (this.ReportsFilterData.SearchText != null || this.ReportsFilterData.SearchText != undefined) ? this.ReportsFilterData.SearchText : '';
    this.ReportsFilterData.BU = (this.ReportsFilterData.BU != null || this.ReportsFilterData.BU != undefined) ? this.ReportsFilterData.BU : '';
    this.ReportsFilterData.SBU = (this.ReportsFilterData.SBU != null || this.ReportsFilterData.SBU != undefined) ? this.ReportsFilterData.SBU : '';

    if(this.ReportsFilterData.SearchBy == '' && this.ReportsFilterData.SearchText != '')
    {
      this.reportsvalidationinfo = "Please select Search By";
      this.IsLoading = false;
    }
    else{
      this.homeService.getEmpListforMyPeopleDashboard(this.DataInput, this.userroleid, this.ReportsFilterData.SearchBy, this.ReportsFilterData.SearchText, this.ReportsFilterData.BU, this.ReportsFilterData.SBU).subscribe(res => {
        if (res.Data != null) {
          this.empListData = res.Data;
          this.empListDataOrg = res.Data;
          this.empListDatabackupforfilter = res.Data;
          this.StartDate = res.Data[0].StartDate;
          this.EndDate = res.Data[0].EndDate;
          this.MonthsCount = res.Data[0].MonthsCount;
          this.IsPagination = true;
          this.Isrecord = false;
          this._gridTotalRecords = this.empListData.length > 0 ? this.empListData[0].TotalRecords : 0;
        }
        if (res.Data == null) {
          this.empListData = [];
          this.empListDataOrg = [];
          this.Isrecord = true;
          this.IsPagination = false;
          this.reportsvalidationinfo = "No records found";
        }      
        this.IsLoading = false;
      },
      err => {
        this.IsLoading = false;
        console.log('Error on getEmpListforMyPeopleDashboard.');
      });
    }
  }
  onFilter(value: any) {
    value.filter.forEach((element: any) => {
      if (element.value != '' && element.index == 0)
      {
        if(this.empListDataFilteredEmailAddress.length > 0)
        {
          this.empListData = this.getFilterBykey(this.empListDataFilteredEmailAddress, element)
        }
        else
        {
          this.empListData = this.getFilterBykey(this.empListData, element)
        }        
        this.empListDataFilteredEmployeeName = this.getFilterBykey(this.empListDatabackupforfilter, element)
      }
      else if (element.value == '' && element.index == 0)
      {
        this.empListDataFilteredEmployeeName = [];
        if(this.empListDataFilteredEmailAddress.length > 0)
        {
          this.empListData = this.empListDataFilteredEmailAddress;
        }
        else
        {
          this.empListData = this.empListDatabackupforfilter;
        }
      }
      else if (element.value != '' && element.index == 1)
      {
        if(this.empListDataFilteredEmployeeName.length > 0)
        {
          this.empListData = this.getFilterBykey(this.empListDataFilteredEmployeeName, element)
        }
        else
        {
          this.empListData = this.getFilterBykey(this.empListData, element)
        }        
        this.empListDataFilteredEmailAddress = this.getFilterBykey(this.empListDatabackupforfilter, element)
      }
      else if (element.value == '' && element.index == 1)
      {
        this.empListDataFilteredEmailAddress = [];
        if(this.empListDataFilteredEmployeeName.length > 0)
        {
          this.empListData = this.empListDataFilteredEmployeeName;
        }
        else
        {
          this.empListData = this.empListDatabackupforfilter;
        }        
      }
    });
    this.empListData.length <= 0 ? this.Isrecord = true : this.Isrecord = false;
  }
  getFilterBykey(data: any, filter: any) {
    console.log(this.empListTemplate[filter.index].FieldName.toLowerCase())
    let fieldname = this.empListTemplate[filter.index].FieldName != 'Team' ? this.empListTemplate[filter.index].FieldName : 'TeamToolTip'
    data = data.filter((x: any) => x[fieldname]?.toLowerCase().includes(filter.value?.toLowerCase()))
    return data;
  }
  onClick(event: any): void {
    this.selectedempData = event.item;
    this.supervisorhistoryData = [];
    switch (event.fieldname.toUpperCase()) {
      // case 'EMPLOYEENAME':
      //   if (event.item.EmployeeEmailId != undefined && event.item.EmployeeGGID != undefined) {
      //     this.getsupervisorhistory();
      //       this.modalInf =
      //       {
      //         header: 'Supervisor History for : "' + event.item.EmployeeName + '"',
      //         size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
      //       };
      //       this.openModal('supervisorhistory');
      //   }
      //   break;
      case 'ACTION':
        switch (event.icon.Value) {
          case '1':
            if (event.item.EmployeeEmailId != undefined && event.item.EmployeeGGID != undefined) {
              this.getsupervisorhistory();
                this.modalInf =
                {
                  header: 'Supervisor History for : "' + event.item.EmployeeName + '"',
                  size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
                };
                this.openModal('supervisorhistory');
            }
            break;
          }
        break;
    }
  }
  getsupervisorhistory() {
    this.IsLoading = true;
    this.homeService.getsupervisorhistory(this.selectedempData.EmployeeEmailId).subscribe(res => {
      if (res.Data != null) {
        this.supervisorhistoryData = res.Data;
        this.IsPagination = true;
      }
      if (res.Data == null) {
        this.supervisorhistoryData = [];
        this.IsPagination = false;
      }      
      this.IsLoading = false;
    },
    err => {
      this.IsLoading = false;
      console.log('Error on getsupervisorhistory.');
    });
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  onChange(event: any) {
  }
  downloadsupervisorhistorypopup(){
    this.modalInf =
    {
      header: 'Download Supervisor History',
      size: 'modal-lg custom-modal-width modal-dialog-centered modal-dialog-scrollable',
      yes: 'Download Supervisor History',
      no: 'Cancel'
    };
    this.searchEmployee = '';
    this.downloadvalidationinfo = '';
    this.StartDateSelected = '';
    this.EndDateSelected = '';
    this.openModal('downloadsupervisorhistorypopup');
  }
  oninput(event: any, string: any): void {
    if(string == "StartDateSelected")
    {
      this.StartDateSelected = event.target.value;
    }
    else if(string == "EndDateSelected")
    {
      this.EndDateSelected = event.target.value;
    }
  }
  downloadsupervisorhistory(){
    this.IsLoading = true;
    let emailexistscount = 0;
    let ggidexistscount = 0;
    this.ReportsFilterData.SearchBy = (this.ReportsFilterData.SearchBy != null || this.ReportsFilterData.SearchBy != undefined) ? this.ReportsFilterData.SearchBy : '';
    this.ReportsFilterData.SearchText = (this.ReportsFilterData.SearchText != null || this.ReportsFilterData.SearchText != undefined) ? this.ReportsFilterData.SearchText : '';
    this.ReportsFilterData.BU = (this.ReportsFilterData.BU != null || this.ReportsFilterData.BU != undefined) ? this.ReportsFilterData.BU : '';
    this.ReportsFilterData.SBU = (this.ReportsFilterData.SBU != null || this.ReportsFilterData.SBU != undefined) ? this.ReportsFilterData.SBU : '';
    if(this.searchEmployee !== '')
    {
      this.empListData.forEach((element: any, index : any) =>{
        if(this.empListData[index].EmployeeEmailId == this.searchEmployee)
        {
          emailexistscount = emailexistscount + 1;
        }
        else if(this.empListData[index].EmployeeGGID == this.searchEmployee)
        {
          ggidexistscount = ggidexistscount + 1;
        }
      });
    }

    if(this.StartDateSelected == '')
    {
      this.downloadvalidationinfo = "Please select Start Date";
      this.IsLoading = false;
    }
    else if(this.EndDateSelected == '')
    {
      this.downloadvalidationinfo = "Please select End Date";
      this.IsLoading = false;
    }
    else if(this.EndDateSelected > this.EndDate)
    {
      this.downloadvalidationinfo = "End Date should be less than or equal to current date";
      this.IsLoading = false;
    }
    else if(this.StartDateSelected < this.StartDate)
    {
      this.downloadvalidationinfo = "Only " + this.MonthsCount + " months data can be downloaded. Please select Start Date within " + this.MonthsCount + " months range from current date";
      this.IsLoading = false;
    }
    else if(this.StartDateSelected > this.EndDateSelected)
    {
      this.downloadvalidationinfo = "Start Date should be less than or equal to End Date";
      this.IsLoading = false;
    }
    else if(this.searchEmployee !== '' && emailexistscount == 0 && ggidexistscount == 0)
    {
      this.downloadvalidationinfo = "You are unauthorized to download supervisor history of Employee : " + this.searchEmployee;
      this.IsLoading = false;
    }
    else
    {
      let subscribe$: Subscription = this.homeService.DownloadSupervisorHistory(this.ReportsFilterData.SearchBy, this.ReportsFilterData.SearchText, this.ReportsFilterData.BU, this.ReportsFilterData.SBU, this.searchEmployee, this.StartDateSelected, this.EndDateSelected, this._appSharedService.currentRoleData.UserId, this._appSharedService.currentRoleData.UserRoleId).subscribe(result => {
        let date: any = new Date();
        let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
        this.downloadFile(result, 'Supervisor History' + '_' + latest_date);
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
      this.closeModal('downloadsupervisorhistorypopup');
    }
  }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');
  }
  onPageChange(event: any) {
    this.DataInput['PageIndex'] = event.PageIndex + 1;
    this.DataInput['PageSize'] = event.PageSize;
    if (!isNullOrUndefined(event?.Reload)) {
      this.empListData = [];
      this.empListDataOrg = [];
    }
    this.getEmpListforMyPeopleDashboard();
  }
  onSortingChange(event: any) {
    this.DataInput['OrderDirection'] = event.OrderDirection;
    this.DataInput['OrderColumn'] = event.OrderColumn;
    this.getEmpListforMyPeopleDashboard();
  }
  filtersearch(){
    this.DataInput['PageIndex'] = 1;
    this.getEmpListforMyPeopleDashboard();
  }
}