import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService, isNullOrUndefined } from 'src/app/shared/app.sharedservice';
import { ModalService } from 'src/modules/modal/modal.service';
import { HomeService } from 'src/app/components/home/home.service';
import { FieldTemplate } from 'src/modules/_classes/utility.interface';
import { ComponentCodes, ComponentConfig, ProcessStatus } from '../../app.interface';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { NotificationData } from 'src/modules/notification/notification.interface';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-projectdetail',
  templateUrl: './projectdetail.component.html',
  styleUrls: ['./projectdetail.component.scss']
})
export class ProjectdetailComponent implements OnInit {

  public ProjectData: any = {};
  public subscriptionList$: Subscription[] = [];
  public IsLoading: boolean = false;
  public IsPagination: boolean = false;
  public IsShowHierarchy:boolean=false;
  public Isrecord: boolean = false;
  public allocationsTemplate: FieldTemplate[] = [];
  public templateHeader!: ComponentConfig;
  public allocationsData: any = {};
  public allocationsDatabackupforfilter: any[] = [];
  public allocationsDataFilteredEmployeeName: any[] = [];
  public allocationsDataFilteredEmailAddress: any[] = [];
  public allocationsDataforsupervisorchange: any = {};
  public selectedItems: any = {};
  public allocationsDataSelectedforService: any = [];
  public modalInf: ModalInterface = new ModalInterface();
  public dropdownSettings: IDropdownSettings = {};
  public changeSupervisorvalidationinfo:any;
  public sameSupervisorvalidationinfo:any;
  public searchedEmployeeData: any = {};
  public searchedEmployeeDataforvalidation: any = {};
  public searchEmployee: any;
  public displayNotifybox: boolean = false;
  public supervisorgradevalidationCount: any = 0;
  public samesupervisorvalidationCount: any = 0;
  public sameemployeesupervisorvalidationCount: any = 0;
  public selectedsupervisorssupervisorvalidationCount: any = 0;
  public notify: NotificationData = new NotificationData();
  public selectedempData: any = {};
  public supervisorhistoryData: any[] = [];
  public supervisorhistoryTemplate: FieldTemplate[] = [];
  public reporteestaggedcountinfo:any;
  public _gridTotalRecords: number = 0;
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    IsColResizable: false
  }

  @ViewChild('multiselect', { static: false }) multiselect!: MultiSelectComponent;

  constructor(private title: Title, private _router: Router, private _route: ActivatedRoute,
    private _appSharedService: AppSharedService, private _commonAPIService: CommonAPIService,
    private modalService: ModalService, private homeService: HomeService, private toastr: ToastrService, public datepipe: DatePipe) {

    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  ngOnInit(): void {    
    if (!isNullOrUndefined(window.history.state.projectData)) {
      this.ProjectData = window.history.state.projectData;      
      this._appSharedService.currentProjectData = window.history.state.projectData;
    }
    this.getComponentTemplate();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'EmployeeEmailId',
      textField: 'EmployeeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1000,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  getComponentTemplate(): void {
    this.IsLoading = true;
    this.allocationsTemplate = [];
    let odata: ComponentConfig = {
      Component: 'Allocation', ComponentCode: ComponentCodes.Supervisor, ReferenceValueType: "1"
    };

    this.IsLoading = true;
    this.templateHeader = odata;
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.allocationsTemplate = template.filter(x => x.Group === 'AllocatedEmployees');
        this.getAllocationsofProject();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getComponentTemplate.');
      });
    this.subscriptionList$.push(subscribe$);
  }

  onClickHierarchy(){
    this.IsShowHierarchy = true;
  }
  getAllocationsofProject() {    
    let subscribe$: Subscription = this.homeService.getAllocationsofProject(this.ProjectData.ProjectCode).subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        if(result.Data != null){
          this.allocationsData.IsEditable = 1;
          this.allocationsData = result.Data;
          this.allocationsDatabackupforfilter = result.Data;
          this.allocationsDataforsupervisorchange = this.allocationsData.filter((x : any) => x.SupervisorEditFlag == 1);
          this.IsPagination = true;
          this._gridTotalRecords = this.allocationsData.length > 0 ? this.allocationsData[0].TotalRecords : 0;
        }
        else if (result.Data == null) {
          this.allocationsData = [];
          this.Isrecord = true;
          this.IsPagination = false;
        }        
      }      
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }

  GotoHomePage(): void {
    this._router.navigate(['/home']);
  }

  onFilter(value: any) {
    value.filter.forEach((element: any) => {
      if (element.value != '' && element.index == 1)
      {
        if(this.allocationsDataFilteredEmailAddress.length > 0)
        {
          this.allocationsData = this.getFilterBykey(this.allocationsDataFilteredEmailAddress, element)
        }
        else
        {
          this.allocationsData = this.getFilterBykey(this.allocationsData, element)
        }        
        this.allocationsDataFilteredEmployeeName = this.getFilterBykey(this.allocationsDatabackupforfilter, element)
      }
      else if (element.value == '' && element.index == 1)
      {
        this.allocationsDataFilteredEmployeeName = [];
        if(this.allocationsDataFilteredEmailAddress.length > 0)
        {
          this.allocationsData = this.allocationsDataFilteredEmailAddress;
        }
        else
        {
          this.allocationsData = this.allocationsDatabackupforfilter;
        }
      }
      else if (element.value != '' && element.index == 2)
      {
        if(this.allocationsDataFilteredEmployeeName.length > 0)
        {
          this.allocationsData = this.getFilterBykey(this.allocationsDataFilteredEmployeeName, element)
        }
        else
        {
          this.allocationsData = this.getFilterBykey(this.allocationsData, element)
        }        
        this.allocationsDataFilteredEmailAddress = this.getFilterBykey(this.allocationsDatabackupforfilter, element)
      }
      else if (element.value == '' && element.index == 2)
      {
        this.allocationsDataFilteredEmailAddress = [];
        if(this.allocationsDataFilteredEmployeeName.length > 0)
        {
          this.allocationsData = this.allocationsDataFilteredEmployeeName;
        }
        else
        {
          this.allocationsData = this.allocationsDatabackupforfilter;
        }        
      }
    });
    this.allocationsData.length <= 0 ? this.Isrecord = true : this.Isrecord = false;
  }

  getFilterBykey(data: any, filter: any) {
    console.log(this.allocationsTemplate[filter.index].FieldName.toLowerCase())
    let fieldname = this.allocationsTemplate[filter.index].FieldName != 'Team' ? this.allocationsTemplate[filter.index].FieldName : 'TeamToolTip'
    data = data.filter((x: any) => x[fieldname]?.toLowerCase().includes(filter.value?.toLowerCase()))
    return data;
  }

  onClick(event: any): void {
    this.selectedempData = event.item;
    this.supervisorhistoryData = [];
    switch (event.fieldname.toUpperCase()) {
      // case 'EMPLOYEENAME':
      //   if (event.item.EmployeeEmailId != undefined && event.item.EmployeeGGID != undefined) {
      //     this.getComponentTemplateforsupervisorhistory();
      //       this.modalInf =
      //       {
      //         header: 'Supervisor History for : "' + event.item.EmployeeName + '"',
      //         size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
      //       };
      //       this.openModal('supervisorhistory');
      //   }
      //   break;
      case 'ACTION':
        switch (event.icon.Value) {
          case '1':
            if (event.item.EmployeeEmailId != undefined && event.item.EmployeeGGID != undefined) {
              this.getComponentTemplateforsupervisorhistory();
                this.modalInf =
                {
                  header: 'Supervisor History for : "' + event.item.EmployeeName + '"',
                  size: 'modal-lg modal-dialog-centered modal-dialog-scrollable'
                };
                this.openModal('supervisorhistory');
            }
            break;
          case '0':
            if (event.item.EmployeeEmailId != undefined && event.item.EmployeeGGID != undefined) {
              this.modalInf =
              {
                header: 'Change Supervisor',
                size: 'modal-lg custom-modal-width modal-dialog-centered modal-dialog-scrollable',
                yes: 'Change Supervisor',
                no: 'Cancel'
              };
              if(this.multiselect)
              {
                this.multiselect.selectedItems = [];
              }
              this.searchEmployee = '';
              this.searchedEmployeeData = [];
              this.searchedEmployeeDataforvalidation = [];
              this.changeSupervisorvalidationinfo = '';
              this.reporteestaggedcountinfo = '';
              this.selectedItems = [];
              this.selectedItems.push(event.item);
              this.openModal('ChangeSupervisor');
            }
            break;
          }
        break;
    }
  }

  getComponentTemplateforsupervisorhistory(): void {
    let odata: ComponentConfig = {
      Component: 'People', ComponentCode: ComponentCodes.PeopleComponent, ReferenceValueType: "1"
    };
    this.templateHeader = odata;
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.supervisorhistoryTemplate = template.filter(x => x.Group === 'SupervisorHistory');
        this.getsupervisorhistory();
      }
    },
      err => {
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  getsupervisorhistory() {
    this.IsLoading = true;
    this.homeService.getsupervisorhistory(this.selectedempData.EmployeeEmailId).subscribe(res => {
      if (res.Data != null) {
        this.supervisorhistoryData = res.Data;
        this.IsPagination = true;
      }
      if (res.Data == null) {
        this.supervisorhistoryData = [];
        this.IsPagination = false;
      }
      this.IsLoading = false;
    },
    err => {
      this.IsLoading = false;
      console.log('Error on getsupervisorhistory.');
    });
  }

  onChange(event: any) {
  }

  ChangeSupervisor() {
    this.getAllocationsofProject();
    this.modalInf =
    {
      header: 'Change Supervisor',
      size: 'modal-lg custom-modal-width modal-dialog-centered modal-dialog-scrollable',
      yes: 'Change Supervisor',
      no: 'Cancel'
    };
    if(this.multiselect)
    {
      this.multiselect.selectedItems = [];
    }
    this.searchEmployee = '';
    this.searchedEmployeeData = [];
    this.searchedEmployeeDataforvalidation = [];
    this.changeSupervisorvalidationinfo = '';
    this.reporteestaggedcountinfo = '';
    this.selectedItems = [];
    this.openModal('ChangeSupervisor');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  onItemSelect(item: any) {
  }

  onItemDeSelect(removeditem: any) {
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getEmployeeDetails(event: any) {    
    this.searchedEmployeeData = [];
    this.searchedEmployeeDataforvalidation = [];
    this.changeSupervisorvalidationinfo = '';
    this.reporteestaggedcountinfo = '';
    this.supervisorgradevalidationCount = 0;
    this.sameSupervisorvalidationinfo = '';
    this.samesupervisorvalidationCount = 0;
    let maxgradelevel = 0;
    let maxgrade = '';
    let maxemployeename = '';
    let samesupervisorgrade = '';
    let samesupervisoremployeename = '';
    this.sameemployeesupervisorvalidationCount = 0;
    let sameemployeesupervisorgrade = '';
    let sameemployeesupervisorname = '';
    this.selectedsupervisorssupervisorvalidationCount = 0;
    let selectedsupervisorssupervisorgrade = '';
    let selectedsupervisorssupervisorname = '';
    if(event == "")
    {
      this.changeSupervisorvalidationinfo = "To search Employee, Please enter Employee GGID/Email";
    }
    else{
      let subscribe$: Subscription = this.homeService.getEmployeeDetails(event, this.ProjectData.ProjectCode).subscribe(result => {
        if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
          if(result.Data != null){
            this.searchedEmployeeData.IsEditable = 1;
            this.searchedEmployeeData = result.Data[0];
            this.searchedEmployeeDataforvalidation = result.Data;
            // if(this.searchedEmployeeData.EmployeeGradeLevel >= 4)
            // {
            //   this.reporteestaggedcountinfo = "Maximum employees allowed to map to a supervisor is " + this.searchedEmployeeData.SupervisorReporteesValidationCount + ". The selected supervisor is already mapped to " + this.searchedEmployeeData.TaggedEmployeeCount + " employee/s. " + (this.searchedEmployeeData.SupervisorReporteesValidationCount - this.searchedEmployeeData.TaggedEmployeeCount) + " employee/s can be mapped";
            // }            
            this.selectedItems.forEach((element: any, index : any) =>{
              this.selectedItems[index].ProjectId = this.ProjectData.ProjectCode;
              this.selectedItems[index].ProjectCodeOwnerEmailId = this.ProjectData.ProjectCodeOwnerEmailId;
              this.selectedItems[index].NewSupervisorEmailId = this.searchedEmployeeData.EmployeeEmailId;
            });
            this.selectedItems.forEach((element: any, index : any) =>{
              let tempEmployeeEmailId = this.selectedItems[index].EmployeeEmailId;
              var allocationsDataindex = this.allocationsData.findIndex((emailid: { EmployeeEmailId: any; }) => emailid.EmployeeEmailId === tempEmployeeEmailId);
              if (allocationsDataindex !== -1) {
                  this.selectedItems[index].CurrentSupervisorEmailId = this.allocationsData[allocationsDataindex].EmployeeSupervisorEmailId;
                  this.selectedItems[index].EmployeeGradeLevel = this.allocationsData[allocationsDataindex].EmployeeGradeLevel;
                  this.selectedItems[index].EmployeeLocalGrade = this.allocationsData[allocationsDataindex].EmployeeLocalGrade;
              }
            });
            this.selectedItems.forEach((element: any, index : any) =>{
              if(this.selectedItems[index].EmployeeGradeLevel > this.searchedEmployeeData.EmployeeGradeLevel)
              {
                maxgradelevel = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeGradeLevel : maxgradelevel;
                maxgrade = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeLocalGrade : maxgrade;
                maxemployeename = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeName : maxemployeename;
                this.supervisorgradevalidationCount = this.supervisorgradevalidationCount + 1;
              }
            });
            this.selectedItems.forEach((element: any, index : any) =>{
              if(this.selectedItems[index].CurrentSupervisorEmailId == this.searchedEmployeeData.EmployeeEmailId)
              {
                samesupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
                samesupervisoremployeename = this.selectedItems[index].EmployeeName;
                this.samesupervisorvalidationCount = this.samesupervisorvalidationCount + 1;
              }
            });
            this.selectedItems.forEach((element: any, index : any) =>{
              if(this.selectedItems[index].EmployeeEmailId == this.searchedEmployeeData.EmployeeSupervisorEmailId)
              {
                selectedsupervisorssupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
                selectedsupervisorssupervisorname = this.selectedItems[index].EmployeeName;
                this.selectedsupervisorssupervisorvalidationCount = this.selectedsupervisorssupervisorvalidationCount + 1;
              }
            });
            this.selectedItems.forEach((element: any, index : any) =>{
              if(this.selectedItems[index].EmployeeEmailId == this.searchedEmployeeData.EmployeeEmailId)
              {
                sameemployeesupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
                sameemployeesupervisorname = this.selectedItems[index].EmployeeName;
                this.sameemployeesupervisorvalidationCount = this.sameemployeesupervisorvalidationCount + 1;
              }
            });

            // if(this.searchedEmployeeData.TaggedEmployeeCount + this.selectedItems.length > this.searchedEmployeeData.SupervisorReporteesValidationCount)
            // {
            //   this.changeSupervisorvalidationinfo = "Supervisor cannot be mapped to more than " + this.searchedEmployeeData.SupervisorReporteesValidationCount + " employees";
            // }
            // if(this.searchedEmployeeData.EmployeeGradeLevel < 4)
            // {
            //   this.changeSupervisorvalidationinfo = "Supervisor should be Grade B1 and above";
            // }
            if(this.supervisorgradevalidationCount > 0)
            {
              this.changeSupervisorvalidationinfo = "Employee - " + maxemployeename + " (" + maxgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Supervisor grade should be equal or above employee grade being mapped";
            }
            else if(this.samesupervisorvalidationCount > 0)
            {
              this.sameSupervisorvalidationinfo = "Employee - " + samesupervisoremployeename + " (" + samesupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Current and new supervisor selected are same. Select different supervisor to proceed further";
            }
            else if(this.sameemployeesupervisorvalidationCount > 0)
            {
              this.changeSupervisorvalidationinfo = "Employee - " + sameemployeesupervisorname + " (" + sameemployeesupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Employee and new supervisor selected are same. Select different supervisor to proceed further";
            }
            else if(this.selectedsupervisorssupervisorvalidationCount > 0)
            {
              this.changeSupervisorvalidationinfo = "Employee - " + selectedsupervisorssupervisorname + " (" + selectedsupervisorssupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Supervisor selected is already reporting to Employee selected. Select different supervisor to proceed further";
            }
          }
          else if (result.Data == null) {
            this.searchedEmployeeData = [];
            this.changeSupervisorvalidationinfo = "No Employee found, Please check Employee GGID/Email";
          }        
        }      
        this.IsLoading = false;
      },
        err => {
          console.log('Error on getting getEmployeeDetails.');
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
    }
  }

  InsertUpdateSupervisorMapping() {
    this.IsLoading = true;
    this.changeSupervisorvalidationinfo = '';
    this.supervisorgradevalidationCount = 0;
    this.sameSupervisorvalidationinfo = '';
    this.samesupervisorvalidationCount = 0;
    let maxgradelevel = 0;
    let maxgrade = '';
    let maxemployeename = '';
    let samesupervisorgrade = '';
    let samesupervisoremployeename = '';
    this.sameemployeesupervisorvalidationCount = 0;
    let sameemployeesupervisorgrade = '';
    let sameemployeesupervisorname = '';
    this.selectedsupervisorssupervisorvalidationCount = 0;
    let selectedsupervisorssupervisorgrade = '';
    let selectedsupervisorssupervisorname = '';
    let searchedemployeenameformessage = this.searchedEmployeeData.EmployeeName;
    let taggedEmployeeCountformessage = 0;
    if(this.selectedItems.length > 0)
    {
      taggedEmployeeCountformessage = this.searchedEmployeeData.TaggedEmployeeCount + this.selectedItems.length;
      this.selectedItems.forEach((element: any, index : any) =>{
        this.selectedItems[index].ProjectId = this.ProjectData.ProjectCode;
        this.selectedItems[index].ProjectCodeOwnerEmailId = this.ProjectData.ProjectCodeOwnerEmailId;
        this.selectedItems[index].NewSupervisorEmailId = this.searchedEmployeeData.EmployeeEmailId;
      });
      this.selectedItems.forEach((element: any, index : any) =>{
        let tempEmployeeEmailId = this.selectedItems[index].EmployeeEmailId;
        var allocationsDataindex = this.allocationsData.findIndex((emailid: { EmployeeEmailId: any; }) => emailid.EmployeeEmailId === tempEmployeeEmailId);
        if (allocationsDataindex !== -1) {
            this.selectedItems[index].CurrentSupervisorEmailId = this.allocationsData[allocationsDataindex].EmployeeSupervisorEmailId;
            this.selectedItems[index].EmployeeGradeLevel = this.allocationsData[allocationsDataindex].EmployeeGradeLevel;
        }
      });
      this.selectedItems.forEach((element: any, index : any) =>{
        if(this.selectedItems[index].EmployeeGradeLevel > this.searchedEmployeeData.EmployeeGradeLevel)
        {
          maxgradelevel = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeGradeLevel : maxgradelevel;
          maxgrade = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeLocalGrade : maxgrade;
          maxemployeename = maxgradelevel <= this.selectedItems[index].EmployeeGradeLevel ? this.selectedItems[index].EmployeeName : maxemployeename;
          this.supervisorgradevalidationCount = this.supervisorgradevalidationCount + 1;
        }
      });
      this.selectedItems.forEach((element: any, index : any) =>{
        if(this.selectedItems[index].CurrentSupervisorEmailId == this.searchedEmployeeData.EmployeeEmailId)
        {
          samesupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
          samesupervisoremployeename = this.selectedItems[index].EmployeeName;
          this.samesupervisorvalidationCount = this.samesupervisorvalidationCount + 1;
        }
      });
      this.selectedItems.forEach((element: any, index : any) =>{
        if(this.selectedItems[index].EmployeeEmailId == this.searchedEmployeeData.EmployeeSupervisorEmailId)
        {
          selectedsupervisorssupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
          selectedsupervisorssupervisorname = this.selectedItems[index].EmployeeName;
          this.selectedsupervisorssupervisorvalidationCount = this.selectedsupervisorssupervisorvalidationCount + 1;
        }
      });
      this.selectedItems.forEach((element: any, index : any) =>{
        if(this.selectedItems[index].EmployeeEmailId == this.searchedEmployeeData.EmployeeEmailId)
        {
          sameemployeesupervisorgrade = this.selectedItems[index].EmployeeLocalGrade;
          sameemployeesupervisorname = this.selectedItems[index].EmployeeName;
          this.sameemployeesupervisorvalidationCount = this.sameemployeesupervisorvalidationCount + 1;
        }
      });
      this.allocationsDataSelectedforService = {
        'supervisorMappingLists': this.selectedItems
      }
    }
    if(this.selectedItems.length == 0)
    {
      taggedEmployeeCountformessage = this.searchedEmployeeData.TaggedEmployeeCount;
      this.changeSupervisorvalidationinfo = "Please select atleast one employee to update supervisor";
    }
    else if(this.searchedEmployeeData.length == 0)
    {
      this.changeSupervisorvalidationinfo = "Please search supervisor to update";
      this.getEmployeeDetails(this.searchEmployee);
    }
    // else if(this.searchedEmployeeData.EmployeeGradeLevel < 4)
    // {
    //   this.changeSupervisorvalidationinfo = "Supervisor should be grade B1 and above";
    // }
    // else if(this.searchedEmployeeData.TaggedEmployeeCount + this.selectedItems.length > this.searchedEmployeeData.SupervisorReporteesValidationCount)
    // {
    //   this.changeSupervisorvalidationinfo = "Supervisor cannot be mapped to more than " + this.searchedEmployeeData.SupervisorReporteesValidationCount + " employees";
    // }
    else if(this.supervisorgradevalidationCount > 0)
    {
      this.changeSupervisorvalidationinfo = "Employee - " + maxemployeename + " (" + maxgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Supervisor grade should be equal or above employee grade being mapped";
    }
    else if(this.samesupervisorvalidationCount > 0)
    {
      this.sameSupervisorvalidationinfo = "Employee - " + samesupervisoremployeename + " (" + samesupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Current and new supervisor selected are same. Select different supervisor to proceed further";
    }
    else if(this.sameemployeesupervisorvalidationCount > 0)
    {
      this.changeSupervisorvalidationinfo = "Employee - " + sameemployeesupervisorname + " (" + sameemployeesupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Employee and new supervisor selected are same. Select different supervisor to proceed further";
    }
    else if(this.selectedsupervisorssupervisorvalidationCount > 0)
    {
      this.changeSupervisorvalidationinfo = "Employee - " + selectedsupervisorssupervisorname + " (" + selectedsupervisorssupervisorgrade + "), Supervisor - " + this.searchedEmployeeData.EmployeeName + " (" + this.searchedEmployeeData.EmployeeLocalGrade + ") : " + "Supervisor selected is already reporting to Employee selected. Select different supervisor to proceed further";
    }
    else
    { 
      let subscribe$: Subscription = this.homeService.InsertUpdateSupervisorMapping(this.allocationsDataSelectedforService).subscribe(result => {
        if (!isNullOrUndefined(result) && result.Status === ProcessStatus.Success) {
          this.showNotificationMessage('You have successfully modified supervisor of selected employees allocated for the project ' + this.ProjectData.ProjectName + ' (' + this.ProjectData.ProjectCode + ') and number of reportees for ' + searchedemployeenameformessage + ' is ' + taggedEmployeeCountformessage, 1, '', null, '', '', 5000);
          this.getAllocationsofProject();
          this.IsLoading = false;
        }
      },
        err => {
          this.IsLoading = false;
        });
      this.subscriptionList$.push(subscribe$);
      this.searchEmployee = '';
      this.searchedEmployeeData = [];
      this.searchedEmployeeDataforvalidation = [];
      this.closeModal('ChangeSupervisor');
    }
    this.IsLoading = false;
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }

    this.notify = {
      info: {
        header: 'Confirmation Alert',
        message: msg,
        yes: yes,
        no: no,

        IsHeaderHidden: true,
        HeaderIcon: headerIcon,
        IsCrossEnable: true,
        SizeClass: 'modal-sm'
      },

      action: action,
      item: item,
      timeout: timeout
    };

    this.displayNotifybox = true;
  }

  setNotifyRes(event: any): void {
    console.log('notify res >> ' + JSON.stringify(event));
    let canClose: boolean = true;

    if (canClose) {
      this.closeNotifybox();
    }
  }
  
  closeNotifybox(): void {
    this.displayNotifybox = false;
  }

  GetEmployeesListToExcel(){
    this.IsLoading = true;
    let subscribe$: Subscription = this.homeService.GetEmployeesListToExcel(this.ProjectData.ProjectCode).subscribe(result => {
      let date: any = new Date();
      let latest_date = this.datepipe.transform(date, "yyyy-MM-dd_HH-mm-ss");
      this.downloadFile(result, 'Employees List' + '_' + latest_date);
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  public downloadFile(response: any, fileName: string) {
    fileName = fileName;
    const blob = new Blob([response.body], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, fileName + '.xlsx');
  }

}
