<!-- <div class="row">
    <div class="container-fluid">  -->
<div class="dx-table-grid">
  <div class="table-responsive">
    <!-- <table class="table align-middle"> -->
    <table class="table align-middle">
      <ng-container>
        <thead *ngIf="DisplayHeader" [ngClass]="HeaderCSS">
          <tr>
            <!-- <th>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"  />
                <label class="form-check-label" for="flexCheckChecked"></label>
              </div>
            </th> -->
            <th *ngFor="let key of FieldTemplate;let idx=index" [hidden]="!showGridCol(key.FieldName)" scope="col"
            [ngStyle]="{'sticky':(key.FieldName == 'OpportunityID')}">
              <div class="dx-col-head">

                <div [ngClass]="{'text-right': (key.Control.InputType == 'number'),}"  class="dx-col-head-truncate"
                  *ngIf="key.HeaderTemplate == undefined || key.HeaderTemplate.length == 0">

                  <div class="text-truncate my-0 text-{{key.FieldName}}">
                    <span *ngIf="key.Control.Type != 'checkbox'"
                      container="body" placement="bottom">{{key.DisplayName}}</span>
                      <!-- [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)" -->

                    <span *ngIf="(key.Control.Type =='checkbox' && key.Control.InputType == null)">
                      <!-- [tooltip]="key.ToolTipTemplate"> -->
                      <input type="checkbox" class="form-check-input" [(ngModel)]="selectAll"
                        (click)="onClickSelectAll()" [tooltip]="key.ToolTipTemplate" container="body" placement="bottom"/>
                    </span>
                    <span *ngIf="(key.Control.Type =='checkbox' && key.Control.InputType != null)" container="body" placement="bottom">
                      {{key.DisplayName}}
                    </span>
                  </div>
                  <div class="text-sorter" *ngIf="key?.SortEnable">
                    <a href="javascript:void(0)" class="icon-column-sortable" (click)="sort(key)"
                      [ngClass]="{'up': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField)&& !isDesc), 
                      'down': (sortcolumn == (key.LinkedField == undefined || key.LinkedField == null || key.LinkedField.length == 0? key.FieldName: key.LinkedField) && isDesc) }"
                      
                      aria-hidden="true"></a>
                  </div>


                  <div *ngIf="key.HeaderTemplate != undefined && key.HeaderTemplate.length > 0"
                    [innerHtml]="key.HeaderTemplate" class="text-truncate"
                    [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"
                    container="body" placement="bottom">
                  </div>
                </div>
                <!-- Column level Filter-->
                <div class="dx-col-head-filter" *ngIf="key.Filter?.Enable">
                  <!-- style="margin: 6px 0 -3px" -->
                  <a  href="javascript:void(0)" target="_self" class="icon-column-filter " (click)="showFilter(key)"
                  
                  [ngClass]="{'selective': (filtercolumn[idx] == key.FieldName) && searchValue[idx]!=''}"></a>


                  <div class="dx-window-custom dx-window-filter lefter" *ngIf="DispFilter"
                    [class.show]="key.FieldName === selectedcolfilter"
                    [class.hide]="key.FieldName !== selectedcolfilter">
                    <!-- <div class="dx-window-heading">
                      <h4>Search</h4>
                      <a href="javascript:void(0)" (click)="onClose(key)" class="icon-closable"></a>
                    </div> -->
                    <!-- <div class="dx-window-content">
                      <div class="dx-window-stuff">
                        <div class="dx-grab">
                          <div class="dx-form-carry">
                            <div class="form-group">
                              <div *ngIf="(key.Filter?.InputType == 'text')">
                                <label for="contains" class="form-label">Contains</label>
                                <input type="text" class="form-control" id="contains" [(ngModel)]="searchValue[idx]"
                                  placeholder="input search text " />
                              </div>
                              <div *ngIf="(key.Filter?.InputType == 'dropdown')">
                                <label for="contains" class="form-label">Select</label>
                                <ng-select  placeholder="Select " id="contains_{{idx}}" [(ngModel)]="searchValue[idx]">
                                  <ng-option *ngFor="let x of FilterColdd" value={{x}}>{{x}}</ng-option>
                                </ng-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="dx-box-btn righter">
                          <button class="btn btn-light" (click)="cancelFilter(key,idx)">Clear</button>
                          <button class="btn btn-primary" (click)="applyFilter(key, idx)">Apply
                            Filter</button>
                        </div>
                      </div>
                    </div> -->
<!-- search section for table start here -->

                    <div class="input-group" style="padding: 10px;">
                      <input type="text" class="" id="contains" [(ngModel)]="searchValue[idx]"
                        placeholder=" " />
                      <div class="input-group-btn">
                        <button type="submit" class="btn" (click)="applyFilter(key, idx)">
                          <i aria-hidden="true" class="fa fa-search" style="padding:7px;"></i></button>
                        <button type="submit" class="btn" (click)="cancelFilter(key,idx)">
                          <i class="fa-solid fa-trash-can"></i></button>
                        <button *ngIf="(key.Filter?.InputType == 'text')" class="btn"
                          (click)="onClose(key)">
                          <i class="fa-solid fa-xmark"></i>
                        </button>

                      </div>
                    </div>
                    <!-- search section for table ends here -->
                  </div>

                </div>
              </div>
            </th>
          </tr>
        </thead>
      </ng-container>
      <ng-container>
        <tbody *ngIf="isValidDataType() ">
          <ng-template ngFor let-item
            [ngForOf]="PaginationConfig.ServerLoad? (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
          | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)}) : 
        (GridData | orderBy: {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype} 
          | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage):(Pagination.CurrentPage*Pagination.ItemsPerPage))"
            let-item_indx="index">

            <tr>


              <td *ngFor="let key of FieldTemplate;let key_indx= index" [hidden]="!showGridCol(key.FieldName)">

                <field-control [parentRandomId]="gridRandomId" [item_indx]="item_indx" [key_indx]="key_indx"
                  [item]="item" [key]="key" [cellLock]="cellLock" [parent]="this.parent" [parentNode]="this"
                  [selectedCell]="selectedCell" (fldClick)="fldOnClick($event)" (fldChange)="fldOnChange($event)"
                  (fldonEditStart)="fldonEditStart($event)" [attr.data-fieldname]="key.FieldName"
                  [attr.data-ctrltype]="key.Control.Type" [showcontrolalways]="key.ShowControlAlways"
                  [initedit]="IsEditable"></field-control>

              </td>
            </tr>
          </ng-template>
        </tbody>
        
        <tfoot *ngIf="Isrecord">
          <tr>
            <td class="dx-no-record" [attr.colspan]="getColumnCount()">
              <div class="dx-no-record-msg">
                <h5>--- : No Record Found : ---</h5>
              </div>
            </td>
          </tr>
        </tfoot>
      </ng-container>

    </table>
  </div>
</div>


<ng-container *ngIf="IsPagination">
  <div class="dx-pagination">
    <div class="lefter">
      <p>Total <span class=""></span> items</p>
    </div>
    <div class="righter">
      <nav aria-label="Page navigation example" class="page-number">
        <ul class="pagination justify-content-end">
          <li class="page-item">
            <pagination *ngIf="isValidDataType() && PaginationConfig.Required" [totalItems]="GridDataTotalCount"
              [itemsPerPage]="Pagination.ItemsPerPage" [maxSize]="5" [boundaryLinks]="true" previousText="&lsaquo;"
              nextText=" &rsaquo;" firstText="&laquo; First" [rotate]="false" [(ngModel)]="Pagination.CurrentPage"
              (pageChanged)="pageChanged($event)" (numPages)="totalPagesChanged($event)"></pagination>
          </li>
        </ul>
      </nav>
      <div class="page-render">
        <select class="form-select" aria-label="Default select example" [(ngModel)]="curItemsPerPage"
          (change)="pageItemsChange($event)">
          <option value=5>5/page</option>
          <option value=10>10/page</option>
          <option value=25>25/page</option>
          <option value=50>50/page</option>
          <option value=100>100/page</option>
        </select>
      </div>
      <div class="page-jump">
        <span class="goto">Go to</span>
        <input type="number" class="form-control" aria-label="Go to" placeholder="" [(ngModel)]="gotopage"
          (ngModelChange)="goto()" />
      </div>
    </div>
  </div>
</ng-container>

<!-- <ng-container>
  <div class="dx-pagination">
    <nav aria-label="Page navigation example" class="page-number">
      <ul class="pagination justify-content-end">
        <li class="page-item disabled">
          <a class="page-link">Previous</a>
        </li>
        <li class="page-item selective"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link truncate" href="#">...</a></li>
        <li class="page-item disabled"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item"><a class="page-link" href="#">4</a></li>
        <li class="page-item"><a class="page-link" href="#">5</a></li>
        <li class="page-item"><a class="page-link" href="#">6</a></li>
        <li class="page-item"><a class="page-link" href="#">7</a></li>
        <li class="page-item"><a class="page-link" href="#">8</a></li>
        <li class="page-item"><a class="page-link truncate" href="#">...</a></li>
        <li class="page-item"><a class="page-link" href="#">50</a></li>
        <li class="page-item">
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
    <div class="page-render">
      <select class="form-select" aria-label="Default select example">
        <option selected>10/page</option>
        <option value="1">25/page</option>
        <option value="2">50/page</option>
        <option value="3">100/page</option>
      </select>
    </div>
    <div class="page-jump">
      <span class="goto">Go to</span>
      <input type="text" class="form-control" aria-label="Go to" placeholder="" />
    </div>
  </div>
</ng-container> -->