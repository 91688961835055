import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
// import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CustomPipeModule } from '../custompipe/custompipe.module';
// import { FieldControlModule } from '@dist/fieldcontrol-lib';
// import { FieldControlModule } from 'fieldcontrol-lib';
// import { ElioScopeService, ElioUtilityService } from 'fieldcontrol-lib';
import { ElioScopeService } from '../_services/ElioScope.service';
import { UtilityService } from '../_services/utility.service';
import { GriddisplayComponent } from './griddisplay.component';
import { FieldControlModule } from '../fieldcontrol/fieldcontrol.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CustomPipeModule,
    FieldControlModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AlertModule.forRoot(),
    NgSelectModule
  ],
  declarations: [GriddisplayComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [ElioScopeService, UtilityService],
  exports: [GriddisplayComponent]
})
export class GriddisplayModule { }

