import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environment/environment';

export const authConfig: AuthConfig = {

  // Url of the Identity Provider
  issuer: environment.ssoLoginUrl,

  // URL of the SPA to redirect the user to after login
  
  redirectUri: window.location.origin + environment.ssoRedirectUri,

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",

  // defaults to true for implicit flow and false for code flow
  // as for code code the default is using a refresh_token
  // Also see docs section 'Token Refresh'
  useSilentRefresh: false,

  // // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.ssoClientId,
  dummyClientSecret: environment.ssoSecretId,

  scope: 'openid profile',

  responseType: 'code',

  requestAccessToken: true,
  tokenEndpoint: environment.ssotokenEndpoint,
  userinfoEndpoint: environment.ssouserinfoEndpoint,

  showDebugInformation: true,

}
