import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const internalRoutes: Routes = []

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(internalRoutes)],
  exports: [RouterModule]
})

export class ProjectdetailRoutingModule { }
