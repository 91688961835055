<form #form *ngIf="(FormTemplate!=undefined && FormTemplate!=null)">

    <div class="row" [ngClass]="{'form-inline': (!CaptionTop)}">
        <ng-container *ngFor="let key of FormTemplate;let key_indx= index">
            <!-- <div class="col-{{ColSize}}"  -->
            <div class="col-xs-{{respColSize.xs}} col-sm-{{respColSize.sm}} col-md-{{respColSize.md}} col-lg-{{respColSize.lg}} col-xl-{{respColSize.xl}}"
                [hidden]="!showFormCol(key.FieldName)"
                [ngClass]="{'non-editable': key.Editable == 0, 'align-self-end': (key.Control.Type == 'button')}">
                <div class="form-group">


                    <label class="form-label" for="field{{key.DisplayName.split(' ').join('')}}-{{formRandomId}}"
                        *ngIf="(key.Control.Type != 'button' && key.Control.Type != 'link')"
                        [ngClass]="[key.EditValidate.Required ? 'required':'']"
                        [attr.aria-required]="(key.EditValidate.Required ? 'true': 'false')">
                        <span class="sr-only asterik" aria-label="Asterik-Required"
                            *ngIf="(key.EditValidate.Required)">*</span>

                        {{key.DisplayName | translate}}

                        <ng-container
                            *ngIf="(key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0)">
                            <span placement="bottom" html="true" class="ml-auto informative-tooltip"
                                [tooltip]="((key.HeaderToolTip != undefined && key.HeaderToolTip != null && key.HeaderToolTip.length > 0) ? key.HeaderToolTip: key.DisplayName)"><i
                                    class="icon-informs"></i></span>
                        </ng-container>

                    </label>

                    <!-- <div [ngClass]="{'readonly': getReadOnly(key, FormData)}"> -->
                    <field-control [parentRandomId]="formRandomId" #fieldctrl [item_indx]="0" [key_indx]="key_indx"
                        [item]="FormData" [key]="key" [initedit]="IsEditable" [cellLock]="cellLock"
                        [parent]="this.parent" [parentNode]="this" (fldClick)="fldOnClick($event)"
                        (fldChange)="fldOnChange($event)" [attr.data-fieldname]="key.FieldName"
                        [attr.data-controltype]="key.Control.Type"
                        [showcontrolalways]="ShowControlAlways"></field-control>
                    <!-- </div> -->
                </div>
            </div>
        </ng-container>

    </div>

</form>