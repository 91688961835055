export class ModalInterface {
  header!: string;
  subheader?: string;
  size?: string;
  height?:string;
  width?:string;
  yes?:string;
  no?:string;
  data?:any = [{Comments: ''}];
  close?: boolean = true
}

export class ModalInterfaceRisk {
  header!: string;
  size?: string;
  height?:string;
  width?:string;
  data?:any;
  data2?: any;
}