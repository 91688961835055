import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../_services/utility.service';
import { FieldControlComponent } from '../fieldcontrol/fieldcontrol.component';
import { FieldTemplate,FormConfig,FormProperty,FieldEventData } from '../_classes/utility.interface';
// import { ElioScopeService, ElioUtilityService } from 'fieldcontrol-lib';
import { ElioScopeService } from '../_services/ElioScope.service';
import { UtilityService } from '../_services/utility.service';


@Component({
  selector: 'customform',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit, AfterViewInit {
  @Input() FormId?: any;
  @Input() FormTemplate: Array<FieldTemplate> = [];
  @Input() FormData: any;
  @Input() FormProp!: FormProperty;
  @Input() FormConfig!: FormConfig;
  @Input() GlobalConfig: any = {};

  @Input() cellLock!: Function;
  @Input() parent: any;

  @Output() FieldClick = new EventEmitter<any>();
  @Output() FieldChange = new EventEmitter<any>();
  @Output() FileUpload = new EventEmitter<any>();

  @ViewChildren('fieldctrl') fieldctrls!: QueryList<FieldControlComponent>;

  @ViewChild('form', { static: false, read: ElementRef }) private overallform!: ElementRef;


  // Form properties
  // Default values
  public ColSize: number = 2;
  public respColSize: any = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }
  public CaptionColSize: number = 4;
  public DataColSize: number = 8;
  public CaptionBold: boolean = true;
  public CaptionTop: boolean = false;
  public IsEditable: boolean = false;
  public ShowControlAlways: boolean = false;
  // public FormName: string;
  public DisplayColumns: string = '';
  public selectedCell: string = '';
  public editMode: boolean = false;
  public formRandomId: string = '';

  constructor(private _elioScopeService: ElioScopeService, private _elioUtilityService: UtilityService,
    private translate: TranslateService, private eleRef: ElementRef) {

    this.formRandomId = Math.random().toString(36).substr(2, 5);
  }

  ngOnInit() {

    if (this.FormProp !== undefined) {
      // this.FormName = this.FormProp.Name;
      // this.ColSize = (this.FormProp.ColSize === undefined ? this.ColSize : (12 / this.FormProp.ColSize));
      this.ColSize = (this.FormProp.ColSize === undefined ? this.ColSize : this.FormProp.ColSize);
      this.CaptionBold = (this.FormProp.CaptionBold === undefined ? this.CaptionBold : this.FormProp.CaptionBold);
      this.CaptionTop = (this.FormProp.CaptionTop === undefined ? this.CaptionTop : this.FormProp.CaptionTop);
      this.IsEditable = (this.FormProp.IsEditable === undefined ? this.IsEditable : this.FormProp.IsEditable);
      this.ShowControlAlways = (this.FormProp.ShowControlAlways === undefined ? this.ShowControlAlways : this.FormProp.ShowControlAlways);

      if (this.CaptionTop) {
        this.CaptionColSize = 12;
        this.DataColSize = 12;
      }
    }

    this.setResponsiveColSize();

    if (!this.IsEditable) {
      this.setTemplateReadOnly();
    }

    this.editMode = this.IsEditable;
    // this.DisplayColumns = this.setDispcols();
    this.setDispcols();

    //console.log(this.FormTemplate);
    //console.log(this.FormData);

    if (this.FormConfig !== undefined && this.FormConfig.BaseUrl !== undefined && this.FormConfig.BaseUrl !== null) {
      this._elioScopeService.setBaseURL(this.FormConfig.BaseUrl);
    }

    if (this.GlobalConfig !== undefined) {
      let GlobalKeys: Array<string> = [];
      // Object.entries(this.GlobalConfig).forEach(
      //   ([key, value]) => {console.log(key, value); GlobalKeys.push(key); }
      // );
      for (let key in this.GlobalConfig) { GlobalKeys.push(key); }

      this._elioUtilityService.PreProcessTemplate(this.FormTemplate, GlobalKeys, this.GlobalConfig);
    }

    // to initialise the field level celllock logic for all data
    if (!isNullOrUndefined(this.FormData)) {
      this.initCellLock(this.FormData);
      console.log(this.FormData)
    }

    // setTimeout(() => this.removeFocus(), 400);
  }

  ngAfterViewInit() {
    setTimeout(() => this.removeFocus(), 400);
  }

  setResponsiveColSize() {
    switch (this.ColSize) {
      case 1:
        this.respColSize = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
        break;

      case 2:
        this.respColSize = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 };
        break;

      case 3:
        this.respColSize = { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 };
        break;

      case 4:
      case 5:
        this.respColSize = { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 };
        break;

      case 6:
        this.respColSize = { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 };
        break;

    }
  }

  fldOnClick(event: any): void { this.FieldClick.emit(event); }
  

  fldOnChange(event: any): void {

    // Clear dependent fields dropdowns and reload their dowpdown values
    let curkey: any = this.FormTemplate.filter(x => x.FieldName === event.fieldname);

    if (curkey.length > 0) {
      if (!isNullOrUndefined(curkey[0].Control) && !isNullOrUndefined(curkey[0].Control.DependentControl)
        && curkey[0].Control.DependentControl.length > 0) {

        for (let index in curkey[0].Control.DependentControl) {
          let field = curkey[0].Control.DependentControl[index];

          // let fldctrl = this.fieldctrls.filter(x => x.key.FieldName === field);
          // if (fldctrl.length > 0) {
          //   fldctrl[0].ctrlLoad();
          // }

          let fldctrl: any = this.fieldctrls.find(x => x.key.FieldName === field);
          if (!isNullOrUndefined(fldctrl)) {
            fldctrl.ctrlLoad();
          }

          // let fieldkey = this.FormTemplate.filter(x => x.FieldName === field);

          // if (fieldkey.length > 0) {
          //   if (!isNullOrUndefined(fieldkey[0].Control) &&
          //     (fieldkey[0].Control.Type === 'dropdown' || fieldkey[0].Control.Type === 'link' ||
          //  fieldkey[0].Control.Type === 'typeahead') &&
          //     !isNullOrUndefined(fieldkey[0].Control.Source)) {

          //     // clear list
          //     fieldkey[0].Control.List = [];
          //     fieldkey[0].Control.LoadedUrl = '';

          //     // reload list
          //     //console.log(this.fieldctrls.length);
          //     let fldctrl = this.fieldctrls.filter(x => x.key.FieldName === field)

          //     if (fldctrl.length > 0){
          //       fldctrl[0].ctrlLoad();
          //     }

          //   }

          // }

        }

        // this.removeFocus();
      }
    }

    // this.FieldChange.emit({ 'fieldname': event.fieldname, 'item': event.item,  });
    let eventdata = { fieldname: event.fieldname, item: event.item, oldValue: event.oldValue } as FieldEventData;
    this.FieldChange.emit(eventdata);
  }

  removeFocus(): void {
    // do nothing

    if (this.IsEditable) {
      // let fldctrl: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('select');
      let fldctrl: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('field-control[data-controltype="dropdown"]');
      if (!isNullOrUndefined(fldctrl)) {
        fldctrl.forEach(fld => {
          if (!isNullOrUndefined(fld.querySelector('select'))) {
            fld.querySelector('select')?.blur();
          }
        });
      }

      // let form = this.eleRef.nativeElement.querySelector('form') as HTMLFormElement;
      // if (!isNullOrUndefined(form)) {
      //   // form.blur();

      //   // let fldctrl = form.querySelectorAll('select');
      //   // if (!isNullOrUndefined(fldctrl)) {
      //   //   fldctrl.forEach(fld => {
      //   //     fld.blur();
      //   //   });
      //   // }

      // }

    }

  }

  showFormCol(FieldName: string): boolean {
    return (this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0 ? true : false);
  }

  // setDispcols(): string {
  setDispcols(): void {
    let dispcols: string = '';
    if (this.FormTemplate !== undefined && this.FormTemplate !== null) {
      for (let i = 0; i < Object.keys(this.FormTemplate).length; i++) {
        if (!this.FormTemplate[i].IsHidden) {
          dispcols = dispcols + ',' + this.FormTemplate[i].FieldName;
        }
      }
    }

    // return (dispcols.length > 0 ? dispcols + ',' : '')
    this.DisplayColumns = (dispcols.length > 0 ? dispcols + ',' : '');

  }

  setSelectedCell(cell: any): void {
    if (cell !== undefined || cell !== null) {
      this.selectedCell = cell.row + '-' + cell.col;
    }
    else {
      this.selectedCell = '';
    }
  }

  validateForm(): boolean {
    let bFlg = true;

    if (this.FormTemplate !== undefined && this.FormTemplate !== null) {
      for (let i = 0; i < Object.keys(this.FormTemplate).length; i++) {
        if ((!this.FormTemplate[i].IsHidden) && this.FormTemplate[i].Editable) {
          let fldname = this.FormTemplate[i].FieldName;

          // (key.EditValidate.Required && (item[key.FieldName] === undefined ||
          // item[key.FieldName] === null || item[key.FieldName].length === 0))
          if (this.FormTemplate[i].EditValidate.Required && (this.FormData[fldname] === undefined ||
            this.FormData[fldname] === null || this.FormData[fldname] === 'null' || this.FormData[fldname].length === 0)) {
            bFlg = false;
            break;
          }

        }
      }
    }

    return bFlg;
  }

  enableFields(colmlist: string, bdisable?: boolean): void {
    let fieldlst = colmlist.split(',');

    this.editMode = this.IsEditable;

    for (let j = 0; j < fieldlst.length; j++) {
      let fieldname = fieldlst[j];

      // let fldctrl = this.eleRef.nativeElement.querySelector('field-control[data-fieldname="' + fieldname + '" i]') as HTMLElement;
      let fldctrl = this.eleRef.nativeElement.querySelector('field-control[data-fieldname="' + fieldname + '"]') as HTMLElement;
      if (!isNullOrUndefined(fldctrl)) {
        let ctrltype = fldctrl.dataset.controltype;

        switch (ctrltype) {
          case 'button':
            let btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;

            if (!isNullOrUndefined(btn)) {
              // btn['disabled'] = bdisable;
              btn.disabled = bdisable!;
            }
            break;

          case 'checkbox':
            let chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;

            if (!isNullOrUndefined(chkbx)) {
              // chkbx['disabled'] = bdisable;
              chkbx.disabled = bdisable!;
            }
            break;

          default:
            let x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;

            if (!isNullOrUndefined(x)) {
              x.click();
            }
            break;

        }

      }

    }

  }

  setTemplateReadOnly() {
    this.FormTemplate.forEach(item => item.Editable = 0);
  }

  initCellLock(item: any): void {

    console.log('inside initCellLock in GridDisp comp');

    let itemcelllock: Array<any> = [];
    // for each fieldtemplate
    this.FormTemplate.forEach((key: FieldTemplate, key_index: number) => {
      let editcell = false;

      if (key.Editable && item['IsEditable']) {
        // Check for cell level logic if defined in the template
        if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {
          editcell = true;
        }
        else {
          // if the cell is editable based on the row data and other custom conditions built in this component.
          editcell = !this.cellLock(key.FieldName, item, this.parent);
        }
      }

      let fldlock: any = { FieldName: key.FieldName, Lock: !editcell };
      itemcelllock.push(fldlock);
    
    });

    // finally add the lock object to datarow
    item['CellLock'] = itemcelllock;

  }

 

  // removeFormFocus(): void {
  //   // do nothing

  //   if (this.IsEditable) {
  //     // // let fldctrl: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('select');
  //     // let fldctrl: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll('field-control[data-controltype="dropdown"]');
  //     // if (!isNullOrUndefined(fldctrl)) {
  //     //   fldctrl.forEach(fld => {
  //     //     let temp: HTMLSelectElement = fld.querySelector('select');
  //     //     if (!isNullOrUndefined(temp)) {
  //     //       temp.blur();
  //     //     }
  //     //   });
  //     // }

  //     let form = this.eleRef.nativeElement.querySelector('form') as HTMLFormElement;
  //     if (!isNullOrUndefined(form)) {
  //       form.blur();

  //       // this.focusableEles = this.eleRef.nativeElement.querySelectorAll(`a[href], area[href],
  //       //  input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
  //       //  button:not([disabled]), [tabindex="0"]`);

  //       let fldctrl: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll(`input:not([disabled]), 
  //         select:not([disabled]), textarea:not([disabled]), button:not([disabled])`);

  //       if (!isNullOrUndefined(fldctrl)) {
  //         fldctrl.forEach(fld => {
  //           fld.blur();
  //         });
  //       }

  //     }

  //   }

  // }


  // getReadOnly(key: FieldTemplate, item: any): boolean {
  //   let bFlag = false; /// defaulting to editable
  //   bFlag = !key.Editable;

  //   if (!bFlag && !isNullOrUndefined(key.DataCell) && !isNullOrUndefined(key.DataCell.CustomLock) && this.cellLock !== undefined) {
  //     bFlag = this.cellLock(key.FieldName, item, this.parent);
  //   }

  //   // if (!bFlag && !isNullOrUndefined(item['CellLock'])) {
  //   //   let temp = item['CellLock'].filter(x=> x.cell === key.FieldName);
  //   //   if (temp.length > 0) {
  //   //     bFlag = temp[0].lock;
  //   //   }
  //   // }

  //   /// Checking parallel edit access and not locked by user
  // // if (!bFlag && !isNullOrUndefined(item.RowLockStatus) && item.RowLockStatus === RowLockStatus.Locked) {
  // //   bFlag = true; /// reset as non editable
  // // }
  //   // if (!bFlag && !isNullOrUndefined(item.DataRowlock) && item.DataRowlock.RowLockStatus === RowLockStatus.Locked) {
  //   //   bFlag = true; /// reset as non editable
  //   // }
  //   if (!this.IsEditable) {
  //     bFlag = true;
  //   }
  //   return bFlag;
  // }
}

