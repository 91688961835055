import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/app.sharedservice';
import { AppSharedService } from '../shared/app.sharedservice';
import { JwtHelper } from './jwthelper';
//import { ToastService } from 'src/app/sharedservices/toast.service';
import { environment } from 'src/environment/environment';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { ProcessStatus } from '../app.interface';


@Injectable()
export class AuthenticationService {
    public BaseUrl!: string;
    public token: any;

    constructor(private http: HttpClient, private _appSharedService: AppSharedService,
        private encryptDecryptService: EncryptDecryptService) {

        this.BaseUrl = environment.apiurl + "/UIConfig/";

        console.log('Baseurl in auth service = ' + this.BaseUrl);
    }


    private async getToken(mailid: string, actingmailid?: string, GGID?: string): Promise<any> {
        console.log('Calling getJwtToken');

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        let granttype = this.encryptDecryptService.encrypt('password');
        let UserMail = this.encryptDecryptService.encrypt(mailid.trim());
        let ActingUserMail = '';

        if (actingmailid !== undefined && mailid !== actingmailid) {
            ActingUserMail = this.encryptDecryptService.encrypt(actingmailid.trim());
        }
        
        let odata = {
            'grant_type': granttype,
            'UserMail': UserMail,
            'ActingUserMail': ActingUserMail,
            'GGID': GGID
        }

        console.log(JSON.stringify(odata));
        
        const response = await this.http.post<any>(this.BaseUrl + 'GetUserDetails', odata,
            {
                headers: headers,
                withCredentials: true
            }).toPromise().catch(x => {
                // this.toastr.warning('Access Denied', 'Login');
            });
        return response;

    }

    async getAPIToken(mailid: string, actingmailid?: string, GGID?: string): Promise<boolean> {
        console.log('login');

        let res = await this.getToken(mailid, actingmailid, GGID);

        console.log('getToken res >> ' + JSON.stringify(res));

        //debugger
        if (res != null && res.Status === ProcessStatus.Success) {
            // set token property
            this.token = res.Data;

            // console.log('parsedToken = ' + JSON.stringify(parsedToken));
            this._appSharedService.setApiToken(this.token, this.token);

            this._appSharedService.currentRoleData = this.token;
            this._appSharedService.currentRoleData$.next(this._appSharedService.currentRoleData);

            // store username and jwt token in local storage to keep user logged in between page refreshes
            if (!isNullOrUndefined(mailid)) {
                // Changing the localstorage to session as the switched user should not be persistent across sessions
                // localStorage.setItem('currentUser', mailid);
                sessionStorage.setItem('currentUser', mailid!);
            }

            // return true to indicate successful login
            return true;
        } else {
            // return false to indicate failed login
            return false;
        }

    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('currentUser');
    }

    checkTokenExpiry(): void {
        let userInfo = this._appSharedService.UserAccessInfo;
        this.logout();
        // get token
        this.getAPIToken(userInfo.email, userInfo.actingemail).then(result => {
            console.log('refresh token >> ' + JSON.stringify(result));
        });
        // }
    }

}
