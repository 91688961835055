<div>
    <loading [IsLoading]="IsLoading" [IsFullScreen]="false"></loading>

    <div class="dx-wrapper">
        <header class="dx-header">
            <div class="lefter justify-content-between">
                <div class="d-flex align-items-center logo-wrap" >
                    <a (click)="GotoHomePage()" class="capgemini">
                        <!-- <span class="capg-logo"><img src="../assets/images/capgemini_logo.png" alt=""></span> -->
                    </a>
                    <!-- <a (click)="GotoHomePage()" class="capgemini-apps bll"></a> -->
                    <a (click)="GotoHomePage()" class="capgemini-apps"><span class="product-logo">Supervisor Management System</span></a>
                </div>

                <ng-container *ngIf="(this._appSharedService.UserInfo != null && this._appSharedService.UserInfo != undefined 
                                    && this._appSharedService.UserInfo.email.length > 0 )">
                    <div class="dx-header-navbar">
                        <nav class="navbar navbar-expand-lg">
                            <div class="container-fluid">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNav">

                                    <ng-container *ngIf="(menuList != null && menuList != undefined && menuList.length > 0) &&
                                    (showNoMenuMessage == false && showNoDataMessage == false)">

                                        <menubar [menuType]="'mainbar'"
                                            [menuList]="(menuList | filterBy : {HasPermission :true})"
                                            (menuClick)="menuTabClick($event)" [defualtTab]="selectedTab"></menubar>
                                    </ng-container>

                                </div>
                            </div>
                        </nav>
                    </div>
                </ng-container>
            </div>
            <div class="righter" clickOutsideClose (clickOutsideClose)="CloseNotify()">
                <!-- 
                <div class="dx-box-help">
                    <a href="" class="icon-help"></a>
                </div> (click)="BellIconClick()" 
                -->
                <!--  -->



                <!-- <app-notification></app-notification> -->



                <div class="userguide-container">
                    <a class="userguide-button flex-vert-align-center" target="_self" href="User-Guide.pdf" download="User-Guide.pdf">
                    <span class="material-symbols-outlined">
                      download</span>
                      User Guide
                      </a>
                </div>


                <div *ngIf="usericonimage" class="dx-box-log" role="button" id="dropdownMenuParentLink" type="button"
                    data-toggle="collapse">
                    <!-- <a class="icon-log" [style.background-image]=" image != '' ? image : 'image'"
                        (click)="openSwitchUser()"></a> -->

                    <a class="icon-log" [style.background-image]=" image != '' ? image : 'image'"
                        (click)="openUserProfile()"></a>
                </div>
                <div *ngIf="!usericonimage" class="dx-box-initials" style="position: relative;" clickOutsideClose
                    (clickOutsideClose)="clickedOutside()">
                    <!-- <a class="icon-initials" [attr.data-initials]="initialicon" (click)="openSwitchUser()"></a> -->
                    <a class="icon-initials" [attr.data-initials]="initialicon" (click)="openUserProfile()"></a>

                    <div class="dx-window-custom dx-window-log"
                        [ngStyle]="{'display': IsuserActions ? 'block' : 'none'}">
                        <div class="dx-user-details">
                            <!-- <div class="dx-user-image">

                            </div> -->
                            <div class="dx-user-identity">
                                <h4 class="dx-user-name">{{userAccessInfo.Name}}</h4>
                                <a href="" class="dx-user-mailid" mailto="">{{userAccessInfo.ActingEmail}}</a>
                            </div>
                        </div>
                        <div class="dx-window-content dx-window-user" *ngIf="this._appSharedService.currentRoleData.IsSwitchUser == 1">
                            <ul class="list-group">
                                <li class="list-group-item"
                                    *ngIf="userAccessInfo != null">
                                    <a class="dx-log-list" (click)="openSwitchUser()">
                                        <span class="dx-log-icon"><i class="icon-dx-profile"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Switch profile</span>
                                        </span>
                                    </a>
                                </li>
                                <!-- <li class="list-group-item">
                                    <a class="dx-log-list">
                                        <span class="dx-log-icon"><i class="icon-dx-notifications"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Notifications</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="list-group-item-hr">
                                    <hr />
                                </li>
                                <li class="list-group-item">
                                    <a class="dx-log-list">
                                        <span class="dx-log-icon"><i class="icon-dx-projects"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Projects</span>
                                        </span>
                                    </a>
                                </li> -->
                                <!-- <li class="list-group-item" (click)="_delegationModelPopup()">
                                    <a class="dx-log-list">
                                        <span class="dx-log-icon"><i class="icon-dx-delegation"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Delegation</span>
                                        </span>
                                    </a>
                                </li> -->
                                <li class="list-group-item-hr">
                                    <hr />
                                </li>
                                <!-- <li class="list-group-item">
                                    <a class="dx-log-list">
                                        <span class="dx-log-icon"><i class="icon-dx-settings"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Settings</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="list-group-item">
                                    <a class="dx-log-list">
                                        <span class="dx-log-icon"><i class="icon-dx-logout"></i></span>
                                        <span class="dx-log-text">
                                            <span class="text">Logout</span>
                                        </span>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="clear"></div> -->
        </header>
        <router-outlet></router-outlet>
    </div>

    <!-- SwitchUser section -->
    <div *ngIf="displaySwitchUser">
        <app-switchuser (close)="closeSwitchUser($event)"></app-switchuser>
    </div>

    <!-- Loader for entire app -->
    <div>
        <loading [IsLoading]="AppIsLoading" [IsFullScreen]="true"></loading>
    </div>

</div>

<!-- Delegation  -->
<modal-popup class="modal-delegate-settings" id="DelegationPopup" style="display: none;" [modaldetail]="modalInf">
    <div class="dx-carry-form dx-new-form dx-delegate-settings">
        <div class="dx-carry-form">
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-box-group">
                        <div class="form-box-label"><label class="form-label">Select Action</label></div>
                        <div class="form-box-field">
                            <!-- <select class="form-select" [(ngModel)]="selectedAction" (change)="onChange($event)">
                                    <option value="Add Delegation">Add Delegation</option>
                                    <option value="Revoke">Revoke</option>
                                    <option value="History">History</option>
                                </select> -->
                            <ng-select [(ngModel)]="selectedAction" (change)="onChange($event)">
                                <ng-option *ngFor="let option of dateTypeOptions" [value]="option">{{ option}}
                                </ng-option>
                            </ng-select>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dx-select-delegation" *ngIf="IsDelegateAdd">
            <div class="dx-carry-form">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="form-box-group">
                            <div class="form-box-label"><label class="form-label">Select User</label></div>
                            <div class="form-box-field">
                                <!-- <input class="form-control" placeholder="Enter User Name" /> -->
                                <mat-form-field>
                                    <input matInput [(ngModel)]="empDLSearch" (ngModelChange)="gotoadduser()"
                                        placeholder="Type min. 3 characters to search username"
                                        [formControl]="searchEmployeeDLDetails" #empDLAuto [matAutocomplete]="empDL">
                                    <mat-autocomplete [panelWidth]="430" #empDL="matAutocomplete"
                                        (optionSelected)="onEmpDLSelected($event)" [displayWith]="displayWithEmpDL">
                                        <mat-option *ngIf="empDLLoading" class="is-loading matOptionIcon"
                                            style="padding-top: 0.25rem;padding-bottom: 0.25rem;padding-left: 0.5rem;font-size: 1rem;border-radius: 0.2rem;">
                                            Loading...
                                        </mat-option>
                                        <ng-container>
                                            <mat-option *ngFor="let usr of dlEmpList" class="matOptionIcon"
                                                [value]="usr"
                                                style="padding-top: 0.25rem;padding-bottom: 0.25rem;padding-left: 0.5rem;font-size: 1rem;border-radius: 0.2rem;"
                                                [disabled]="usr.FullName === 'No records found (Please provide a valid username to search)'">
                                                <!-- <span>{{usr.Key}}</span> -->
                                                <ng-container
                                                    *ngIf="usr.FullName === 'No records found (Please provide a valid username to search)'; else userOption">
                                                    <span>{{usr.FullName}}</span>
                                                </ng-container>
                                                <ng-template #userOption>
                                                    <span>{{usr.FullName}} ({{usr.Mail}})</span>
                                                </ng-template>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="form-group">
                            <label class="form-label">Reason for delegation *</label>
                            <textarea name="" id="" cols="30" rows="4" class="form-control"
                                [(ngModel)]="delegate_reason" placeholder="Enter the comment"
                                (keyup)="onKey($event)"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dx-select-revoke" *ngIf="IsDelegateRevoke">
            <div class="dx-cape-grid">
                <div class="dx-table-grid">
                    <div class="table-responsive">
                        <table class="table align-middle">
                            <thead>
                                <tr>
                                    <th>Delegated to</th>
                                    <th>Delegated on</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngIf="delegationRecords.length > 0; else revokeNoRecordsFound">
                                    <tr *ngFor="let record of delegationRecords">
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedUser_FullName }}</div>
                                        </td>
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedOn }}</div>
                                        </td>
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedReason }}</div>
                                        </td>

                                    </tr>
                                </ng-container>

                            </tbody>
                            <ng-template #revokeNoRecordsFound>
                                <tr>
                                    <td colspan="3">
                                        <div class="dx-texts text-center">No records found</div>
                                    </td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="dx-select-history" *ngIf="IsDelegateHistory">
            <div class="dx-cape-grid">
                <div class="dx-table-grid">
                    <div class="table-responsive">
                        <table class="table align-middle">
                            <thead>
                                <tr>
                                    <th>Delegated to</th>
                                    <th>Delegated on</th>
                                    <th>Revoked on</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngIf="delegationRecords.length > 0; else historyNoRecordsFound">
                                    <tr *ngFor="let record of delegationRecords">
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedUser_FullName }}</div>
                                        </td>
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedOn }}</div>
                                        </td>
                                        <td>
                                            <div class="dx-texts">{{ record.RevokedOn }}</div>
                                        </td>
                                        <td>
                                            <div class="dx-texts">{{ record.DelegatedReason }}</div>
                                        </td>
                                    </tr>
                                </ng-container>

                            </tbody>
                            <ng-template #historyNoRecordsFound>
                                <tr>
                                    <td colspan="4">
                                        <div class="dx-texts text-center">No records found</div>
                                    </td>
                                </tr>
                            </ng-template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="dx-box-btn righter">
            <button *ngIf="IsDelegateAdd || IsDelegateRevoke" type="button" class="btn btn-light"
                data-bs-dismiss="modal" (click)="CloseDelegationModelPopup()">{{btn_light_Name}}</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                *ngIf="IsDelegateAdd || IsDelegateRevoke" (click)="_onSubmitOrRevokeDelegation()"
                [disabled]="!disableAddTeamSubmit">{{btn_primary_Name}}</button>

            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                *ngIf="!IsDelegateAdd && !IsDelegateRevoke"
                (click)="CloseDelegationModelPopup()">{{btn_primary_Name}}</button>
        </div>
    </div>
</modal-popup>

<modal-popup class="modal-confirmation-message" id="delegationNotifications" style="display: none;"
    [modaldetail]="modalNotification">
    <div class="dx-swaddle">
        <p class="only-p">{{delegationMessage}}</p>
    </div>
    <div class="modal-footer">
        <div class="dx-box-btn righter">
            <button type="button" class="btn btn-primary" (click)="_closeDelegationNotificationPopup()">Ok</button>
        </div>
    </div>
</modal-popup>
<modal-popup class="modal-confirmation-message" id="InformationPopup" style="display: none;" [modaldetail]="modalInf">
    <div class="dx-swaddle">
        <p class="only-p">Technical issue occurred while saving the data. Please try again.</p>
    </div>
    <div class="modal-footer">
        <div class="dx-box-btn righter">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="closeModal('InformationPopup')">Ok</button>
        </div>
    </div>
</modal-popup>
<modal-popup class="modal-confirmation-message" id="Alert" style="display: none;" [modaldetail]="modalInf">
    <div class="dx-swaddle">
        <p class="only-p">No permission to access this page.</p>
    </div>
    <div class="modal-footer">
        <div class="dx-box-btn righter">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="closeModal('Alert')">Ok</button>
        </div>
    </div>
</modal-popup>
<modal-popup class="modal-confirmation-message" id="SuccessPopup" style="display: none;" [modaldetail]="modalInf">
    <div class="dx-swaddle">
        <p class="only-p">Email notification saved successfully</p>
    </div>
    <div class="modal-footer">
        <div class="dx-box-btn righter">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="closeModal('SuccessPopup')">Ok</button>
        </div>
    </div>
</modal-popup>

<!-- Session Expiry section -->
<div *ngIf="displaySessionExp">
    <session-modal [countMinutes]="session['countMinutes']" [countSeconds]="session['countSeconds']"
        [progressCount]="session['progressCount']" [maxCount]="session['maxCount']" (close)="closeSessionPopup($event)">
    </session-modal>
</div>