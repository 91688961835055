import { Observable, Subject, Subscription, filter, fromEvent } from 'rxjs';
import { MessageCodes } from '../app.config';


@Injectable({
    providedIn: 'root'
})
export class AppSharedService {

    public IsAuthUser: boolean = false;
    public IsAuthUser$ = new Subject<any>();

    public UserInfo: any;
    public UserInfo$ = new Subject<any>();


    public IsEditable: boolean = true;
    public IsEditable$ = new Subject<any>();


    public ApiToken: any;
    public ApiToken$ = new Subject<any>();

    public UserAccessInfo: any;

    public CurrentComponent: any;

    public AppIsLoading$ = new Subject<boolean>();
    public AppLoaderSubscription!: Subscription;

    public ActingUser$ = new Subject<string>();
    public SelectedLanguage$ = new Subject<string>();


    public containerData: any = {};
    public containerData$ = new Subject<string>();

    public currentRoleData: any;
    public currentRoleData$ = new Subject<string>();


    public LanguageDetail: any[] = [];
    public UserRoleList: any[] = [];
    public UserRoleList$ = new Subject<any>();

    public UserModuleList: any[] = [];

    // By default, date format for lang 'en-US' is set.
    public AppDateFormat: string = 'dd-MM-yyyy';
    // public AppDateTimeFormat: string = 'dd-MMM-yyyy hh:mm:ss a';
    public AppDateTimeFormat: string = 'dd-MM-yyyy hh:mm';
    // public canResetTab

    public employeeRoleCode: number = 0;
    public tabHomeName: string = 'Opportunities';
    public tabOpportunityDetailsName: string = 'OpportunityDetails';
    public tabReviewsName: string = '';
    public tabDetailsName: string = '';
    public DealStatus: string = '';
    public NextAction: string = '';
    public DealCategory: string = '';

    public PreviousURL: string = '';
    public _isURLComingFromMailParam: string = '';

    public manageDealnavParam: string = '';
    public detailsnavParam: string = '';
    public currentProjectData: any;


    constructor() {
        this.UserInfo = localStorage.getItem('userInfo');
        this.UserInfo = JSON.parse(this.UserInfo);
        this.currentRoleData = localStorage.getItem('currentRoleData');
        this.currentRoleData = JSON.parse(this.currentRoleData);
    }

    public setUserInfo(value: any) {
        this.UserInfo = value;
        this.UserInfo$.next(this.UserInfo);

        console.log('UserInfo.email >> UserInfo' + this.UserInfo.RoleName);
    }


    public getUserInfo(): Observable<any> {
        return this.UserInfo;
    }
    public getRoleInfo(): Observable<any> {
        return this.currentRoleData;
    }

    public setApiToken(token: any, accessinfo: any) {
        this.ApiToken = token;
        this.UserAccessInfo = accessinfo;

        this.ApiToken$.next(this.ApiToken);
    }

    public getApiToken(): Observable<any> {
        return this.ApiToken;
    }


    public getCurrentComponent(): any {
        return this.CurrentComponent;
    }

    private escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    public getMessage(msgcode: string, ...args: any[]): string {
        let msg = '', arg = '';

        msg = getProperty(MessageCodes, msgcode as any);

        // Check if place holders persent
        arg = '{args[';
        if (msg.indexOf(arg)) {
            msg = msg.replace(new RegExp(this.escapeRegExp(arg), 'g'), '$' + arg);
            msg = eval('`' + msg + '`');
        }

        return msg;
    }



}

export function AutoUnsubscribe(constructor: any) {

    // reference to ngDestroy func defined
    const original = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {



        for (let prop in this) {
            // unsubscription prop
            if (this[prop] instanceof Subscription) {
                const property = this[prop];
                property.unsubscribe();
            }

            // unsubscription array
            if (Array.isArray(this[prop]) && this[prop][0] instanceof Subscription) {
                const property = this[prop];
                property.forEach((element: any) => {
                    element.unsubscribe();
                });
            }

        }

        if (original && typeof original === 'function') { original.apply(this, arguments); }

    };

}

import { Directive, Input, ElementRef, Renderer2, Injectable } from '@angular/core';
import { string } from '@amcharts/amcharts4/core';

@Directive({
    selector: '[focused]'
})

export class FocusedDirective {

    @Input()
    set focused(value: boolean) {
        if (value) {
            // this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'scrollIntoViewIfNeeded');
            // this.elementRef.nativeElement.scrollIntoViewIfNeeded();
            this.elementRef.nativeElement.scrollIntoViewIfNeeded({ behavior: 'smooth', block: 'center' });
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
}


// export function isNullOrUndefined<T>(object: T | undefined | null): object is T {  
//   return <T>object !== undefined && <T>object !== null;  
// }  
export function isNullOrUndefined<T>(obj?: T | null): boolean {
    // null == undefined so this is true if obj = null or obj = undefined
    return obj == null;
}

export function isStringNullOrEmpty(obj: any): boolean {
    // null == undefined so this is true if obj = null or obj = undefined

    return (obj == null || obj.trim() == '') ? true : false;
}

export function isDate(obj: any): boolean {
    return obj instanceof Date && !isNaN(obj.getTime()) ? true : false;
};

export function isNumber(obj: any): boolean {
    return typeof obj === 'number';
};

export function isEmail(obj: any): boolean {
    return obj.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null ? false : true;
};

export function getProperty<Type, Key extends keyof Type>(obj: Type, key: Key) {
    return obj[key];
}

export function setProperty<Type, Key extends keyof Type>(obj: Type, key: Key, value: any) {
    return obj[key] = value;
}

import { Output, EventEmitter, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Directive({
    selector: '[clickOutsideClose]',
  })

  export class ClickOutsideCloseDirective implements AfterViewInit, OnDestroy {
    @Output() clickOutsideClose = new EventEmitter<void>();
  
    documentClickSubscription: Subscription | undefined;
  
    constructor(
      private element: ElementRef,
      @Inject(DOCUMENT) private document: Document
    ) {}
  
    ngAfterViewInit(): void {
      this.documentClickSubscription = fromEvent(this.document, 'click')
        .pipe(
          filter((event) => {
            return !this.isInside(event.target as HTMLElement);
          })
        )
        .subscribe(() => {
          this.clickOutsideClose.emit();
        });
    }
  
    ngOnDestroy(): void {
      this.documentClickSubscription?.unsubscribe();
    }
  
    isInside(elementToCheck: HTMLElement): boolean {
      return (
        elementToCheck === this.element.nativeElement ||
        this.element.nativeElement.contains(elementToCheck)
      );
    }
  }