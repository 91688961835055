<ng-container *ngIf="menuType=='mainbar'">
    <ul id="MainMenu" class="navbar-nav">
       
           <!-- <li> <a routerLink="/projectdetail">Project detail page</a></li>
            <li><a routerLink="/addemployee">Add Employee</a></li>
            <li><a routerLink="/activeallocation">Active Allocation</a></li>
            <li><a routerLink="/archivedallocation">Archived Allocation</a></li> -->

        
        <ng-container
            *ngTemplateOutlet="menuView; context:{ $implicit: (menuList | filterBy: {HasPermission:true}), parentTab: null, level:1 }">
        </ng-container>
        <ng-template #menuView let-tabList let-parentTab="parentTab" , let-level="level">
            <ng-template ngFor let-tab [ngForOf]="tabList">
                <li *ngIf="(tab.SubMenu.length==0)" class="nav-item"
                [ngClass]="{'active': (tab.Name == selTab)}">
                    <a [attr.aria-label]="tab?.DisplayName" class="nav-link"
                    
                        href="javascript:void(0)" aria-haspopup="true" aria-expanded="false"
                        (click)="opportunityMenuClik(tab.Name)" 
                        [routerLink]="[tab.RouteLink]" >
                        {{tab.DisplayName | translate}} </a>
                </li>
                
            </ng-template>
        </ng-template>
    </ul>
</ng-container>

<ng-container *ngIf="menuType=='opportunitiesmainbar'">
    <ul id="MainMenu" >
        <ng-container
            *ngTemplateOutlet="menuView; context:{ $implicit: (menuList | filterBy: {HasPermission:true}), parentTab: null, level:1 }">
        </ng-container>
        <ng-template #menuView let-tabList let-parentTab="parentTab" , let-level="level">
            <ng-template ngFor let-tab [ngForOf]="tabList">
                <li *ngIf="(tab.SubMenu.length==0)"
                [ngClass]="{'active': (tab.Name == selTab)}">
                    <a [attr.aria-label]="tab?.DisplayName"                    
                        href="javascript:void(0)" aria-haspopup="true" aria-expanded="false"
                        (click)="opportunityMenuClik(tab.Name)" >
                        {{tab.DisplayName | translate}} </a>
                </li>
                
            </ng-template>
        </ng-template>
    </ul>
</ng-container>


<!-- Sidebar Menu
<ng-container *ngIf="menuType=='sidebar'">
    <div class="d-flex h-100">
        <span class="sidebarFix d-none sr-hidden sidebarText collapse show"></span>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel">Navigation</h5>
                <button #closebutton type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>

            <div class="offcanvas-body p-0 sidebar-container">
                <ul class="list-group overflow-auto" id="tabMain">
                    <ng-container
                        *ngTemplateOutlet="tabView; context:{ $implicit: menuList | filterBy: {HasPermission:true} }">
                    </ng-container>

                    <ng-template #tabView let-tabList>
                        <ng-template ngFor let-tab [ngForOf]="tabList">
                            <li>
                                <ng-container *ngIf="tab.SubMenu.length==0">
                                    <a class="list-group-item" [ngClass]="{'active': (tab.Name == selTab)}"
                                        [attr.aria-label]="tab?.DisplayName" href="#tabMain"
                                        [routerLink]="[tab.RouteLink]" 
                                        title="{{tab.DisplayName |translate}}">
                                        <img alt="menubar-{{tab.DisplayName}}" *ngIf="tab.Src != null" class="admin-img"
                                            src="{{tab.Src}}">

                                        <span
                                            class="sidebarText collapse-horizontal collapse fade show">{{tab.DisplayName
                                            |translate}}</span>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="tab.SubMenu.length>0">
                                    <a class="list-group-item" [attr.aria-label]="tab?.DisplayName"
                                        data-bs-toggle="collapse" [href]="'#sidebar'+tab.Name" role="button"
                                        aria-expanded="false" [attr.aria-controls]="'sidebar'+tab.Name"
                                        [ngClass]="{'active': (tab.Active == true)}">
                                        
                                        <img alt="menubar-{{tab.DisplayName}}" *ngIf="tab.Src != null" class="admin-img"
                                            src="{{tab.Src}}">

                                        <span
                                            class="sidebarText collapse-horizontal collapse fade show">{{tab.DisplayName
                                            |translate}}</span>
                                    </a>

                                        <ul class="collapse sub-list-group ps-4" [id]="'sidebar'+tab.Name">
                                            <ng-container
                                                *ngTemplateOutlet="tabView; context:{ $implicit: tab.SubMenu | filterBy: {HasPermission:true} }">
                                            </ng-container>
                                        </ul>
                            
                                </ng-container>

                            </li>
                        </ng-template>
                    </ng-template>
                </ul>
            </div>
        </div>



        <div class="w-100 h-100">
            <div class="tab-content h-100">
                <div class="tab-pane fade active show">
                    <div class="h-100">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container> -->


