import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProjectdetailRoutingModule } from './projectdetail-routing.module';
import { ProjectdetailComponent } from './projectdetail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { GriddisplayModule } from "../../../modules/griddisplay/griddisplay.module";
import { LoadingModule } from "../../../modules/loading/loading.module";
import { ComponentsModule } from '../components.module';
import { ModalModule } from "../../../modules/modal/modal.module";
import { FormsModule } from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        RouterModule,
        ProjectdetailRoutingModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        NgbModule,
        BrowserModule,
        GriddisplayModule,
        LoadingModule,
        ComponentsModule,
        ModalModule,
        FormsModule,
        MatTooltipModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    exports: [
        
    ]
})
export class ProjectdetailModule { }
