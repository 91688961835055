import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { isNullOrUndefined } from 'util';
import { AppSharedService, isStringNullOrEmpty } from '../shared/app.sharedservice';
import { AuthenticationService } from '../auth/authentication.service';
import { navParams } from '../app.interface';

@Injectable()
export class AuthGuardService implements CanActivate {

    public actinguser!: string;
    public defaultlang!: string;

    constructor(private _authenticationService: AuthenticationService, private _appSharedService: AppSharedService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        let retFlag: boolean = false;

        if (this._authenticationService.token !== undefined) {
            retFlag = true;
        } else {
            let startSSOhotellingUrl = localStorage.getItem(navParams._localStorage_PreviousURL);
            if (isStringNullOrEmpty(startSSOhotellingUrl)) {

                localStorage.setItem(navParams._localStorage_PreviousURL, window.location.href);
            }
        }

        return retFlag;


    }
}