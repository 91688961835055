import { NgModule,  CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SearchByModule } from 'src/modules/searchby/searchby.module';
import { GriddisplayModule } from 'src/modules/griddisplay/griddisplay.module';
import { CustomPipeModule } from 'src/modules/custompipe/custompipe.module';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { FormModule } from 'src/modules/form/form.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
  declarations: [
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
    
  ],
  imports: [
    BrowserModule, FormsModule, SearchByModule, GriddisplayModule, CustomPipeModule,
    LoadingModule, FormModule, NgSelectModule,NgMultiSelectDropDownModule
  ],
  //   providers: [HomeService,Title],
  bootstrap: []
})
export class HomeModule { }
