<div *ngIf="sessionpage; else sessmodal">
    Your session has expired! 
</div>

<ng-template #sessmodal>
    <!-- Modal section -->
    <div class="modal" tabindex="-1" role="dialog" style="display:block;" #dragboundary>
        <div class="modal-dialog modal-lg ps-modal-dialog" role="dialog">
            <!-- Modal content -->
            <div class="ng-draggable modal-content"  ngDraggable [attr.inBounds]="true" [attr.bounds]="dragboundary" [attr.handle]="draghandle">
                <!-- Modal header -->
                <div class="modal-header bg-light drag-block-handle" #draghandle>
                    <h3 class="modal-title">
                        
                        <i class="fa fa-exclamation-triangle"></i> Session Timeout warning</h3>
                </div>

                <!-- Modal body -->
                <div class="modal-body ps-modal-body">
                    You will be timed out in {{(countMinutes != 0 ? + countMinutes+' Minute'+(countMinutes > 1 ? 's ' : ' ') : '') + countSeconds+'
                    Seconds'}}
                    <p>
                        <progressbar [max]="maxCount" [value]="progressCount" type="danger" [striped]="true" [animate]="true">
                            <span class="text-nowrap">{{progressCount}} / {{maxCount}}</span>
                        </progressbar>

                    </p>
                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="extendSession(true)">Continue</button>
                    <button type="button" class="btnNegative" data-bs-dismiss="modal" (click)="extendSession(false)">End</button>
                    <!-- <button type="button" class="btnNegative" data-bs-dismiss="modal" (click)="reloadpage()">End</button> -->
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal !-->
</ng-template>