export class ProcessResponse<T> {
    Data!: T;
    Status!: number;
    Message!: string;
    EventID!: string;
}

export interface UserInfo {
    name: string;
    email: string;
    username: string;
    userid: number;
    actingusername: string;
    actinguserid: number;

    exp?: number;
    iss?: string;
    aud?: string;
    jti?: string;
}

export interface ComponentConfig {
    // AppId: string;
    Component?: string;
    SubComponent?: string;
    ReferenceValueType?: string;
    ReferenceValue?: string;
    FieldTemplate?: any[];
    Permission?: string[];
    Properties?: any[];
    ComponentCode: string;
    ComponentViewId?: number;
}

export enum ProcessStatus {
    Success = 200,
    Queued = 202,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    NoContent = 404,
    Error = 500
}

export enum ReferenceValueType {
    Quality = "1",
    ProjectId = "2",
    Admin = "3"
}
export enum RowLockStatus {
    None = 0,
    Locked = 1,
    Edit = 2
}

export interface HubGroupMember {

    GroupName: string;
    UserId: number;
    Name: string;
    ConnectionId: string;
    LastActionOn: Date;
    RowIds: string[];
}

export class GroupDataRowLocks {
    constructor(init?: Partial<GroupDataRowLocks>) {
        Object.assign(this, init);
    }

    GroupName!: string;
    Locks!: DataRowlock[];
    DataObject: any;
    DataUniqueKey!: string;
}

export class DataRowlock {
    constructor(init?: Partial<DataRowlock>) {
        Object.assign(this, init);
    }
    GroupName!: string;
    UserId!: number;
    RowId!: string;
    Name!: string;
    ConnectionId!: string;
    LockedOn!: Date;
    RowLockStatus!: RowLockStatus;
    Data: any;
}




export enum ComponentCodes {
    AppComponent = 'C001',
    DashboardComponent = 'C002',
    Supervisor = 'C003',
    PeopleComponent = 'C004'
}


export enum ActionCode {
    Update_Check = 'AC001',
    Partial_Save = 'AC002',
}

export enum ServiceDomain {
    AM = 'AMSD003',
    Enhancement = 'AMSD0018'
}

export enum GuideLinesParam {
    Recommended = 'Recommended',
    ManualOverride = 'Manual Override',
    Cola = 'COLA %',
    NetReduction = 'Net Reduction'
}

export enum RateModellingOption {
    Capgemini = 'RTM001',
    External = 'RTM002'
}


export enum SCREEN_SIZE {
    XS,     // Extra small devices (portrait phones, less than 576px)
    SM,     // Small devices (landscape phones, 576px and up)
    MD,     // Medium devices (tablets, 768px and up)
    LG,     // Large devices (desktops, 992px and up)
    XL      // Extra large devices (large desktops, 1200px and up)
}
export class MenuItem {
    Icon: string = '';
    Name: string = '';
    DisplayName: string = '';
    SeqOrder: number = 0;
    IsDefault: boolean = false;
    ComponentCode: string = '';
    RouteLink: string = '';
    Active?: boolean = false;

    HasPermission?: boolean = false;

    SubMenu: MenuItem[] = [];
}
export class RiskAssessmentModelInterface {
    RowNumber!: number
    Tab!: string
    Category!: string
    CriteriaTop!: string
    CriteriaBottom!: string
    QuestionCode!: string
    QuestionDescription!: string
    QuestionLink!: string
    Answers!: string
}

export enum navParams {
    //Auth Guard Service
    _localStorage_PreviousURL = 'referralBLL',

    //app-main component
    _appSelectedTab_Opportunities = 'Opportunities',
    _appSelectedTab_Reports = 'Analytics',
    _appSelectedTab_Reviews = 'Assignedtome',

    //home component
    _navhomeURLParam = 'HOME',
    _homeURLParam = 'navigationParam',
    _navMailReviewApprovalFlag = 'review_approve',
    _navMailDocumentsFlag = 'documents',
    _OpportunityTab_Documents = 'Documents',
    _OpportunityTab_OpportunityDetails = 'OpportunityDetails',
    _nav_opportunitysummary = '/opportunitysummary/',

    //opportunitysummary component
    _navMailReviewSelectedTab = 'REVIEWS',
    _navMailDocumentsSelectedTab = 'DOCUMENTS'
}

export class Settings {
    Id!: string;
    Name!: string;
    isChecked!: boolean
  }