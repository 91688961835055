<div class="dx-content">
    <div class="header popup-section" *ngIf="this._appSharedService.currentRoleData.UserRoleId == 0">
        <div class="infotext"><span class="material-symbols-outlined">
            info
            </span>This application will load content based on below roles only: PCO, Delegate, People Manager, Supervisor. Kindly refer user guide for more details. If you are playing one of these roles and still no data available, kindly raise a service central ticket.</div>
    </div>
    <div class="dx-dashboard" *ngIf="displayprojectsdashboard">
      <div class="header popup-section">
        <div class="infotext"><span class="material-symbols-outlined">
          info
          </span> Project Code Owner will have Delegate button option while a Delegate user will not have this option</div>
      </div>
      <div class="legend-info-wrap">
        <ul>
          <li>Legends:</li>
          <li>
            <i class="cg-btn-icon supervisor-change"></i>
            Change supervisor</li>
          <li>
            <i class="icon-flipped fa fa-user-plus"></i>
            Delegate user</li>
         
        </ul>
      </div>
        <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
                    
          
                <app-griddisplay    
                    #overallgrid 
                    [FieldTemplate]="dealListTemplate" 
                    [GridData]="projectListData"[GridDataOrg]="projectListDataOrg"
                    [GridProp]="GridProp"
                    [IsPagination]="IsPagination"
                    [Isrecord]="Isrecord"
                    [GridDataTotalCount]="_gridTotalRecords"
                    (onFilter)="onFilter($event)"
                    (FieldClick)="onClick($event)"
                    (FieldChange)="onChange($event)">
                </app-griddisplay>
           
    </div>
  

</div>

<div *ngIf="displayNotifybox" class="alertbox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
      [curitem]="notify.item" (click)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<modal-popup id="AddDelegate" style="display: none;" [modaldetail]="modalInf" (NoEvent)="closeModal('AddDelegate')" (YesEvent)="InsertUpdateDelegations()">
  <div class="infotext"><span class="material-symbols-outlined">
    info
    </span> Only employees allocated to this project can be chosen as delegate</div>

    <div class="add-adjust-emp-wrap">
      <div class="">
        <div class="">
          <div class="content">
            <div class="header popup-section">
            <!--   <i aria-hidden="true"></i>  -->
             <h6>Delegate List</h6> 
             <!-- <p *ngIf="IsDelegateSelected">{{selectedDelegates}}</p> -->
             <p *ngIf="!IsDelegateSelected" style="color: #d94d4d;">No Employee has been granted delegate access to this project</p>
            </div>
            <div *ngIf="IsDelegateSelected" class="delegationsData-wrap">
              <div *ngFor="let item of delegationsData" >
                <div class="delegated-emp"><span>{{item.EmployeeName}}</span><i class="fa fa-remove" (click)="confirmremovedelegate(item)"></i></div>
              </div>
            </div>
  
            <div class="body">
              <form class="row g-3">
                <div class="col-12 mb-2">
                  <label for="delegatingfor" class="form-label"> Select Delegate(s) </label><span class="required">*</span>
                  <ng-multiselect-dropdown #multiselect [placeholder]="'Select Delegate(s)'" [settings]="dropdownSettings"
                    [data]="allocationsDatafordelegation" [(ngModel)]="selectedItems" name="delegateselection"
                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>
              </form>
            </div>
            <div *ngIf="(this.delegateemployeevalidationinfo != null && this.delegateemployeevalidationinfo != undefined)">
              <span class="required_withBG"> {{this.delegateemployeevalidationinfo}}</span>
             </div>
            
            <!-- <div class=""> 
           <label class="form-label">  Search Delegate <span class="required">*</span>
           </label> 
            </div> -->
            <!-- <div class="input-group">
              <input type="text" class="form-control" placeholder="Search by Email/Employee GGID" name="search" [(ngModel)]="searchEmployee">
              <div class="input-group-btn">
                <button class="btn btn-default bordergrey" type="submit" (click)="getEmployeeDetails(searchEmployee)"
                  ><i class="fa fa-search inputgroupsearch" aria-hidden="true"
                    ></i></button>
              </div>
            </div>
            <div *ngIf="(this.delegateemployeevalidationinfo != null && this.delegateemployeevalidationinfo != undefined)">
             <span class="required_withBG"> {{this.delegateemployeevalidationinfo}}</span>
            </div>
            <div class="resultsection">
              <div class="member-profile" *ngIf="(this.searchedEmployeeDataforvalidation != null && this.searchedEmployeeDataforvalidation != undefined && this.searchedEmployeeDataforvalidation.length > 0)">
                <p><b>Employee Name : </b><span>{{this.searchedEmployeeData.EmployeeName}}</span></p>
                <p><b>Email : </b><span>{{this.searchedEmployeeData.EmployeeEmailId}}</span></p>
                <p><b>GGID : </b><span>{{this.searchedEmployeeData.EmployeeGGID}}</span></p>
              </div>
            </div> -->
           
            <!-- <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="closeModal('AddDelegate')">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="InsertUpdateDelegations()">Delegate</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- please use below set of div to give extra space below dropdown --> 
  <div class="" style="height: 65px;"></div>
  </modal-popup>

  <modal-popup id="RemoveDelegateConfirmation" style="display: none;" [modaldetail]="modalInf" (NoEvent)="gotoadddelegate()" (YesEvent)="removedelegate()">

    <div class="add-adjust-emp-wrap">
      <div class="" >
        <div class="">
          <div class="content">
            <div class="header">
            </div>
  
            <div class="body">
              {{RemoveDelegateConfirmation}}
            </div>
            <!-- <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="gotoadddelegate()">
                  No
                </button>
                <button type="button" class="btn btn-primary" (click)="removedelegate()">Yes</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  
  </modal-popup>

  <modal-popup id="ChangeSupervisor" style="display: none;" [modaldetail]="modalInf">
    <div class="add-adjust-emp-wrap">
      <div class="">
        <div class="">
          <div class="content">  
            <div class="header popup-section">
                <div class="changesupervisortext"><span class="material-symbols-outlined">
                    info
                    </span> 
                    <ul>
                      <li>Select Employees dropdown might not display all employees allocated to this project as they need to match below criteria:</li>
                      <li>1) Employee should be from CSS</li>
                      <li>2) Employee allocation % for this project should be greater than that of other projects</li>
                      <li>3) If employee is allocated equally across multiple projects, employee will be available in the first project allocated</li>
                      <li>4) Employee allocation should not be for future date</li>
                    </ul>
                   
                    </div>
                    
                
            </div>
            <div></div>
            <div class="body">
              <form class="row g-3">
                <div class="col-12 mb-2">
                  <label for="changesupervisorfor" class="form-label"> Select Employee(s) for Supervisor Change </label>
                  <ng-multiselect-dropdown #multiselect [placeholder]="'Select Employee(s)'" [settings]="dropdownSettings"
                    [data]="allocationsDataforsupervisorchange" [(ngModel)]="selectedItems" name="changesupervisor"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </form>
              <div class="" style="margin-top: 15px;"><label class="form-label"> Search Supervisor <span class="required">*</span></label></div>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search by Employee GGID/Email" name="search" [(ngModel)]="searchEmployee">
                <div class="input-group-btn">
                  <button class="btn btn-default bordergrey" type="submit" (click)="getEmployeeDetailsforsupervisorchange(searchEmployee)"
                    ><i class="fa fa-search inputgroupsearch" aria-hidden="true"
                      ></i></button>
                </div>
              </div>
              <div *ngIf="(this.changeSupervisorvalidationinfo != null && this.changeSupervisorvalidationinfo != undefined)">
               <span class="required_withBG"> {{this.changeSupervisorvalidationinfo}}</span>
            </div>
            <div *ngIf="(this.sameSupervisorvalidationinfo != null && this.sameSupervisorvalidationinfo != undefined)">
                <span class="required_withBG"> {{this.sameSupervisorvalidationinfo}}</span>
            </div>

              <div></div>
              <div class="resultsection">
              <div class="member-profile" *ngIf="(this.searchedEmployeeDataforvalidation != null && this.searchedEmployeeDataforvalidation != undefined && this.searchedEmployeeDataforvalidation.length > 0)">
                <p><b>Name : </b><span>{{this.searchedEmployeeData.EmployeeName}}</span></p>
                <p><b>Email : </b><span>{{this.searchedEmployeeData.EmployeeEmailId}}</span></p>
                <p><b>GGID : </b><span>{{this.searchedEmployeeData.EmployeeGGID}}</span></p>
                <p><b>Grade : </b><span>{{this.searchedEmployeeData.EmployeeLocalGrade}}</span></p>
                <p><b>Reportees : </b><span>{{this.searchedEmployeeData.TaggedEmployeeCount}}</span></p>
              </div></div>
            </div>
            <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="closeModal('ChangeSupervisor')">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="InsertUpdateSupervisorMapping()">Change Supervisor</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </modal-popup>