import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environment/environment';


@Injectable()
export class SearchByService {

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private APIUrl: string;
    private CommAPIUrl: string;

    constructor(private _http: HttpClient) {
        this.APIUrl = environment.baseURL + '/Home/';
        this.CommAPIUrl = environment.baseURL + '/';
    }

    private handleError(error: any): Promise<any> {
        console.error('Error : ', error.message);
        return Promise.reject(error.message || error);
    }

}
