<div class="dx-content new">
    <div class="dx-states-encase">


        <div class="dx-states">
            <div class="dx-states-back">
                <a (click)="GotoHomePage()">
                    <span class="icon-page-back"></span>
                </a>
            </div>
            <div class="dx-states-ids">
                <div class="text">{{this.ProjectData.ProjectName}}</div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">Project Code</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.ProjectCode}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">Project Start Date</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.StartDate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">Project End Date</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.EndDate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">Client Name</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.ClientName}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">BU/MU</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.BUMU}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-states-action">
                <div class="dx-box">
                    <div class="dx-head">
                        <div class="text">Project Code Owner</div>
                    </div>
                    <div class="dx-data">
                        <div class="text">
                            <a>{{this.ProjectData.ProjectCodeOwner}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="dx-content">
    <div class="dx-dashboard">
        <div class="button-container flexwith2section">
            <div class="header popup-section" style="width: calc(100% - 180px);">
                <div class="changesupervisortext">
                    <span class="material-symbols-outlined">
                        info
                        </span>
                     <ul>

                       <li> Change Supervisor will be allowed (indicated with icon <p class="greentdot"></p>) to Employees allocated to this project as they need to match below criteria:</li>
                   
                       <li> 1) Employee should be from CSS</li>
                        <li> 2) Employee allocation % for this project should be greater than that of other projects</li>
                            <li> 3) If employee is allocated equally across multiple projects, employee will be available in the first project allocated</li>
                                <li>4) Employee allocation should not be for future date</li>
                    </ul>
                   </div>
                       
            </div>
            <!-- <button class="float-right" (click)="onClickHierarchy()" ><i class="fa fa-sitemap" aria-hidden="true"></i></button>           
            <button class="float-right"><i class="fa fa-list"></i></button> -->
            <div class="flex-column-btn-wrap">

            
            <button class="float-right" (click)="ChangeSupervisor()">Change Supervisor</button>
            <button class="float-right" (click)="GetEmployeesListToExcel()">Download Employees List</button>
        </div>

        </div>
        <div class="legend-info-wrap">
            <ul>
                <li>Legends:</li>
              
                <li>
                  <i class="cg-btn-icon supervisor-change"></i>
                  Change supervisor</li>
                  <li>
                    <i class="icon-flipped fa fa-history"></i>
                    Supervisor History</li>
              </ul>
          </div>
        <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
            <app-griddisplay  *ngIf="!IsShowHierarchy"  
                #overallgrid 
                [FieldTemplate]="allocationsTemplate" 
                [GridData]="allocationsData"[GridDataOrg]="allocationsData"
                [GridProp]="GridProp"
                [IsPagination]="IsPagination"
                [Isrecord]="Isrecord"
                [GridDataTotalCount]="_gridTotalRecords"
                (onFilter)="onFilter($event)"
                (FieldClick)="onClick($event)"
                (FieldChange)="onChange($event)">
            </app-griddisplay>   
            <app-hierarchy-structure *ngIf="IsShowHierarchy"></app-hierarchy-structure>   
    </div>
</div>

<div *ngIf="displayNotifybox" class="alertbox">
    <notification [notifyinfo]="notify?.info" [timeout]="notify.timeout" [curaction]="notify.action"
      [curitem]="notify.item" (click)="setNotifyRes($event)" (close)="closeNotifybox()"></notification>
</div>

<modal-popup id="ChangeSupervisor" style="display: none;" [modaldetail]="modalInf" (NoEvent)="closeModal('ChangeSupervisor')" (YesEvent)="InsertUpdateSupervisorMapping()">
    <div class="add-adjust-emp-wrap">
      <div class="">
        <div class="">
          <div class="content">  
            <div class="header popup-section">
                <div class="changesupervisortext"><span class="material-symbols-outlined">
                    info
                    </span>
                    <ul>
                        <li> Select Employees dropdown might not display all employees allocated to this project as they need to match below criteria:</li>
                        <li>1) Employee should be from CSS</li>
                        <li>2) Employee allocation % for this project should be greater than that of other projects</li>
                        <li>3) If employee is allocated equally across multiple projects, employee will be available in the first project allocated</li>
                        <li>4) Employee allocation should not be for future date</li>
                    </ul>
                  
                    </div>
                    
                
            </div>
            <div></div>
            <div class="body">
              <form class="row g-3">
                <div class="col-12 mb-2">
                  <label for="changesupervisorfor" class="form-label"> Select Employee(s) for Supervisor Change </label>
                  <ng-multiselect-dropdown #multiselect [placeholder]="'Select Employee(s)'" [settings]="dropdownSettings"
                    [data]="allocationsDataforsupervisorchange" [(ngModel)]="selectedItems" name="changesupervisor"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </form>
              <div class="" style="margin-top: 15px;"><label class="form-label"> Search Supervisor <span class="required">*</span></label></div>
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search by Employee GGID/Email" name="search" [(ngModel)]="searchEmployee">
                <div class="input-group-btn">
                  <button class="btn btn-default bordergrey" type="submit" (click)="getEmployeeDetails(searchEmployee)"
                    ><i class="fa fa-search inputgroupsearch" aria-hidden="true"
                      ></i></button>
                </div>
              </div>
              <div *ngIf="(this.changeSupervisorvalidationinfo != null && this.changeSupervisorvalidationinfo != undefined)">
               <span class="required_withBG"> {{this.changeSupervisorvalidationinfo}}</span>
            </div>
            <div *ngIf="(this.sameSupervisorvalidationinfo != null && this.sameSupervisorvalidationinfo != undefined)">
                <span class="required_withBG"> {{this.sameSupervisorvalidationinfo}}</span>
            </div>

              <div></div>
              <div class="resultsection">
              <div class="member-profile" *ngIf="(this.searchedEmployeeDataforvalidation != null && this.searchedEmployeeDataforvalidation != undefined && this.searchedEmployeeDataforvalidation.length > 0)">
                <p><b>Name : </b><span>{{this.searchedEmployeeData.EmployeeName}}</span></p>
                <p><b>Email : </b><span>{{this.searchedEmployeeData.EmployeeEmailId}}</span></p>
                <p><b>GGID : </b><span>{{this.searchedEmployeeData.EmployeeGGID}}</span></p>
                <p><b>Grade : </b><span>{{this.searchedEmployeeData.EmployeeLocalGrade}}</span></p>
                <p><b>Reportees : </b><span>{{this.searchedEmployeeData.TaggedEmployeeCount}}</span></p>
              </div></div>
              <div class="changesupervisortext" *ngIf="(this.reporteestaggedcountinfo != null && this.reporteestaggedcountinfo != undefined && this.reporteestaggedcountinfo != '')"><span class="material-symbols-outlined">
                info
                </span>{{this.reporteestaggedcountinfo}}</div>
            </div>
            <!-- <div class="modal-footer">
              <div class="right-side-btngroup">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="closeModal('ChangeSupervisor')">
                  Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="InsertUpdateSupervisorMapping()">Change Supervisor</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  
  </modal-popup>

  <modal-popup id="supervisorhistory" style="display: none;" [modaldetail]="modalInf">
    <div>
        <div>
            <div [hidden]="!IsLoading"><loading [IsLoading]="IsLoading" [IsFullScreen]="true"></loading></div>
                    <app-griddisplay    
                        #overallgrid 
                        [FieldTemplate]="supervisorhistoryTemplate" 
                        [GridData]="supervisorhistoryData"[GridDataOrg]="supervisorhistoryData"
                        [GridProp]="GridProp"
                        [IsPagination]="IsPagination"
                        [Isrecord]="Isrecord"
                        (onFilter)="onFilter($event)"
                        (FieldClick)="onClick($event)"
                        (FieldChange)="onChange($event)">
                    </app-griddisplay>               
        </div>
    </div>
  </modal-popup>