import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../common/header/header.component';
import { FooterComponent } from '../common/footer/footer.component';
import { ProjectdetailComponent } from './projectdetail/projectdetail.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfileComponent } from './profile/profile.component';
import { LaunchpadComponent } from './launchpad/launchpad.component';
import { RouterModule } from '@angular/router';
//import { ProjectdetailRoutingModule } from './projectdetail-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { GriddisplayModule } from "../../modules/griddisplay/griddisplay.module";
import { LoadingModule } from "../../modules/loading/loading.module";
import { ModalModule } from "../../modules/modal/modal.module";
import { FormsModule } from '@angular/forms';
import {FormModule} from "../../modules/form/form.module";
import {MatTooltipModule} from '@angular/material/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HierarchyStructureComponent } from './hierarchy-structure/hierarchy-structure.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { PeopleComponent } from './people/people.component';
import { NotificationModule } from "../../modules/notification/notification.module";



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ProfileComponent,
    LaunchpadComponent,
    ProjectdetailComponent,
    HierarchyStructureComponent,
    PeopleComponent,
    ActivityLogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    BsDropdownModule,
    NgbModule,
    BrowserModule,    
    GriddisplayModule,
    LoadingModule,
    ModalModule,
    FormsModule,
    FormModule,
    MatTooltipModule,
    NotificationModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ProfileComponent,
    LaunchpadComponent
  ]
})
export class ComponentsModule { }
