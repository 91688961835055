import { Component,OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentCodes, ComponentConfig, ProcessStatus } from 'src/app/app.interface';
import { CommonAPIService } from 'src/app/shared/app.commonservice';
import { AppSharedService } from 'src/app/shared/app.sharedservice';
import { FieldTemplate } from 'src/modules/_classes/utility.interface';
import { HomeService } from '../home/home.service';


@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit { 
  
  public subscriptionList$: Subscription[] = [];
  public IsLoading: boolean = false;
  public activityLogData: any = {};
  public activityLogTemplate: FieldTemplate[] = [];
  public IsPagination: boolean = false;
  public Isrecord: boolean = false;
  public templateHeader!: ComponentConfig;
  public GridProp: any = {
    Editable: true,
    PaginationConfig: {
      ItemsPerPage: 10
    },
    IsColResizable: false
  }

  constructor(private _appSharedService: AppSharedService, private homeService: HomeService, private _commonAPIService: CommonAPIService) {
    let subscribe$: Subscription = this._appSharedService.currentRoleData$.subscribe((data: any) => {
      this.ngOnInit();
    });
    this.subscriptionList$.push(subscribe$);
  }

  data:any = [
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    },
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    },
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    },
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    },
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    },
    {
      Title:"Supervisor Change",
      Description:"You have successfully changed the [EMPLOYEE]s supervisor from [OLD SUPERVISOR ] to [NEW SUPERVISOR ] was updated.",
      Date:"16-02-2023",
      initialicon:'SP'
    }
    
  ]

 

  ngOnInit(): void {
    this.getComponentTemplate();
   
  }

  getComponentTemplate(): void {
    this.IsLoading = true;
    this.activityLogTemplate = [];
    let odata: ComponentConfig = {
      Component: 'Dashboard', ComponentCode: ComponentCodes.DashboardComponent, ReferenceValueType: "1"
    };

    this.IsLoading = true;
    this.templateHeader = odata;
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {
        let template = res.Data.FieldTemplate!;
        this.activityLogTemplate = template.filter(x => x.Group === 'ActivityLog');
        this.getPCOActivityLog();
      }
    },
      err => {
        this.IsLoading = false;
        console.log('Error on getRequestTemplate.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  getPCOActivityLog() {  
    this.IsLoading = true;  
    let subscribe$: Subscription = this.homeService.getPCOActivityLog().subscribe(result => {
      if (result !== undefined && result !== null && result.Status === ProcessStatus.Success) {
        if(result.Data != null){
          this.activityLogData = result.Data;
          this.IsPagination = true;
          this.Isrecord = false;
        }
        else if (result.Data == null) {
          this.activityLogData = [];
          this.IsPagination = false;
          this.Isrecord = true;
        }        
      }      
      this.IsLoading = false;
    },
      err => {
        this.IsLoading = false;
      });
    this.subscriptionList$.push(subscribe$);
  }
  onFilter(value: any) {
  }
  onClick(event: any): void {
  }
  onChange(event: any) {
  }

}
