<!-- Modal section -->
<div class="modal" tabindex="-1" role="alert" style="display:block;">
  <ng-container *ngIf="modaldetail != null && modaldetail != undefined">
    <div [ngClass]="modaldetail.size!=null ? modaldetail.size : 'modal-dialog  modal-dialog-centered'"
      class="modal-dialog  modal-dialog-centered">
      <!-- Modal content -->
      <div class="modal-content" [style.width.px]="modaldetail.width!='' ? modaldetail.width : null"
        [style.height.px]="modaldetail.height!='' ? modaldetail.height : null">
        <!-- Modal header -->
        <div class="modal-header">
          <h1 class="modal-title">{{modaldetail.header}}</h1>
          <button *ngIf="modaldetail.close == null ? true : modaldetail.close" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()">
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <ng-content></ng-content>
        </div>

        <!-- Modal footer -->
        <!-- <div class="modal-footer">
          
        </div> -->

        <div class="modal-footer">
          <div class="right-side-btngroup">
            <button *ngIf="modaldetail.no != null && modaldetail.no != undefined" type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="btnNoClick()">{{modaldetail.no}}</button>
            <button *ngIf="modaldetail.yes != null && modaldetail.yes != undefined" type="button" class="btn btn-primary" (click)="btnYesClick()">{{modaldetail.yes}}</button>
          </div>
        </div>

      </div>
    </div>
  </ng-container>
</div>