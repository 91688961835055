import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProcessResponse } from '../../app.interface';
import { environment } from '../../../environment/environment';
import { AppSharedService } from '../../shared/app.sharedservice';


@Injectable()
export class HomeService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private APIUrl: string;

  constructor(private _http: HttpClient, private _appSharedService: AppSharedService) {
    this.APIUrl = environment.UIConfigURL;

  }


  private handleError(error: any): Promise<any> {
    //console.error('Error : ', error.message);
    return Promise.reject(error.message || error);
  }



  // getActiveTemplate(email: string): Observable<any> {
  //   let Params = new HttpParams();
  //   Params = Params.append('email', email);
  //   return this._http.get(environment.apiurl + '/UIConfig/ActiveTemplate',
  //     { headers: this.headers, params: Params })
  //     .pipe(catchError(this.handleError));
  // }
  getActiveTemplateRiview(email: string): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('email', email);
    return this._http.get(environment.apiurl + '/UIConfig/ActiveTemplateRiview',
      { headers: this.headers, params: Params })
      .pipe(catchError(this.handleError));
  }

  getProjectsList(userroleid: any): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('userroleid', userroleid);
    return this._http.get(environment.apiurl + '/Supervisor/GetProjectsList',
      { headers: this.headers, params: Params })
      .pipe(catchError(this.handleError));
  }
  templateUpdate(model: any): Observable<any> {
    let Params = new HttpParams();
    let headers1 = new HttpHeaders();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/UIConfig/ComponentTemplateUpdate', model, {
      headers: headers1, params: Params
    }).pipe(catchError(this.handleError));
  }
  ActiveTemplateRiviewUpdate(model: any): Observable<any> {
    let Params = new HttpParams();
    let headers1 = new HttpHeaders();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/UIConfig/ActiveTemplateRiviewUpdate', model, {
      headers: headers1, params: Params
    }).pipe(catchError(this.handleError));
  }
  _insertUpdateDelegateAccess(model: any): Observable<any> {
    let Params = new HttpParams();
    // let headers1 = new HttpHeaders();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/UIConfig/InsertUpdateDelegateAccess', model, {
      headers: this.headers, params: Params
    }).pipe(catchError(this.handleError));
  }
  getMyReviewDeals(): Observable<any> {
    let Params = new HttpParams();
    return this._http.get(environment.apiurl + '/Dashboard/GetMyReviewDeals',
      { headers: this.headers, params: Params })
      .pipe(catchError(this.handleError));
  }

  _getDelegationDetails(selectedAction: any, actionBy: any): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('selectedAction', selectedAction);
    Params = Params.append('actionBy', actionBy);
    return this._http.get(environment.apiurl + '/UIConfig/GetDelegationDetails',
      { headers: this.headers, params: Params })
      .pipe(catchError(this.handleError));
  }

  getAllocationsofProject(projectCode: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('projectId', projectCode);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetAllocationsofProject',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdateDelegations(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Supervisor/InsertUpdateDelegations', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDelegationsofProject(projectCode: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('projectId', projectCode);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetDelegationsofProject',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmployeeDetails(searchtext: any, projectCode: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('searchtext', searchtext);
    Params = Params.append('projectId', projectCode);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetEmployeeDetails',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertUpdateSupervisorMapping(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Supervisor/InsertUpdateSupervisorMapping', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getPCOActivityLog(): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetPCODelegateActivityLog',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  _GetHierarchyData(oData: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('searchtext', oData.SearchText);
    Params = Params.append('mode', oData.Mode);
    Params = Params.append('UserId', oData.UserId);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetHierarchyData',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  InsertDelegations(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Supervisor/InsertDelegations', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  RemoveDelegations(odata: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Supervisor/RemoveDelegations', odata,
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  DownloadSupervisorHistory(searchBy : any, searchText : any, bu: any, sbu: any, searchEmployee: any, StartDateSelected: any, EndDateSelected: any, UserId: any, UserRoleId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('searchBy', searchBy);
    Params = Params.append('searchText', searchText);
    Params = Params.append('bu', bu);
    Params = Params.append('sbu', sbu);
    Params = Params.append('employee', searchEmployee);
    Params = Params.append('startdate', StartDateSelected);
    Params = Params.append('enddate', EndDateSelected);
    Params = Params.append('userId', UserId);
    Params = Params.append('userRoleId', UserRoleId);
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetSupervisorHistoryToExcel',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmpListforMyPeopleDashboard(oData: any, userroleid : any, searchBy : any, searchText : any, bu : any, sbu : any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('pageSize', oData.PageSize);
    Params = Params.append('pageIndex', oData.PageIndex);
    Params = Params.append('searchValue', oData.SearchValue);
    Params = Params.append('orderColumn', oData.OrderColumn);
    Params = Params.append('orderDirection', oData.OrderDirection);
    Params = Params.append('filters', oData.Filters);
    Params = Params.append('userroleid', userroleid);
    Params = Params.append('searchBy', searchBy);
    Params = Params.append('searchText', searchText);
    Params = Params.append('bu', bu);
    Params = Params.append('sbu', sbu);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetEmpListforMyPeopleDashboard',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  getsupervisorhistory(employeeEmailId : any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('employeeEmailId', employeeEmailId);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetSupervisorHistory',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  checkifemployeesupervisorvalid(searchEmployee : any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('searchEmployee', searchEmployee);
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/Checkifemployeesupervisorvalid',
      { headers: this.headers, params: Params })
      .pipe(
        catchError(this.handleError)
      );
  }

  GetSupervisorHierarchyToExcel(searchtext: any, mode: any, UserId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('searchtext', searchtext);
    Params = Params.append('mode', mode);
    Params = Params.append('UserId', UserId);
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetSupervisorHierarchyToExcel',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  GetEmployeesListToExcel(projectId: any): Observable<ProcessResponse<any>> {
    let Params = new HttpParams();
    Params = Params.append('projectId', projectId);
    const httpOption: Object = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: Params,
      responseType: 'arraybuffer',
      observe: 'response'
    };
    return this._http.get<ProcessResponse<any>>(environment.apiurl + '/Supervisor/GetEmployeesListToExcel',
      httpOption)
      .pipe(
        catchError(this.handleError)
      );
  }

  // GetBellNotifications(): Observable<any> {
  //   let Params = new HttpParams();
  //   return this._http.get(environment.apiurl + '/Dashboard/GetBellNotifications',
  //     { headers: this.headers, params: Params })
  //     .pipe(catchError(this.handleError));
  // }

  // UpdateBellNotificationReadStatus(BellNotificationsId: any): Observable<any> {
  //   let Params = new HttpParams();
  //   Params = Params.append('BellNotificationsId', BellNotificationsId);
  //   let headers1 = new HttpHeaders();
  //   return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Dashboard/UpdateBellNotificationReadStatus', BellNotificationsId, {
  //     headers: headers1, params: Params
  //   }).pipe(catchError(this.handleError));
  // }

  // GetNotificationSettings(): Observable<any> {
  //   let Params = new HttpParams();
  //   return this._http.get(environment.apiurl + '/Dashboard/GetNotificationSettings',
  //     { headers: this.headers, params: Params })
  //     .pipe(catchError(this.handleError));
  // }

  // InsertUpdateNotificationSettings(data: any): Observable<any> {
  //   let Params = new HttpParams();
  //   return this._http.post<ProcessResponse<any>>(environment.apiurl + '/Dashboard/InsertUpdateNotificationSettings', data,
  //    { headers: this.headers, params: Params
  //   }).pipe(catchError(this.handleError));
  // }
}

