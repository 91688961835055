import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation,
  OnChanges, SimpleChanges, SimpleChange, AfterViewInit, OnDestroy, Renderer2
} from '@angular/core';
import { isNullOrUndefined } from '../_services/utility.service';
import { RowLockStatus, SCREEN_SIZE } from 'src/app/app.interface';
import {
  FieldTemplate, GridConfig, GridConst, GridProperty, NestedTemplate,
  Pagination, PaginationConfig, FieldEventData, ActionBarConfig
} from '../_classes/utility.interface'
import { ElioScopeService } from '../_services/ElioScope.service';
import { UtilityService } from '../_services/utility.service';
import { OrderByPipe } from '../custompipe/custompipe.module';
import { JSONParser } from '@amcharts/amcharts4/core';
import { ResizeService } from 'src/app/screen-size-detector/resize.service';
import { FilterByPipe } from '../custompipe/custompipe.module';
import { delay } from 'rxjs/operators';
import { AppSharedService, isStringNullOrEmpty } from 'src/app/shared/app.sharedservice';






@Component({
  selector: 'app-griddisplay',
  templateUrl: './griddisplay.component.html',
  styleUrls: ['./griddisplay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [OrderByPipe, FilterByPipe]

})
export class GriddisplayComponent {

  @Input() GridId?: any;
  @Input() FieldTemplate: Array<FieldTemplate> = [];
  @Input() GridData: any;
  @Input() GridDataOrg: any;
  @Input() GridDataTotalCount: number = 0;
  @Input() GridProp: GridProperty = {};
  @Input() GridConfig: GridConfig = {};
  @Input() IsParallelEditEnabled: boolean = false;
  @Input() GlobalConfig: any = {};
  @Input() NestedTemplate!: NestedTemplate;
  @Input() cellLock!: Function;
  @Input() cellRequired!: Function;
  @Input() CustomCss!: Function;
  @Input() parent: any;
  @Input() IsPagination: boolean = false;
  @Input() IsPaginationBody: boolean = false;
  @Input() Isrecord: boolean = false;

  @Input() HierarchyParentData?: any = null;

  //@Output('onFilter') onFilter2: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output('onFilter') onFilter = new EventEmitter<{ filter: any, key: any }>();
  @Output() close = new EventEmitter();

  @Output() FieldClick = new EventEmitter<any>();
  @Output() FieldChange = new EventEmitter<any>();
  @Output() FieldEditStart = new EventEmitter<any>();
  @Output() PageChange = new EventEmitter<any>();
  @Output() public ActionBarClick = new EventEmitter<any>();
  // Grid properties
  // Default values
  public TableCSS: string = '';
  public HeaderCSS: string = '';
  public AlternateRowColor: any = { odd: 'white', even: 'white' };
  public IsEditable: boolean = true;
  public DisplayHeader: boolean = true;
  public TableWidth: number = 100;
  public PaginationConfig: PaginationConfig = {
    Required: true,
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    PageList: false,
    ServerLoad: false
  };
  public IsSortable: boolean = true;
  public IsColResizable: boolean = true;

  public DisplayColumns: string = '';
  public GridTotalWidth: number = 0;
  public selectedCell: string = '';
  public selectedCellItemData: any;
  public prevSelectedCell: string = '';
  public IsNested: boolean = false;
  public ParentField: string = '';
  public FilterField: string = '';
  public selectedNest: any[] = [];
  public LeafCountField: string = '';
  public IsNestedDynamicData: boolean = false;
  // public NestedGridDataTotalCount: any[] = [];
  public _NewRowInd: number = -1;

  public DataFilter: any = {};

  public Pagination: Pagination = {
    ItemsPerPage: GridConst.DEFAULTITEMSPERPAGE,
    CurrentPage: 1,
    TotalItems: 0,
    TotalPages: 0
  };

  public dispFilt: boolean = false;

  public editMode: boolean = false;
  public currCell!: HTMLTableDataCellElement;

  public datacellindx = 0;
  public sortdirection: number = -1;
  public sortcolumn: string = '';
  public filtercolumn: any = [];
  public sortcolumntype: string = '';
  public isDesc: boolean = false;

  public currentDataList: any;

  public thElm: any;
  public startOffset: any;
  public curItemsPerPage: number = 10;
  public screenSize!: SCREEN_SIZE;
  public pagesPerchange?: any;
  public ActionBarConfig: ActionBarConfig = {
    ActionBarList: null,
    noActionClass: false
  };

  public gridRandomId: string = '';
  public DataTotalCount: number = 0;
  public UniqueKeyName: string = '';
  public displayNotifybox: boolean = false;
  private ObjectType: any;
  public gotopage: any;
  public curFiltCondn: string = '';
  public DispFilter: boolean = false;
  public selectedcolfilter: string = '';
  public searchValue: any = [];
  public FilterColdd: any = [];
  public FilterColddOrg: any = [];
  public selectedFilter: any = [];
  Idcolumn: any;
  public selectAll: boolean = false;
  public highlightFilter: boolean = false;
  public SelectedData: any;


  constructor(private _elioScopeService: ElioScopeService, private _elioUtilityService: UtilityService,
    private eleRef: ElementRef, private resizeSvc: ResizeService, private orderByPipe: OrderByPipe,
    private filterByPipe: FilterByPipe, private renderer: Renderer2, public _appSharedService: AppSharedService) {

    this.gridRandomId = Math.random().toString(36).substr(2, 5);

    if (/^\d$/.test(this.gridRandomId.charAt(0))) {
      this.gridRandomId = "a" + this.gridRandomId;
    }

    // Subscription for Resize 
    this.resizeSvc.onResize$
      .pipe(delay(0))
      .subscribe(x => {
        this.screenSize = x;
      });

    // To initialise screenSize
    window.dispatchEvent(new Event('resize'));

  }

  ngOnInit() {

console.log("check------",this.IsPaginationBody)
    console.log(this.DisplayHeader)
    if (this.GridProp !== undefined) {
      this.TableCSS = (this.GridProp.TableCSS === undefined ? this.TableCSS : this.GridProp.TableCSS);
      this.HeaderCSS = (this.GridProp.HeaderCSS === undefined ? this.HeaderCSS : this.GridProp.HeaderCSS);
      this.AlternateRowColor = (this.GridProp.AlternateRowColor === undefined ? this.AlternateRowColor : this.GridProp.AlternateRowColor);
      this.IsEditable = (!isNullOrUndefined(this.GridProp.Editable) ? this.IsEditable : this.GridProp.Editable!);
      this.DisplayHeader = (this.GridProp.DisplayHeader === undefined ? this.DisplayHeader : this.GridProp.DisplayHeader);
      this.TableWidth = (this.GridProp.TableWidth === undefined ? this.TableWidth : this.GridProp.TableWidth);
      this.IsSortable = (this.GridProp.IsSortable === undefined ? this.IsSortable : this.GridProp.IsSortable);
      this.IsColResizable = (this.GridProp.IsColResizable === undefined ? this.IsColResizable : this.GridProp.IsColResizable);

      if (!isNullOrUndefined(this.GridProp.PaginationConfig)) {
        this.PaginationConfig.Required = (isNullOrUndefined(this.GridProp.PaginationConfig?.Required) ?
          this.PaginationConfig.Required : this.GridProp.PaginationConfig?.Required);
        this.PaginationConfig.ItemsPerPage = (isNullOrUndefined(this.GridProp.PaginationConfig?.ItemsPerPage) ?
          this.PaginationConfig.ItemsPerPage : this.GridProp.PaginationConfig?.ItemsPerPage);
        this.PaginationConfig.PageList = (isNullOrUndefined(this.GridProp.PaginationConfig?.PageList) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.PageList);
        this.PaginationConfig.ServerLoad = (isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) ?
          this.PaginationConfig.PageList : this.GridProp.PaginationConfig?.ServerLoad);
      }


      this.Pagination.ItemsPerPage = (this.PaginationConfig.Required && this.PaginationConfig.ItemsPerPage) ? this.PaginationConfig.ItemsPerPage : GridConst.DEFAULTITEMSPERPAGE;
      this.Pagination.CurrentPage = 1;

      // this.GridDataTotalCount = this.GridData.length;
      this.GridDataTotalCount = !isNullOrUndefined(this.GridDataTotalCount) && this.GridDataTotalCount > 0 ? this.GridDataTotalCount : this.GridData.length;
      // if (this.GridProp.AddNewRow) {
      //   this.addDefaultRows();
      // }

    }

    if (!this.IsEditable) {
      this.setTemplateReadOnly();
    }

    this.DisplayColumns = this.setDispcols();
    this.GridTotalWidth = this.getGridWidth();

    if (this.GridConfig !== undefined && this.GridConfig.BaseUrl !== undefined && this.GridConfig.BaseUrl !== null) {
      this._elioScopeService.setBaseURL(this.GridConfig.BaseUrl);
    }

    if (this.GlobalConfig !== undefined) {
      let GlobalKeys: Array<string> = [];
      // Object.entries(this.GlobalConfig).forEach(
      //   ([key, value]) => {console.log(key, value); GlobalKeys.push(key); }
      // );
      for (let key in this.GlobalConfig) { GlobalKeys.push(key); }

      this._elioUtilityService.PreProcessTemplate(this.FieldTemplate, GlobalKeys, this.GlobalConfig);
    }


    if (this.IsParallelEditEnabled) {
      this.datacellindx += 1;
    }
    if (this.IsNested) {
      this.datacellindx += 1;
    }

    this.getCurrentDataList();

    if (this.IsEditable) {
      this.editMode = true;
    }

    // to initialise the field level celllock logic for all data
    if (!isNullOrUndefined(this.GridData) && this.GridData.length > 0) {
      this.initCellLock(this.GridData);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes >> ');
    // console.log(changes);



    // Change in data
    // if (!isNullOrUndefined(changes.GridData) && changes.GridData.previousValue !== changes.GridData.currentValue) {
    //   this.GridDataTotalCount = this.filterByPipe.transform(this.GridData, this.DataFilter).length;
    // }

    // to initialise the field level celllock logic for all data
    if (!isNullOrUndefined(changes.GridData) && changes.GridData.previousValue !== changes.GridData.currentValue
      && !isNullOrUndefined(changes.GridData.previousValue) && !isNullOrUndefined(changes.GridData.currentValue)
      && changes.GridData.previousValue.length == 0 && changes.GridData.currentValue.length > 0) {

      if (this.GridData.length > 0) {
        this.initCellLock(this.GridData);
        this.getCurrentDataList();
      }
    }

  }


  ngOnDestroy() {
    // if (this.osInstance && this.osInstance.destroy) {
    //   this.osInstance.destroy();
    // }
  }

  ngAfterViewInit() {

    let eleTarget: HTMLElement = this.eleRef.nativeElement.querySelector('.table-responsive');

    // Remove the focus on any control in the grid
    if (this.IsEditable) {
      setTimeout(() => { this.setGridFocus(); }, 100);
    }




  }
  onClickSelectAll() {
    console.log(this.selectAll)
    this.selectAll = !this.selectAll;

    if (this.selectAll) {
      this.SelectedData = this.GridData.forEach((item: any) => item.IsSelected = true);
    }
    else {
      this.SelectedData = this.GridData.forEach((item: any) => item.IsSelected = false);
    }

    let eventdata = {
      fieldname: 'IsSelected', item: this.GridData[0], oldValue: false,
      item_index: 0
    } as FieldEventData;
    this.FieldChange.emit(eventdata);
  }
  preventEvent(e: any) {
    let ev = e || window.event;

    if (ev.stopPropagation) {
      ev.stopPropagation();
    }

    if (ev.preventDefault) {
      ev.preventDefault();
    }

    return false;
  }

  setTemplateReadOnly() {
    this.FieldTemplate.forEach(item => item.Editable = 0);
  }

  setGridFocus() {

    let focusableEles: HTMLElement[] = this.eleRef.nativeElement.querySelectorAll(`
           input:not([disabled]), select:not([disabled]), textarea:not([disabled]),
           button:not([disabled]), [tabindex="0"]`);
    // this.focusableEles = Array.prototype.slice.call(this.focusableEles);

    if (focusableEles.length > 0) {
      let firstFocusableEle = focusableEles[0];
      // let lastFocusableEle = focusableEles[focusableEles.length - 1];

      firstFocusableEle.focus();
    }

  }


  // Special handling of Grid data for Milkyway as the URL string is passed for loading data
  isValidDataType(): boolean {
    let flag: boolean = false;

    if (!isNullOrUndefined(this.GridData) && typeof (this.GridData) === 'object' && this.GridData.length > 0) {
      flag = true;
    }

    return flag;
  }
  // Enabling Row Merge in Grid columns
  getCurrentDataList(): any {
    if (!isNullOrUndefined(this.GridData) && this.GridData.length > 0) {
      // PaginationConfig.ServerLoad? (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | filterBy: {PageIndex: (Pagination.CurrentPage - 1)})
      //  : (GridData | orderBy:
      //       {property: sortcolumn, direction: sortdirection, ignorecase: 'true', type: sortcolumntype}
      //     | filterBy: DataFilter | slice:((Pagination.CurrentPage-1)*Pagination.ItemsPerPage)
      //                             :(Pagination.CurrentPage*Pagination.ItemsPerPage))

      let orderedData = this.orderByPipe.transform(this.GridData,
        { property: this.sortcolumn, direction: this.sortdirection, ignorecase: 'true', type: this.sortcolumntype });

      let filteredData = this.filterByPipe.transform(orderedData, this.DataFilter);

      let currentData = filteredData;
      console.log()

      if (!isNullOrUndefined(this.Pagination.CurrentPage) && !isNullOrUndefined(this.Pagination.TotalPages)) {
        if (this.PaginationConfig.ServerLoad) {
          currentData = filteredData.filter((x: any) => x.PageIndex === (this.Pagination.CurrentPage - 1));
        }
        else {
          currentData = filteredData.slice((this.Pagination.CurrentPage - 1) * this.Pagination.ItemsPerPage,
            (this.Pagination.CurrentPage * this.Pagination.ItemsPerPage));
        }
      }

      this.currentDataList = currentData;

      // console.log('currentDataList >> ' + JSON.stringify(this.currentDataList));
    }
    return this.currentDataList;
    console.log(this.currentDataList)
  }
  // ReloadGrid(): void {
  //   this.ngOnInit();
  // }

  getColumnCount(): number {
    let colCnt = 0;
    let allthElms: HTMLHeadElement[] = this.eleRef.nativeElement.querySelector('table thead tr').querySelectorAll('th:not(.d-none)');
    if (!isNullOrUndefined(allthElms)) {
      colCnt = allthElms.length;
    }

    return colCnt;
  }

  setDispcols(): string {
    let dispcols: string = '';
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (!this.FieldTemplate[i].IsHidden || this._appSharedService.currentRoleData.CanShowCheckbox == true) {
        dispcols = dispcols + ',' + this.FieldTemplate[i].FieldName;
      }
    }
    return dispcols.length > 0 ? dispcols + ',' : '';
  }

  getGridWidth(): number {
    let nTotalWidth: number = 0;
    for (let i = 0; i < Object.keys(this.FieldTemplate).length; i++) {
      if (
        this.DisplayColumns.indexOf(
          ',' + this.FieldTemplate[i].FieldName + ','
        ) >= 0
      ) {
        nTotalWidth += parseFloat(this.FieldTemplate[i].ColumnWidth);
      }
    }
    return nTotalWidth;
  }

  showGridCol(FieldName: string): boolean {
    if (this.DisplayColumns == '')
      this.DisplayColumns = this.setDispcols();
    return this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0
      ? true
      : false;
  }

  // setGridColWidth(FieldName: string, ColumnWidth: number): string {
  setGridColWidth(FieldName: string, ColumnWidth: any): string {

    let width =
      this.DisplayColumns.indexOf(',' + FieldName + ',') >= 0
        ? ColumnWidth / this.GridTotalWidth * 100
        : 0;
    return width.toString() + '%';

  }

  setTileHeight(): number {
    let otherHt = 56 + 15 + 36 + 7;
    return 290 + 20;
  }

  fldonEditStart(event: any): void {
    if (this.IsParallelEditEnabled) {
      this.FieldEditStart.emit(event);
    }
  }

  fldOnClick(event: any): void {

    this.FieldClick.emit(event);
  }

  fldOnChange(event: any): void {

    let item_indx = this.currentDataList.findIndex((x: any) => x === event.item);

    let eventdata = {
      fieldname: event.fieldname, item: event.item, oldValue: event.oldValue,
      item_index: item_indx
    } as FieldEventData;
    this.FieldChange.emit(eventdata);
  }


  getReadOnly(key: FieldTemplate, item: any): boolean {
    let bFlag = false; /// defaulting to editable
    bFlag = !key.Editable;

    if (!bFlag && !isNullOrUndefined(key.DataCell) && !isNullOrUndefined(key.DataCell?.CustomLock) && this.cellLock !== undefined) {
      if (!isNullOrUndefined(item['CellLock'])) {
        bFlag = item['CellLock'].filter((x: any) => x.FieldName === key.FieldName).map((y: any) => y.Lock)[0];
      }
      else {
        bFlag = this.cellLock(key.FieldName, item, this.parent);
      }
    }

    /// Checking parallel edit access and not locked by user
    if (!bFlag && !isNullOrUndefined(item.RowLockStatus) && item.RowLockStatus === RowLockStatus.Locked) {
      bFlag = true; /// reset as non editable
    }

    if (!this.IsEditable) {
      bFlag = true;
    }
    return bFlag;
  }

  getRequired(key: FieldTemplate, item: any): boolean {
    let bFlag = false;

    // ReadOnly has higher precedence over Required
    if (!this.getReadOnly(key, item)) {
      bFlag = (key.EditValidate.Required && (isNullOrUndefined(item[key.FieldName]) || item[key.FieldName].length === 0));

      if (!bFlag && (!isNullOrUndefined(key.EditValidate.ConditionalRequired)) && key.EditValidate.ConditionalRequired &&
        !isNullOrUndefined(this.cellRequired)) {

        bFlag = this.cellRequired(key.FieldName, item, this.parent);

      }

    }

    return bFlag;
  }

  getCustomClass(key: FieldTemplate, item: any): string {
    let customclass: string = '';
    if (!isNullOrUndefined(this.CustomCss)) {
      customclass = this.CustomCss(key.FieldName, item, this.parent);
    }

    return customclass;
  }

  pageChanged(event: any): void {
    console.log('pagechanged evnt');
    console.log(event.page);

    this.Pagination.CurrentPage = event.page;

    this.prevSelectedCell = '';
    this.selectedCell = '';
    this.selectedCellItemData = null;

    if (this.PaginationConfig.ServerLoad) {
      this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
    }
  }
  goto(): void {
    console.log(this.gotopage);
    if (this.Pagination.TotalPages >= this.gotopage)
      this.Pagination.CurrentPage = this.gotopage;

  }


  totalPagesChanged(event: number): void {
    let prevPages = this.Pagination.TotalPages;
    let curPage = this.Pagination.CurrentPage;

    this.Pagination.TotalPages = event;


  }

  setCurrentPage(flag: number): void {
    // flag = 1 => added row, flag = -1 => deleted rows
    // On adding, set focus to last page that has newly add row.
    if (flag === 1 && this.Pagination.CurrentPage !== this.Pagination.TotalPages) {
      this.Pagination.CurrentPage = this.Pagination.TotalPages;

      this.prevSelectedCell = '';
      this.selectedCell = '';
      this.selectedCellItemData = null;

    }

    // On deleting, set focus to current page if available else set to last page.
    if (flag === -1 && this.Pagination.CurrentPage > this.Pagination.TotalPages) {
      this.Pagination.CurrentPage = this.Pagination.TotalPages;
    }
  }



  // function to check is given cell is non-editable.
  checkCellReadOnly(newtd: HTMLTableCellElement) {
    let bflag: boolean = false;

    // check for readonly class
    if (!isNullOrUndefined(newtd)) {
      let classlist: DOMTokenList = newtd.classList;
      if (classlist.contains('readonly') || classlist.contains('ParallelEdit')) {
        bflag = true;
      }
    }

    return bflag;
  }

  setCellFocus() {
    let newtd = this.currCell as HTMLTableDataCellElement;

    if (!isNullOrUndefined(newtd)) {
      newtd.focus();
    }
  }

  // sort function
  // sort(property) {
  sort(key: FieldTemplate) {

    if (this.IsSortable) {
      // Don't allow to sort grid if cells as merged
      if ((this.FieldTemplate.filter(x => x.RowMerge === true)).length === 0) {

        let property: any = (!isNullOrUndefined(key.LinkedField) && key.LinkedField!.length > 0 ? key.LinkedField : key.FieldName);

        if (this.sortcolumn === property) {
          // Same column
          this.isDesc = !this.isDesc; // change the direction
        }
        else {
          this.sortcolumn = property;
          this.isDesc = false;
        }
        this.sortdirection = this.isDesc ? -1 : 1;

        this.sortcolumntype = (key.Control?.Type === 'textbox' ? key.Control.InputType : key.Control?.Type) || '';
      }
    }

  }
  showFilter(key: FieldTemplate) {
    this.DispFilter = true;
    this.selectedcolfilter = key.FieldName;
    this.FilterColdd = [];
    this.FilterColddOrg = [];

    this.GridDataOrg.forEach((item: any) => {
      

      if (!this.FilterColddOrg.includes(item[key.FieldName])) {
        this.FilterColddOrg.push(item[key.FieldName]);
        this.FilterColdd = this.FilterColddOrg.filter((s:any) => s != null);
        this.FilterColdd.sort();

      }
    })


  }

  onClose(key: FieldTemplate) {
    this.DispFilter = false;
    this.selectedcolfilter = '';
  }
  applyFilter(key: FieldTemplate, index: number) {
  
    let filterValue: any = []
    this.searchValue.forEach((item: any, index1: any) => {
      filterValue.push({ index: index1, value: item })
    })
    this.onFilter.emit({ filter: filterValue, key: key });
    this.DispFilter = false;
    let property: any = (!isNullOrUndefined(key.LinkedField) && key.LinkedField!.length > 0 ? key.LinkedField : key.FieldName);

    if (this.searchValue[index] != '') {
      this.filtercolumn[index] = property;
      this.highlightFilter = true;

    }
    



    // if (this.searchValue[index] != '') {
    //   this.highlightFilter = true;

    // }
    // else {
    //   this.highlightFilter = false;

    // }
  }
  //this.searchValue = '';

  cancelFilter(key: FieldTemplate, index: number) {
    //this.searchValue.splice(index, 1);
    this.searchValue[index] = '';

    if (this.searchValue[index] == '') {
      this.filtercolumn[index] = '';
      this.highlightFilter = false;

    }
    
    //this.filtercolumn[index] = '';
    
    
    
    
      let filterValue: any = []
      this.searchValue.forEach((item:any, index1:any) => {
        filterValue.push({index: index1, value: item})
      })
      this.onFilter.emit({ filter: filterValue, key: key });
      setTimeout (() => {
        this.showFilter(key);
     }, 1000);

    //this.onFilter.emit({ filter: this.searchValue[index], key: key });
    //this.DispFilter = false;
    // this.searchValue = '';

  }


  getRowSpan(key: FieldTemplate, item: any): number {
    let rowcount: number = 1;

    //let temprows = this.currentDataList.filter(x => x[key.FieldName] === item[key.FieldName]);
    let currentData = this.getCurrentDataList();
    let temprows = currentData.filter((x: any) => x[key.FieldName] === item[key.FieldName]);
    rowcount = temprows.length;

    return rowcount;
  }

  showMergeCell(key: FieldTemplate, item: any, item_indx: number): boolean {
    let bShow: boolean = true;

    let currentData = this.getCurrentDataList();
    // if (item_indx > 0 && this.currentDataList[item_indx - 1][key.FieldName] === item[key.FieldName]) {
    if (item_indx > 0 && currentData[item_indx - 1][key.FieldName] === item[key.FieldName]) {
      bShow = false;
    }

    return bShow;
  }



  pageItemsChange(event: any) {
    let pagesPerchange = event.target.value;
    this.pagesPerchange = pagesPerchange;

    if (this.curItemsPerPage > 0 && this.pagesPerchange != null && this.pagesPerchange > 0) {
      //   this.curItemsPerPage = this.curItemsPerPage > 10 ? pagesPerchange : 10;
      this.Pagination.ItemsPerPage = this.curItemsPerPage;
      this.Pagination.CurrentPage = 1;
      // this.prevSelectedCell = '';
      // this.selectedCell = '';
      // this.selectedCellItemData = null;

      if (this.PaginationConfig.ServerLoad) {
        this.PageChange.emit({ PageIndex: (this.Pagination.CurrentPage - 1), PageSize: this.Pagination.ItemsPerPage });
      }
      // this.curItemsPerPage=pagesPerchange;
      console.log(this.Pagination.ItemsPerPage);
      console.log(this.Pagination.CurrentPage)

    }
    else {
      this.Pagination.ItemsPerPage = 100;
    }

  }


  enableFieldsForRow(colmlist: string, itemindex: any, bdisable?: boolean): void {
    let fieldlst = colmlist.split(',');

    this.editMode = this.IsEditable;

    for (let j = 0; j < fieldlst.length; j++) {
      let fieldname = fieldlst[j];

      // id="{{gridRandomId}}_Row_{{item_indx}}_Main"
      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      let currCell: HTMLTableCellElement = table.querySelector('#' + this.gridRandomId + '_Row_' + itemindex + '_Main')!;
      let fldctrl = currCell.querySelector('field-control[data-fieldname="' + fieldname + '"]') as HTMLElement;

      if (!isNullOrUndefined(fldctrl)) {
        let ctrltype = fldctrl.dataset.controltype;

        switch (ctrltype) {
          case 'button':
            let btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;

            if (!isNullOrUndefined(btn)) {
              // btn['disabled'] = bdisable;
              btn.disabled = bdisable!;
            }
            break;

          case 'checkbox':
            let chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;

            if (!isNullOrUndefined(chkbx)) {
              // chkbx['disabled'] = bdisable;
              chkbx.disabled = bdisable!;
            }
            break;

          default:
            let x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;

            if (!isNullOrUndefined(x)) {
              x.click();
            }
            break;

        }

      }

    }

  }

  refreshAllFieldsForRow(item: any, itemindex: any): void {

    // let fieldlst = colmlist.split(',');
    let fieldlst = this.DisplayColumns.substring(1, this.DisplayColumns.length - 1).split(',');

    this.editMode = this.IsEditable;

    for (let j = 0; j < fieldlst.length; j++) {
      let fieldname = fieldlst[j];

      let bdisable: boolean = item['CellLock'].filter((x: any) => x.FieldName === fieldname).map((y: any) => y.Lock)[0];

      // TODO: This code should be checked and removed later
      this.editMode = !bdisable;

      // id="{{gridRandomId}}_Row_{{item_indx}}_Main"
      let table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;
      let currCell: HTMLTableCellElement = table.querySelector('#' + this.gridRandomId + '_Row_' + itemindex + '_Main')!;
      let fldctrl = currCell.querySelector('field-control[data-fieldname="' + fieldname + '"]') as HTMLElement;

      if (!isNullOrUndefined(fldctrl)) {
        let ctrltype = fldctrl.dataset.controltype;

        switch (ctrltype) {
          case 'button':
            let btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;

            if (!isNullOrUndefined(btn)) {
              // btn['disabled'] = bdisable;
              btn.disabled = bdisable!;
            }
            break;

          case 'checkbox':
            let chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;

            if (!isNullOrUndefined(chkbx)) {
              // chkbx['disabled'] = bdisable;
              chkbx.disabled = bdisable!;
            }
            break;

          default:
            let x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;

            if (!isNullOrUndefined(x)) {
              x.click();
            }
            break;

        }

      }

    }

    // TODO: This code should be checked and removed later
    this.editMode = this.IsEditable;

  }

  initCellLock(Data: any[]): void {

    console.log('inside initCellLock in GridDisp comp');

    // for each datarow
    Data.forEach((item: any, item_index: number) => {
      let itemcelllock: Array<any> = [];

      // for each fieldtemplate
      this.FieldTemplate.forEach((key: FieldTemplate, key_index: number) => {
        let editcell = false;

        if (key.Editable && item['IsEditable']) {
          // Check for cell level logic if defined in the template
          if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {
            editcell = true;
          }
          else {
            // if the cell is editable based on the row data and other custom conditions built in this component.
            editcell = !this.cellLock(key.FieldName, item, this.parent);
          }
        }

        let fldlock: any = { FieldName: key.FieldName, Lock: !editcell };
        itemcelllock.push(fldlock);

      });

      // finally add the lock object to datarow
      item['CellLock'] = itemcelllock;
    });

  }
  initCellLock_1(Data: any[]): void {

    console.log('inside initCellLock in GridDisp comp');




    this.FieldTemplate.forEach((key: FieldTemplate, key_index: number) => {

      key.Editable && Data.forEach((item: any) => {

        if (isNullOrUndefined(key.DataCell) || isNullOrUndefined(key.DataCell?.CustomLock)) {

          item.CellLock.push({ FieldName: key.FieldName, Lock: false });

        } else {

          const editCell = !this.cellLock(key.FieldName, item, this.parent);

          item.CellLock.push({ FieldName: key.FieldName, Lock: !editCell });

        }

      });

    });

  }


  refreshAllFieldsForRow_1(item: any, itemindex: any): void {

    const fieldlst = this.DisplayColumns.substring(1, this.DisplayColumns.length - 1).split(',');




    this.editMode = this.IsEditable;




    const table: HTMLElement = this.eleRef.nativeElement.querySelector('tbody') as HTMLElement;




    fieldlst.forEach((fieldname) => {

      const bdisable: boolean = item.CellLock.find((x: any) => x.FieldName === fieldname)?.Lock || false;




      this.editMode = !bdisable;




      const currCell: HTMLTableCellElement = table.querySelector(`#${this.gridRandomId}_Row_${itemindex}_Main`)!;

      const fldctrl = currCell.querySelector(`field-control[data-fieldname="${fieldname}"]`) as HTMLElement;




      if (fldctrl) {

        const ctrltype = fldctrl.dataset.controltype;




        switch (ctrltype) {

          case 'button':

            const btn: HTMLButtonElement = fldctrl.querySelector('button') as HTMLButtonElement;




            if (btn) {

              btn.disabled = bdisable;

            }

            break;




          case 'checkbox':

            const chkbx: HTMLInputElement = fldctrl.querySelector('input[type=checkbox]') as HTMLInputElement;




            if (chkbx) {

              chkbx.disabled = bdisable;

            }

            break;




          default:

            const x: HTMLElement = fldctrl.querySelector(bdisable ? 'div.reseteditdiv' : 'div.seteditdiv') as HTMLElement;




            if (x) {

              x.click();

            }

            break;

        }

      }

    });




    this.editMode = this.IsEditable;

  }







  addDefaultRows() {
    if (this.GridProp.AddNewRow === true) {
      let datalength: number = this.GridData.length;
      if (datalength < 5) {
        for (let i = 0; i < (5 - datalength); i++) {
          this.addNewRow(false);
          // this.addNewRow(false);

          // reset new index value since these rows are by system
          this._NewRowInd = -1;
        }
      }
    }
  }

  addNewRow(bEmptyCheck?: boolean): void {
    // addNewRow(bFirst?: boolean): void {
    // bFirst = (isNullOrUndefined(bFirst)? true: bFirst);

    bEmptyCheck = (isNullOrUndefined(bEmptyCheck) ? true : bEmptyCheck);
    // Check to see if empty rows available. If so, then show message and do not add row.
    this.UniqueKeyName = (this.GridProp.UniqueKeyName === undefined ? this.UniqueKeyName : this.GridProp.UniqueKeyName);
    let emptyrows = this.GridData.filter((x: any) => x['IsModified'] === 0 && x[this.UniqueKeyName] <= 0);

    if (emptyrows.length > 0 && bEmptyCheck) {
      // this._toastr.info('Please fill in the available empty row.', this.ComponentTitle);
      this.showNotificationMessage('Please fill in the available empty row.', 2, '', null, '', '', 1000);

      this.afterAddNewRow(null as any);
      return;
    }

    if (!isNullOrUndefined(this.GridProp) && !isNullOrUndefined(this.GridProp.DefaultRow)) {
      // this.ObjectType = this.GridProp.DataObject;
      // let newitem: any = new this.ObjectType();
      let newitem: any = JSON.parse(JSON.stringify(this.GridProp.DataObject));


      this.GridProp.DefaultRow?.forEach((item: any) => {
        // newitem[item.Field] = item.Value;
        (newitem as any)[item.Field] = item.Value;
      });

      // newitem['PageIndex'] = this._DataInput['PageIndex'];
      // newitem['PageIndex'] = this.getNewItemPageIndex();
      (newitem as any)['PageIndex'] = this.getNewItemPageIndex();
      // newitem = Object.assign(newitem, { 'PageIndex' : this.getNewItemPageIndex() });

      this.GridData.push(newitem);

      if (!(!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
        this.GridProp.PaginationConfig?.ServerLoad)) {
        this.DataTotalCount++;
      }


      if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
        this.GridProp.PaginationConfig?.ServerLoad) {

        this._NewRowInd = (this.GridData.length - 1);
      }

      this.afterAddNewRow(this.GridData[this.GridData.length - 1]);
    }
  }

  afterAddNewRow(RowItem: any) {
    // Do nothing
  }

  getNewItemPageIndex(): number {
    let newitempageind = 0;

    if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
      !isNullOrUndefined(this.GridProp.PaginationConfig?.ServerLoad) &&
      this.GridProp.PaginationConfig?.ServerLoad) {

      let totitems = this.DataTotalCount;
      let itemsperpage: any = 10;

      if (!isNullOrUndefined(this.GridProp.PaginationConfig) &&
        !isNullOrUndefined(this.GridProp.PaginationConfig.ItemsPerPage)) {
        itemsperpage = this.GridProp.PaginationConfig.ItemsPerPage;
      }

      let totpages = (totitems - (totitems % itemsperpage)) / itemsperpage;
      totpages = (totitems % itemsperpage) > 0 ? totpages + 1 : totpages;

      let lastpageitemscnt = totitems - ((totpages - 1) * itemsperpage);

      newitempageind = ((lastpageitemscnt + 1) > itemsperpage ? totpages + 1 : totpages);
      newitempageind--;

    }

    return newitempageind;
  }

  showNotificationMessage(msg: any, successFlag: number, action: string, item: any, yes: string, no: string, timeout: number) {
    let headerIcon: string = '';

    if (successFlag === 1) {
      headerIcon = 'fa fa-check-circle text-success';
    } else if (successFlag === 2) {
      headerIcon = 'fa fa-exclamation-circle text-warning';
    } else if (successFlag === 3) {
      headerIcon = 'fa fa-exclamation-triangle text-danger';
    } else {
      headerIcon = 'fa fa-info-circle text-info';
    }


    this.displayNotifybox = true;
  }



}



