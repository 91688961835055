export const environment = {
    appURLUI: 'https://sms.capgemini.com/',
    baseURL: 'https://sms.capgemini.com',
    apiurl: 'https://sms.capgemini.com',
    UIConfigURL: 'https://sms.capgemini.com/UIConfig/',
    userDefault: "../assets/images/dummy-img-log.png",

    ssoLoginUrl: "https://signin.capgemini.com",
    ssoRedirectUri: "/#/",
    ssoClientId: "smsprod",
    ssoSecretId:"jkQ9eWbQl9dUykOfFOtowonzPYnkMxujDeI47cIxgRm8MK7qK9a1f4eigESR3Bwq",
    ssotokenEndpoint: "https://signin.capgemini.com/as/token.oauth2",
    ssouserinfoEndpoint: "https://signin.capgemini.com/idp/userinfo.openid",
    
    IsParallelEditEnabled: false,
    tokenexpiry: 30,    // in mins
    sessionexpiry: 15,  // in mins
    sessionexpirywarning: 5,   // in mins

};