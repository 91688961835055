// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { isNullOrUndefined } from 'util';
import { isNullOrUndefined } from '../shared/app.sharedservice';
import { Observable } from 'rxjs';

import { AppSharedService } from '../shared/app.sharedservice';
import { environment } from 'src/environment/environment';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    private token: any;
    constructor(public oauthService: OAuthService, public _appSharedService: AppSharedService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let sameDomain = environment.baseURL;

       
        // Check for same domain
        if (request.url.indexOf(sameDomain) >= 0) {

            // avoid adding token for the GetToken API or Language resource file
            if (request.url.indexOf('GetToken') < 0) {
                
                request = request.clone({
                    setHeaders: {
                        "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
                        "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
                        "X-Frame-Options": "DENY",
                        "X-Content-Type-Options": "nosniff",
                        "X-Xss-Protection": "1; mode=block",
                        "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
                        "Cache-Control": 'no-cache, no-store, must-revalidate',
                        "Pragma": 'no-cache',
                        "Expires": '0',
                        CurrentRole: (isNullOrUndefined(this._appSharedService.currentRoleData) ? '0' : this._appSharedService.currentRoleData.RoleId.toString()),
                        ActingUserId: (isNullOrUndefined(this._appSharedService.UserAccessInfo) ? '0' : this._appSharedService.UserAccessInfo.ActingUserId),
                    }
                });
            }
        }

        return next.handle(request);
    }
}