import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppSharedService } from 'src/app/shared/app.sharedservice';
import { AuthenticationService } from '../auth/authentication.service';


@Component({
  selector: 'app-switchuser',
  templateUrl: './switchuser.component.html',
})
export class SwitchUserComponent implements OnInit {

  @Output() close = new EventEmitter<any>();

  public emailid: string = '';
  public passwd: string = '';
  public IsLoading: boolean = false;

  constructor(public _appSharedService: AppSharedService,
    private _authService: AuthenticationService,
    private _router: Router) { }

  ngOnInit() {
    document.getElementById('txtUName')?.focus();
  }

  closeSwitchUser(reLoad?: boolean): void {
    this.close.emit({ 'reLoad': reLoad });
  }

  async switchUser(): Promise<void> {

    this.IsLoading = true;

    this.emailid = (document.getElementById('txtUName') as HTMLInputElement).value;

    this._appSharedService.AppIsLoading$.next(true);

    await this._authService.getAPIToken(this._appSharedService.UserInfo.email, this.emailid).then(result => {
      if (result) {
        this.IsLoading = false;
        this._router.navigate(['/home']);
        // window.location.href = window.location.href;

        this.closeSwitchUser(true);

        // alert('Successfully switched user, Please refresh to proceed');
      }
      else {
        this.IsLoading = false;
        alert('Sorry, unable to complete the switched user authentication');
        // show as invalid mailid
      }


      this._appSharedService.AppIsLoading$.next(false);

    });

  }

  keyDown(event: any): void {
    if (event.keyCode === 13) {
      this.switchUser();
    }
  }

}
