import { isInRectangle } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { Component, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { isUndefined } from 'ngx-bootstrap/chronos/utils/type-checks';
import { Subscription, of } from 'rxjs';
import { ActivatedRoute, Router } from '../../node_modules/@angular/router';
import { environment } from '../environment/environment';
import { ComponentCodes, ComponentConfig, ProcessStatus, ServiceDomain, navParams, Settings } from './app.interface';
import { AuthenticationService } from './auth/authentication.service';
import { AppSharedService, AutoUnsubscribe, isNullOrUndefined, isStringNullOrEmpty } from './shared/app.sharedservice';
import { CommonAPIService } from './shared/app.commonservice';
import { SearchByComponent } from 'src/modules/searchby/searchby.component';
import { MenuItem } from './app.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingModule } from 'src/modules/loading/loading.module';
import { ModalInterface } from 'src/modules/modal/modal.interface';
import { ModalService } from 'src/modules/modal/modal.service';
import { filter, debounceTime, tap, switchMap, finalize, map, startWith, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HomeService } from 'src/app/components/home/home.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';



@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss'],


})
@AutoUnsubscribe
export class AppMainComponent {
  title = 'BLL-UI';

  idleState = 'NOT_STARTED';
  timedOut = false;



  public userinfo: any = {};
  public userAccessInfo: any = {};
  public refURL: string = '';
  public showNoMenuMessage: boolean = false;

  public displaySwitchUser: boolean = false;
  public SwitchUserAccess: boolean = false;
  public IsuserActions: boolean = false;
  public IsDelegateAdd: boolean = false;
  public IsDelegateRevoke: boolean = false;
  public IsDelegateHistory: boolean = false;
  public empDLSearch: any;
  public selectedName: any;
  public disableAddTeamSubmit: boolean = true;
  public displayDelegationUser: boolean = false;
  public AddTeamListData: any;
  public AddTeamListDataOrg: any;
  public searchEmployeeDLDetails: FormControl = new FormControl();
  public dlEmpList: any;
  public dlEmpListName: any[] = [];
  public dlEmpListemail: any[] = [];
  public empDLLoading: boolean = false;
  public minLengthTerm = 3;
  public enableEmpDLValidate: boolean = true;
  public opportunityMasterIdId: any;
  public delegate_reason: string = '';
  //public menuList: any = [];
  public menuListPermission: any = [];
  public menuListProperty: any = [];
  public selectedTab: string = '';
  public displaySessionExp: boolean = false;
  public session: any = {};
  public AppIsLoading: boolean = false;
  public image: any;
  public userDefault = environment.userDefault;
  public icontext: any;

  public IsLoading: boolean = false;
  //public IsLoadingTest: boolean = false;
  public subscriptionList$: Subscription[] = [];
  public dftTab: string = '';
  public initialicon: string = '';
  public showNoDataMessage: boolean = false;
  public showNoRequestMessage: boolean = false;
  public usericonimage: boolean = false;
  //public mainMenu!: MenuItem[];
  public menuList!: MenuItem[];
  public modalInf: ModalInterface = new ModalInterface();
  public modalNotification: ModalInterface = new ModalInterface();

  dateTypeOptions: any[] = ['Add Delegation', 'Revoke', 'History'];
  public selectedAction: string = 'Select';
  public btn_primary_Name: string = 'Submit';
  public btn_light_Name: string = 'Cancel';
  public selectedUser: any;

  public shouldMakeApiCall: boolean = true;
  public delegationRecords: any[] = [];
  public delegationMessage: string = '';
  public noticationFlag: string = '';
  public Notify: boolean = false;
  public EmailNotification: boolean = false;
  public NotificationData: any;
  public NotificationDataDate: any;
  public BellNotificationData: any;
  public BellNotificationReadData: any;
  public BellCounts: any;
  public BellNotificationsId: any;
  public ToggleBtn: boolean = false;
  public CheckedValue: any;
  public NotificationSettings: any;
  public NotificationSettingsData: any;
  public NotificationReadData: any;
  public toggleBtnCheck: boolean = false

  SettingsData?: Settings[];
  setting: any;
  public CheckedValues: any;
  public MayilTypesFromResponse: any;
  public isChecked: boolean = false;

  getMailType() {
    this.SettingsData = [
      {
        "Id": "ACT",
        "Name": "Action",
        "isChecked": false

      },
      {
        "Id": "REM",
        "Name": "Reminder",
        "isChecked": false
      },
      {
        "Id": "SYS",
        "Name": "System",
        "isChecked": false
      }
    ]
  }

  getMailTypes() {
    this.SettingsData = [
      {
        "Id": "ACT",
        "Name": "Action",
        "isChecked": true

      },
      {
        "Id": "REM",
        "Name": "Reminder",
        "isChecked": true
      },
      {
        "Id": "SYS",
        "Name": "System",
        "isChecked": true
      }
    ]
  }

  constructor(private route: ActivatedRoute, public _appSharedService: AppSharedService, public _authService: AuthenticationService,
    private element: ElementRef, private idle: Idle,
    public _router: Router, private ngZone: NgZone,
    private oauthService: OAuthService, private _commonAPIService: CommonAPIService,
    private sanitizer: DomSanitizer, private renderer: Renderer2,
    private modalService: ModalService, private homeService: HomeService,
    public translate: TranslateService) {

    // this.route.params.subscribe(params => {
    //   this.opportunityMasterIdId = params['id']
    //   alert("id "+this.opportunityMasterIdId);
    // });

    this.translate = Object.assign(this.translate, { 'AppDateTimeFormat': this._appSharedService.AppDateTimeFormat });
    this.translate = Object.assign(this.translate, { 'AppDateFormat': this._appSharedService.AppDateFormat });


    // Silent Oauth Token Generation
    setInterval(() => {
      let accessTknExp = this.oauthService.getAccessTokenExpiration();
      if (accessTknExp != null && new Date(accessTknExp) < new Date(Date.now() + (15 * 60 * 1000))) { // 15 mins minus from current time new Date(Date.now() - (15 * 60 * 1000))
        this.oauthService.silentRefresh();
      }
    }, 6000000);

    this._appSharedService.UserInfo$.subscribe(() => {
      this.ngOnInit();
    })
  }

  ngOnInit() {

    let startSSOhotellingUrl = localStorage.getItem(navParams._localStorage_PreviousURL);

    if (startSSOhotellingUrl !== null && startSSOhotellingUrl != this._appSharedService.PreviousURL) {
      if (startSSOhotellingUrl.includes('home')) {
        this.selectedTab = navParams._appSelectedTab_Opportunities
      } else if (startSSOhotellingUrl.includes('reports')) {
        this.selectedTab = navParams._appSelectedTab_Reports
      } else if (startSSOhotellingUrl.includes('myreview')) {
        this.selectedTab = navParams._appSelectedTab_Reviews
      }
    }

    //this.IsLoading = true;

    this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.userDefault})`);

    if (this._appSharedService.IsAuthUser && !isNullOrUndefined(this._appSharedService.UserInfo)) {
      this.showApp();
    }

    this.searchEmployeeDLDetails.valueChanges
      .pipe(
        filter(res => {
          return res !== undefined && res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          if (this.shouldMakeApiCall) {
            // this.errorMsg = "";
            this.dlEmpList = [];
            this.empDLLoading = true;
          }
        }),
        // switchMap(value => this._commonAPIService.getUserDetailsFromUserName(this.empDLSearch)
        //   .pipe(
        //     finalize(() => {
        //       this.empDLLoading = false
        //     }),
        //   )
        // )
        switchMap(value => {
          // Check if API calls should be made
          if (this.shouldMakeApiCall) {
            return this._commonAPIService.getUserDetailsFromUserName(value).pipe(
              finalize(() => {
                this.empDLLoading = false;
              })
            );
          } else {
            // If API calls are not needed, return the existing list
            return of(this.dlEmpList);
          }
        })
      )
      .subscribe((data: any) => {
        if (!isNullOrUndefined(data)) {
          this.dlEmpListName = data.Data;
          //this.dlEmpList = this.dlEmpListName.filter(x => !this.DXTeamListData.map((x: any) => x.UserEmailID).includes(x['Value']))
          this.dlEmpList = this.dlEmpListName;

          if (this.dlEmpList.length === 0) {
            // Display a message when no records are found
            this.dlEmpList = [{ FullName: 'No records found (Please provide a valid username to search)', Mail: null }];
          }
        } else {
        }
      });
    this.getMailType();
    this.selectedTab = 'Projects';
  }

  // BellCountRefresh() {
  //   setInterval(() => {
  //     this.GetBellNotifications();
  //   }, 2000);
  // }

  // ngAfterViewInit() {
  //   this.BellCountRefresh();
  //   if (!isNullOrUndefined(this._appSharedService.tabHomeName))
  //     this.menuTabClick(this._appSharedService.tabHomeName);

  // }
  // ngAfterViewInit() {
  //   this.showApp();
  // }
  menuTabClick(menu: any) {
    switch (menu.toUpperCase()) {
      case 'OPPORTUNITIES':
        this._router.navigate(['/home/'])
        this.selectedTab = 'Opportunities'
        break;
      case 'ANALYTICS':
        this.selectedTab = 'Analytics'
        this._router.navigate(['/reports/'])
        break;

      case 'ACTIVITYLOG':
        this._router.navigate(['/logs/'])
        this.selectedTab = 'ActivityLog'
        break;

      case 'REPORTS':
        this._router.navigate(['/people/'])
        this.selectedTab = 'Reports'
        break;

      case 'HIERARCHY':
        this._router.navigate(['/hierarchy/'])
        this.selectedTab = 'Hierarchy'
        break;
    }
  }
  showApp() {
    let subscribe$_1: Subscription = this._appSharedService.UserInfo$.subscribe((data: any) => {
      this.userinfo = data;
    });
    this.subscriptionList$.push(subscribe$_1);

    let subscribe$_2: Subscription = this._appSharedService.AppIsLoading$.subscribe((data: any) => {
      //this.AppIsLoading = data;
    });
    this.subscriptionList$.push(subscribe$_2);

    let subscribe$_3: Subscription = this._appSharedService.ApiToken$.subscribe((data: any) => {
      this.userAccessInfo = this._appSharedService.UserAccessInfo;

      if (this.userAccessInfo != null) {
        this.usericonimage = false;
        this.initialicon = this.userAccessInfo.UserInitials;

        if (this.userAccessInfo.ProfileImage != null) {
          this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.userAccessInfo.ProfileImage})`);
          //this.usericonimage = true;
        } else {
          this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.userDefault})`); //this.userDefault
          //this.usericonimage = false;
        }

        this.getAppTemplate();
      }
    });
    this.subscriptionList$.push(subscribe$_3);

    this.setSessionExpiry();

    if (this._appSharedService.UserAccessInfo != null) {
      this.usericonimage = false;
      this.userAccessInfo = this._appSharedService.UserAccessInfo;

      this.initialicon = this.userAccessInfo.UserInitials;

      if (this.userAccessInfo.ProfileImage != null) {
        this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.userAccessInfo.ProfileImage})`);
        // this.usericonimage = true;
      } else {
        this.image = this.sanitizer.bypassSecurityTrustStyle(`url(${this.userDefault})`); //this.userDefault
        // this.usericonimage = false;
      }

      this.getAppTemplate();
    }
  }

  getAppTemplate(): void {

    //this.AppIsLoading = true;
    this.showNoMenuMessage = false;
    let odata: ComponentConfig = {
      Component: 'AppComponent', ComponentCode: ComponentCodes.AppComponent, ReferenceValueType: "1"
    };
    let subscribe$: Subscription = this._commonAPIService.getComponentConfig(odata).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        //this.menuList = res.Data.FieldTemplate;
        //console.log('Tab List Template >> ' + JSON.stringify(this.menuList));

        this.menuListPermission = res.Data.Permission;
        this.menuListProperty = res.Data.Properties;
        this.menuList = (this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'AppComponent'.toLowerCase()).length > 0 ?
          JSON.parse(this.menuListProperty.filter((x: { Name: string; }) => x.Name.toLowerCase() === 'AppComponent'.toLowerCase())[0].JSONData) : []);

        this.menuList.forEach((item: any) => {
          // let menuitem = this.menuList.filter((x: any) => x[this.menuListPermission['MenuList_' + item.Name + '_Permission']]);
          item.HasPermission = false;

          let peritem = this.menuListPermission.indexOf('MenuList_' + item.Name + '_Permission');
          if (peritem >= 0) {
            item.HasPermission = true;
          }
          if (item.SubMenu.length > 0) {
            // this.menuList.forEach((submenuitem: any) => {
            item.SubMenu.forEach((submenuitem: any) => {
              submenuitem.HasPermission = false;
              let subperitem = this.menuListPermission.indexOf('MenuList_' + submenuitem.Name + '_Permission');
              if (subperitem >= 0) {
                submenuitem.HasPermission = true;
              }
            });
          }
        });
        //this._router.navigate(["/home"]);

        if (this.menuList.filter((x: any) => x.HasPermission === true).length == 0) {
          this.showNoMenuMessage = true;
        }
        // if(this._appSharedService.currentRoleData.UserRoleId == 1 || this._appSharedService.currentRoleData.UserRoleId == 2)
        // {
        //   this.menuList = this.menuList;
        // }
        if(this._appSharedService.currentRoleData.UserRoleId == 3)
        {
          // this.menuList = this.menuList.filter((x: any) => x.Name.toUpperCase() == 'HIERARCHY');
          this.menuTabClick('HIERARCHY');
        }
        else if(this._appSharedService.currentRoleData.UserRoleId == 4 || this._appSharedService.currentRoleData.UserRoleId == 5)
        {
          // this.menuList = this.menuList.filter((x: any) => (x.Name.toUpperCase() == 'HIERARCHY' || x.Name.toUpperCase() == 'REPORTS'));
          this.menuTabClick('REPORTS');
        }
        // else
        // {
        //   this.menuList = [];
        // }
        if(this._appSharedService.currentRoleData.IsPCO == 0 && this._appSharedService.currentRoleData.IsDelegate == 0)
        {
          this.menuList = this.menuList.filter((x: any) => x.Name.toUpperCase() != 'PROJECTS');
          this.menuList = this.menuList.filter((x: any) => x.Name.toUpperCase() != 'ACTIVITYLOG');
        }
        if(this._appSharedService.currentRoleData.IsSupervisor == 0 && this._appSharedService.currentRoleData.IsPCO == 0 && this._appSharedService.currentRoleData.IsDelegate == 0 && this._appSharedService.currentRoleData.IsPeopleManager == 0 && this._appSharedService.currentRoleData.IsAdmin == 0)
        {
          this.menuList = this.menuList.filter((x: any) => x.Name.toUpperCase() != 'HIERARCHY');
        }
        if(this._appSharedService.currentRoleData.IsPeopleManager == 0 && this._appSharedService.currentRoleData.IsAdmin == 0)
        {
          this.menuList = this.menuList.filter((x: any) => x.Name.toUpperCase() != 'REPORTS');
        }
        if(this._appSharedService.currentRoleData.UserRoleId == 0)
        {
          this.menuList = [];
        }
        console.log(this._router.url)
        this.IsLoading = false;
      }

    },
      err => {
        // this.AppIsLoading = false;
        // console.log('Error on getHomeTemplate.');
      });
    this.subscriptionList$.push(subscribe$);
    //this.AppIsLoading = false;
    // this.GetBellNotifications();
  }


  removeString(value: string) {
    var numsStr = value.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
  }

  openSwitchUser(): void {
    this.IsuserActions = !this.IsuserActions;
    this.displaySwitchUser = true;
    this.selectedTab = 'Projects';
  }

  closeSwitchUser(reLoad?: any): void {
    if (!isNullOrUndefined(reLoad) && reLoad.reLoad === true) {
      this.getAppTemplate();
    }

    this.displaySwitchUser = false;
  }

  GotoHomePage(): void {
    this._router.navigate(['/home']);
  }

  openUserProfile(): void {
    this.IsuserActions = !this.IsuserActions;
  }
  _delegationModelPopup() {
    this.IsuserActions = !this.IsuserActions;
    this.btn_primary_Name = 'Close';
    this.resetFields();
    this.IsDelegateAdd = false;
    this.IsDelegateRevoke = false;

    this.openModal('DelegationPopup');
    this.modalInf = {
      header: 'Delegate settings',
      size: 'modal-md modal-dialog-centered',
      width: 'auto',
      height: 'auto'
    };
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  CloseDelegationModelPopup() {
    this.resetFields();
    this.closeModal('DelegationPopup');
  }
  onChange(event: any) {
    this.resetFields();
    this.selectedAction = event;
    if (this.selectedAction.toUpperCase() === "ADD DELEGATION") {
      this.btn_primary_Name = 'Submit';
      this.IsDelegateAdd = true;
    }
    else if (this.selectedAction.toUpperCase() === "REVOKE") {
      this.GetDelegationDetails();
      this.IsDelegateRevoke = true;

    } else if (this.selectedAction.toUpperCase() === "HISTORY") {
      this.IsDelegateHistory = true;
      this.GetDelegationDetails();

    }
  }
  gotoadduser() {
    if (this.empDLSearch != this.selectedName) {
      // this.disableAddTeamSubmit = true;
      this.selectedUser = null;
      this.shouldMakeApiCall = true;
    }
    this.enableSubmitButton();
  }
  onEmpDLSelected(selectedUser: any) {
    let obj = !isNullOrUndefined(selectedUser) && !isNullOrUndefined(selectedUser.option) && !isNullOrUndefined(selectedUser.option.value) ? selectedUser.option.value : null;
    this.selectedUser = obj;
    // this.empDLSearch = this.selectedUser.FullName + ' (' + this.selectedUser.Mail + ')';
    this.selectedName = this.selectedUser.FullName + ' (' + this.selectedUser.Mail + ')';
    this.shouldMakeApiCall = false;
    this.searchEmployeeDLDetails.setValue(this.selectedUser.FullName + ' (' + this.selectedUser.Mail + ')', { emitEvent: false });
    this.enableSubmitButton();
  }
  displayWithEmpDL(value: any) {

    if (!isNullOrUndefined(value) && value !== '' && !isNullOrUndefined(value.mail) && value.mail !== '') {
      value = value.mail;
      this.enableEmpDLValidate = true;
    } else if (!isNullOrUndefined(value) && value !== '') {
      value = value;
      this.enableEmpDLValidate = true;
    } else {
      value = undefined;
      this.enableEmpDLValidate = false;
    }
    return value;
  }


  OnSubmitAddDelegate(value: any) {
    let Flag = 'Add'
    let data = {
      UserName: this.selectedUser.Username,
      EmailId: this.selectedUser.Mail,
      Reason: value,
      DelegateByUserId: this._appSharedService.UserInfo.GGID,
      IsActive: true
    }
    console.log("data delegate ", data)
    alert("data " + data)
    this.homeService._insertUpdateDelegateAccess(data).subscribe(res => {

      // this.GetDelegateHistory();
    })

    this.empDLSearch = '';
    this.selectedName = '';
    this.delegate_reason = '';
  }
  onKey(event: any) {
    this.enableSubmitButton();
  }

  resetFields(): void {
    this.selectedAction = 'Select';
    if (this.selectedAction == 'Select') {
      this.IsDelegateAdd = false;
    } else {
      this.IsDelegateAdd = true;
    }
    this.IsDelegateRevoke = false;
    this.IsDelegateHistory = false;
    this.empDLSearch = '';
    this.delegate_reason = '';
    this.selectedUser = null;
    this.shouldMakeApiCall = true;
    this.disableAddTeamSubmit = false;
    this.noticationFlag = '';

  }

  enableSubmitButton() {
    this.disableAddTeamSubmit = false;
    if (!isNullOrUndefined(this.selectedUser) && !isStringNullOrEmpty(this.delegate_reason)) {
      this.disableAddTeamSubmit = true;
    }
  }

  onRevokeChange(record: any): void {
    if (record.IsDelegationRevoked) {
      this.disableAddTeamSubmit = true;
      this.selectedUser = record;
    } else {
      this.disableAddTeamSubmit = false;
      this.selectedUser = null;
    }
  }

  _onSubmitOrRevokeDelegation() {

    // let _IsDelegateRevoke: boolean = false;

    // if (this.IsDelegateRevoke) {
    //   if (!isNullOrUndefined(this.selectedUser) && this.selectedUser.IsDelegationRevoked) {
    //     _IsDelegateRevoke = true;
    //   } else {
    //     this._openDelegationNotificationPopup('Alert', 'Please select revoke option.', 'VALIDATION');
    //     return;
    //   }
    // }

    if (this.IsDelegateAdd && !this.IsDelegateRevoke) {
      if (!isNullOrUndefined(this.selectedUser) && (this.selectedUser.CapGeminiGlobalID.toString() === this._appSharedService.UserInfo.GGID.toString())) {
        this._openDelegationNotificationPopup('Alert', 'You cannot delegate yourself. Please select another employee.', 'VALIDATION');
        return;
      }
    }


    let data: any = {};
    if (this.IsDelegateAdd || this.IsDelegateRevoke) {
      this.IsLoading = true;
      data = {
        TransactionId: this.IsDelegateRevoke ? this.selectedUser.TransactionId : 0,
        DelegatedUser_CGID: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_CGID : this.selectedUser.CapGeminiGlobalID,
        DelegatedUser_FirstName: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_FirstName : this.selectedUser.Name,
        DelegatedUser_LastName: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_LastName : this.selectedUser.Surname,
        DelegatedUser_EmailId: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_EmailId : this.selectedUser.Mail,
        DelegatedUser_CountryId: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_CountryId : this.selectedUser.Country,
        DelegatedUser_Designation: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_Designation : this.selectedUser.Title,
        DelegatedUser_CorpId: this.IsDelegateRevoke ? this.selectedUser.DelegatedUser_CorpId : this.selectedUser.Username,
        DelegatedReason: this.IsDelegateRevoke ? this.selectedUser.DelegatedReason : this.delegate_reason,
        Action: this.selectedAction,
        ActionBy_CGID: this._appSharedService.UserInfo.GGID
      }

      let subscribe$: Subscription = this.homeService._insertUpdateDelegateAccess(data).subscribe(res => {
        if (res !== undefined && res !== null && res.Status === 200) {
          this._openDelegationNotificationPopup('Alert', res.Data.DelegationMessage, res.Data.DelegationMessageStatus);
        }
        this.IsLoading = false;
      },
        err => {
          this.IsLoading = false;
          console.log('Error on _onSubmitOrRevokeDelegation.');
        });

      this.subscriptionList$.push(subscribe$);

    } else {
      this.CloseDelegationModelPopup();
    }
    // this.CloseDelegationModelPopup();
    // this._openDelegationNotificationPopup('Access Delegated Successfully.');
  }



  _openDelegationNotificationPopup(header: any, message: any, noticationFlag: any) {
    this.delegationMessage = message;
    this.noticationFlag = noticationFlag;
    this.openModal('delegationNotifications');
    this.modalNotification = {
      header: header,
      size: 'modal-md modal-dialog-centered',
      width: 'auto',
      height: 'auto'
    };
  }

  _closeDelegationNotificationPopup() {

    if (this.noticationFlag.toUpperCase() === 'SUCCESS') {
      this.closeModal('delegationNotifications');
      this.CloseDelegationModelPopup();
    } else {
      this.closeModal('delegationNotifications');
    }
  }

  GetDelegationDetails() {
    this.IsLoading = true;
    let subscribe$: Subscription = this.homeService._getDelegationDetails(this.selectedAction, this._appSharedService.UserInfo.GGID).subscribe(res => {
      if (res !== undefined && res !== null && res.Status === 200) {

        this.delegationRecords = !isNullOrUndefined(res.Data) && res.Data.length > 0 ? res.Data : [];

        if (this.selectedAction.toUpperCase() === "REVOKE") {
          this.btn_primary_Name = 'Revoke';
          this.IsDelegateRevoke = true;
          this.disableAddTeamSubmit = !isNullOrUndefined(this.delegationRecords) && this.delegationRecords.length > 0 ? true : false;
          this.selectedUser = !isNullOrUndefined(this.delegationRecords) && this.delegationRecords.length > 0 ? this.delegationRecords[0] : null;
        } else {
          this.btn_primary_Name = 'Ok';
          this.IsDelegateHistory = true;
          this.disableAddTeamSubmit = true;
        }

        this.IsLoading = false;
      }

    },
      err => {
        this.IsLoading = false;
        console.log('Error on _onSubmitOrRevokeDelegation.');
      });

    this.subscriptionList$.push(subscribe$);
  }

  clickedOutside(): void {
    this.IsuserActions = false;
  }

  onClick(event: any): void {

    let OPMID = event;
    if (OPMID == 0) {
      this.openModal('Alert');
      this.modalNotification = {
        header: '',
        size: 'modal-md modal-dialog-centered',
        width: 'auto',
        height: 'auto'
      };
    }
    this._appSharedService.tabOpportunityDetailsName = 'OpportunityDetails';
    this._router.navigate(['/opportunitysummary/' + OPMID]);
  }

  // BellIconClick() {
  //   this.Notify = true;
  //   this.GetBellNotifications();
  // }

  ClickNotify() {
    this.toggleBtnCheck = false;
    this.ToggleBtn = !this.ToggleBtn;
    this.EmailNotification = false;
    this.getMailType();
  }

  CloseNotify() {
    this.Notify = false;
    this.EmailNotification = false;
  }

  // OnClickSettings() {
  //   this.homeService.GetNotificationSettings().subscribe(res => {
  //     // if (res !== undefined && res !== null && res.Status === 200) {
  //     this.NotificationSettings = res.Data;
  //     if (this.NotificationSettings == null || res.Message == "No data found") {
  //       this.EmailNotification = true;
  //       this.toggleBtnCheck = true;
  //       this.ToggleBtn = !this.ToggleBtn;
  //       this.getMailTypes();
  //     }
  //     this.MayilTypesFromResponse = this.NotificationSettings.MailTypes.split(',').map((type: any) => type.trim());
  //     this.SettingsData?.forEach(setting => {
  //       if (this.NotificationSettings.MailTypes.includes(setting.Id)) {
  //         setting.isChecked = true;
  //       }
  //     });

  //     if (this.NotificationSettings.MailTypes.length == 0 && this.toggleBtnCheck == false) {
  //       this.EmailNotification = true;
  //       this.toggleBtnCheck = false;
  //     } else {
  //       this.EmailNotification = true;
  //       this.toggleBtnCheck = true;
  //     }
  //     // }
  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on BellNotificationDataList.');
  //     });
  // }

  // GetBellNotifications() {
    
  //   this.homeService.GetBellNotifications().subscribe(res => {
  //       this.BellNotificationData = res.Data;
  //       if(this.BellNotificationData == null || res.Message == "No data found"){
  //         this.BellCounts = 0;
  //       } else {
  //         this.BellCounts = this.BellNotificationData.filter((x:any) => x.IsRead == false).length;
  //         this.BellNotificationData.forEach((item:any) => {
  //           let NameSplit = item.CreatedBy.split(",");
  //           item.FirstLabel = NameSplit[1].split("");
  //           item.SecondLabel = NameSplit[0].split("");
  //           item.ModifiedDate = moment(item.CreatedDate).fromNow(true);
  //         })
  //       }
        
  //     this.IsLoading = false;
  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on BellNotificationDataList.');
  //     });
  // }

  // onClickRead(data: any) {
  //   this._router.navigate(['/home']);
  //   this.BellNotificationsId = data.BellNotificationsId;
  //   this.homeService.UpdateBellNotificationReadStatus(this.BellNotificationsId).subscribe(res => {
  //     this.BellNotificationReadData = res.Data;
  //     const OpportunityMasterIDs = data.OpportunityMasterId;
  //     this.GetBellNotifications();
  //     this.onClick(OpportunityMasterIDs);
  //     this.CloseNotify();
  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on BellNotificationReadDataList.');
  //     });
  // }

  // MarkAllRead() {
  //   this.homeService.UpdateBellNotificationReadStatus(0).subscribe(res => {
  //     this.BellNotificationReadData = res.Data;
  //     this.GetBellNotifications();
  //     this.CloseNotify();
  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on BellNotificationReadDataList.');
  //     });
  // }

  OnClickToggle(toggleBtnCheck: any) {
    if (this.toggleBtnCheck == true) {
      this.getMailType();
    } else {
      // this.OnClickSettings();
    }

  }



  onClickCheckbox() {
  }

  EmailNotifySave() {
    this.CloseNotify();
    this.CheckedValue = [];
    this.SettingsData?.forEach((item) => {
      if (item.isChecked) {
        this.CheckedValue.push(item.Id);
      }
    });
    let data = {
      NotificationSettings: !isNullOrUndefined(this.NotificationSettings) ? this.NotificationSettings.NotificationSettingsId : 0,
      EmailId: !isNullOrUndefined(this.NotificationSettings) ? this.NotificationSettings.EmailId : 0,
      EmailsEnabled: this.toggleBtnCheck,
      MailTypes: this.CheckedValue.join(', ')
    }
    this.IsLoading = true;
    // this.homeService.InsertUpdateNotificationSettings(data).subscribe(res => {
    //   this.NotificationReadData = res.Data;
    //   this.SavedPopupFn();
    //   if (this.NotificationReadData == null) {
    //     // this.InformationPopupFn();
    //   }
    //   this.IsLoading = false;

    // },
    //   err => {
    //     this.IsLoading = false;
    //     this.InformationPopupFn();
    //     console.log('Error on InsertUpdateNotificationSettings.');
    //   });
  }

  // GetNotificationSettings() {
  //   this.homeService.GetNotificationSettings().subscribe(res => {
  //     if (res !== undefined && res !== null && res.Status === 200) {
  //       this.NotificationSettings = res.Data;
  //     }
  //   },
  //     err => {
  //       this.IsLoading = false;
  //       console.log('Error on BellNotificationDataList.');
  //     });
  // }

  ClearCheckBoxData() {
    const mailTypesNewArray = this.NotificationSettings.MailTypes.split(',').map((type: any) => type.trim());
    this.SettingsData?.forEach(setting => {
      if (!mailTypesNewArray.includes(setting.Id)) {
        setting.isChecked = false;
      } else {
        setting.isChecked = true;
      }
    });
  }

  SavedPopupFn() {
    this.openModal('SuccessPopup');
    this.modalNotification = {
      header: '',
      size: 'modal-md modal-dialog-centered',
      width: 'auto',
      height: 'auto'
    };
  }

  InformationPopupFn() {
    this.openModal('InformationPopup');
    this.modalNotification = {
      header: '',
      size: 'modal-md modal-dialog-centered',
      width: 'auto',
      height: 'auto'
    };
  }

  //#region Session
  openSessionPopup(): void {
    this.displaySessionExp = true;
  }

  closeSessionPopup(event: any): void {
    this.displaySessionExp = false;

    if (event.extend) {
      this.resetSession(true);
    }
    else {
      this.endSession();
    }
  }

  setSessionExpiry(): void {
    // sets an idle timeout of 15 minutes.
    this.idle.setIdle(environment.sessionexpiry * 60);

    // sets a timeout period of 5 minutes.
    this.idle.setTimeout(environment.sessionexpirywarning * 60);

    // // for testing
    //this.idle.setIdle(.1 * 60);
    //this.idle.setTimeout(.1 * 60);

    // sets the interrupts like Keydown, scroll, mouse wheel, mouse down, and etc
    this.idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);
    // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NO_LONGER_IDLE';
      console.log('session no longer idle');
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.timedOut = true;
      console.log('session timeout');

      // this.idle.onIdleStart.unsubscribe();
      // this.closeSessionPopup({ extend: false });

      this.closeSessionPopup({ extend: true });
    });

    this.idle.onIdleStart.subscribe(() => {
      this.session = { count: 1 };
      this.idleState = 'IDLE_START', this.openSessionPopup();
      console.log('session idle start');
    });

    this.idle.onTimeoutWarning.subscribe((countdown: any) => {
      this.idleState = 'IDLE_TIME_IN_PROGRESS';
      this.session = {
        count: (Math.floor((countdown - 1) / 60) + 1),
        progressCount: this.reverseNumber(countdown),
        countMinutes: (Math.floor(countdown / 60)),
        countSeconds: countdown % 60,
        maxCount: this.idle.getTimeout()
      };

      console.log('session idle in-progress');
    });

    this.resetSession();
  }

  // ngOnDestroy() {
  //   //this.endSession();
  // }

  reverseNumber(countdown: number) {
    // return (5*60 - (countdown - 1));
    return (this.idle.getTimeout() - (countdown - 1));
  }

  resetSession(withsave?: boolean) {
    console.log('session reset');

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;

    if (withsave) {
      let curcomp = this._appSharedService.getCurrentComponent();
      if (!isNullOrUndefined(curcomp)) {
        // let element = document.getElementsByTagName(curcomp['Component']);
        let compref: any = curcomp['ComponentReference'];
        compref.saveClick();
      }
    }
  }

  endSession() {
    console.log('session end');

    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();

    // window.close();
    //this._router.navigate(['sessionexpired']);

    window.location.reload();

  }
  //#endregion

}