import {
  AfterViewChecked, ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter,
  Input, OnInit, Output, Renderer2, ViewEncapsulation, ViewChild, OnChanges, SimpleChanges
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { isNullOrUndefined } from '../_services/utility.service';
import { RowLockStatus } from '../../app/app.interface';
import { FieldEventData, FieldTemplate, ListItem, LoadedUrl } from '../_classes/utility.interface';
import { UtilityService } from '../_services/utility.service';
import { FieldControlService } from './fieldcontrol.service';
import {
  NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n,
  NgbDateParserFormatter, NgbDate, NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter, I18n, CustomDatepickerI18n } from './customdatepicker.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { AppSharedService } from 'src/app/shared/app.sharedservice';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'field-control',
  templateUrl: './fieldcontrol.html',
  styleUrls: ['./fieldcontrol.component.scss'],

  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
  { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class FieldControlComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() public parentRandomId: any;
  @Input() public item: any;
  @Input() public key!: FieldTemplate;
  @Input() public item_indx!: any;
  @Input() public key_indx!: any;
  @Input() public selectedCell!: string;
  @Input() public initedit!: boolean;
  @Input() cellLock!: Function;
  @Input() parent: any;
  @Input() parentNode: any;
  @Input() public showcontrolalways?: boolean;
  @Output() public fldClick = new EventEmitter<any>();
  @Output() public dealclick = new EventEmitter<any>();
  @Output() public NextActionclick = new EventEmitter<any>();
  @Output() public fldChange = new EventEmitter<any>();
  @Output() public setSelectedCell = new EventEmitter<any>();
  @Output() public fldonEditStart = new EventEmitter<any>();

  @ViewChild('select', { static: false }) selectctrl!: NgSelectComponent;
  @ViewChild('d', { static: false }) datectrl!: NgbInputDatepicker;

  public editCell: boolean = false;
  // public dispMulti: boolean = false;
  public selectedMulti: any[] = [];
  // public TypeAheadlist: any[] = [];
  public dispLoader: boolean = false;
  public pagedisplay: boolean = false;
  public compdata: any;
  public addTagNowRef: (name: any) => void;
  private prevValue: any;
  public placeholder: string = 'Select';
  public disableCtrl: boolean = false;
  public OpenDocumetsicon: string = '';
  public fieldRandomId: string;
  public commentplaceholder: string = '';
  OPMID: any;
  public uploadedFile: any;

  // constructor
  constructor(private _fieldControlService: FieldControlService, private _elioUtilityService: UtilityService,
    private hostElement: ElementRef, private renderer2: Renderer2,
    private cdRef: ChangeDetectorRef, public translateService: TranslateService, private datePipe: DatePipe,
    public sanitizer: DomSanitizer, public _appSharedService: AppSharedService) {

    this.addTagNowRef = (name) => this.typeaheadAddTag(name);

    this.fieldRandomId = Math.random().toString(36).substr(2, 5);

    // this.translateService.AppDateFormat 
    type Indexer<T> = { [key: string]: T };
  }

  ngOnInit() {

    this.initedit = (isNullOrUndefined(this.initedit) ? false : this.initedit);
    this.showcontrolalways = (isNullOrUndefined(this.showcontrolalways) ? false : this.showcontrolalways);

    // Convert date strings to date object
    if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type.toLowerCase() === 'datepicker') {
      this.item[this.key.FieldName] = (!isNullOrUndefined(this.item[this.key.FieldName]) ? new Date(this.item[this.key.FieldName]) : null);

      this.key.EditValidate.Min = (!isNullOrUndefined(this.key.EditValidate.Min))
        ? new Date(this.key.EditValidate.Min) : null;

      this.key.EditValidate.Max = (!isNullOrUndefined(this.key.EditValidate.Max))
        ? new Date(this.key.EditValidate.Max) : null;
    }
    this.placeholder = (!isNullOrUndefined(this.key) && !isNullOrUndefined(this.key.Control)
      && !isNullOrUndefined(this.key.Control?.PlaceHolderText))
      ? this.key.Control?.PlaceHolderText! : 'Select';

    if (!isNullOrUndefined(this.key.Control) && isNullOrUndefined(this.key.Control!.LoadedUrl)) {
      this.key.Control!.LoadedUrl = [];
    }

    if (this.key.Control?.Type === 'typeahead' && (!isNullOrUndefined(this.key.Control.DynamicLoad) && this.key.Control.DynamicLoad)) {
      this.item[this.key.FieldName + 'SearchText'] = (isNullOrUndefined(this.item[this.key.FieldName]) ? '' : this.item[this.key.FieldName]);
    }

    // Call setEdit explicitly as the field is loaded in edit mode initialy
    if (this.initedit) { this.setCtrlEdit(); }

    this.prevValue = this.item[this.key.FieldName];

    this.disableCtrl = !this.enableCell();
    if (this.showcontrolalways && !this.editCell) {
      this.ctrlLoad();
    }

  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('changes >> ');
    // console.log(changes);

    // if (this.initedit) {
    // Change in template
    if (!isNullOrUndefined(changes.key) && changes.key.previousValue !== changes.key.currentValue) {
      // Convert date strings to date object
      if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type.toLowerCase() === 'datepicker') {
        this.key.EditValidate.Min = (!isNullOrUndefined(this.key.EditValidate.Min))
          ? new Date(this.key.EditValidate.Min) : null;

        this.key.EditValidate.Max = (!isNullOrUndefined(this.key.EditValidate.Max))
          ? new Date(this.key.EditValidate.Max) : null;
      }
    }

    // Change in data
    if (!isNullOrUndefined(changes.item) && changes.item.previousValue !== changes.item.currentValue) {
      // Convert date strings to date object
      if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type.toLowerCase() === 'datepicker') {
        this.item[this.key.FieldName] = (!isNullOrUndefined(this.item[this.key.FieldName]) ?
          new Date(this.item[this.key.FieldName]) : null);

      }

      if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type.toLowerCase() === 'dropdown') {
        if (this.key.Control.InputType === 'multi') {
          this.setMultiSelectedList();
        }
      }
    }
    // }

  }


  parseURL(): string {

    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.Source) &&
      !isNullOrUndefined(this.key.Control?.Source?.Url)) {

      return this._elioUtilityService.ParsingURL(
        this.key.Control?.Source?.Url!,
        this.key.Control?.Source?.UrlAttribute!,
        this.item
      );
    }
    else {
      return '#';
    }
  }

  compareWithFn(listitem: any, selected: any): boolean {
    // NOTE: This function is written to avoid type checking between selected data and listitem in dropdown.
    // tslint:disable-next-line: triple-equals

    type Indexer<T> = { [key: string]: T };
    // just a reference to 'this' at runtime
    var indexer = this as unknown as Indexer<any>;


    return listitem[(this as any)['bindValue']] == selected;
  }

  ctrlLoad(showdropdown: boolean = false): void {
    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.Type) &&
      (this.key.Control?.Type === 'dropdown' || this.key.Control?.Type === 'link' ||
        this.key.Control?.Type === 'typeahead' || this.key.Control?.Type === 'radio') &&
      !isNullOrUndefined(this.key.Control.Source)) {

      let url: any = this.key.Control.Source?.Url;
      if ((this.key.Group == "EditDealCategory")) {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;

      }
      if (this.key.Group == "AddTeamMember" && this.key.FieldName == "Role") {
        url = `${url}${this.item.OpportunityMasterID}`;

      }
      if (this.key.Group == "EditCategorization") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if (this.key.Group == "EditRiskAssessment") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if (this.key.Group == "EditDealQualification") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if (this.key.Group == "EditSolution") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if (this.key.Group == "EditProposal") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if (this.key.Group == "EditContractualReview") {

        url = `${url}${this.item.OpportunityMasterID.toString()}`;
      }
      if ((this.key.Group == "GetDealQualificationAnswerDropdown") || (this.key.Group == "GetDealQualificationAnswerRadio")) {

        url = `${url}${this.item.QuestionCode.toString()}`;
      }
      // if source url present, then get it from service
      if (!isNullOrUndefined(this.key.Control.Source?.Url) && this.key.Control.Source!.Url.length > 0) {

        if (!isNullOrUndefined(this.key.Control.Source?.UrlAttribute) &&
          this.key.Control.Source!.UrlAttribute!.length > 0) {

          url = this._elioUtilityService.ParsingURL(
            url,
            this.key.Control.Source?.UrlAttribute!,
            this.item,
          );
        }

        /// Checking if already loaded data is same as the current request
        if (!this.checkUrlDataExists(url) ||
          (!isNullOrUndefined(this.key.Control.DynamicLoad) && this.key.Control.DynamicLoad)) {

          this.key.Control.List = [];
          this.dispLoader = true;

          this._fieldControlService.getCodeData(url).subscribe(data => {
            if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(data) && data.Data.length > 0) {
              this.key.Control!.List = data.Data;
              //this.key.Control.LoadedUrl = url;

              let indx: any = this.key.Control?.LoadedUrl?.findIndex(x => x.Url === url);
              if (!isNullOrUndefined(indx) && indx >= 0) {
                this.key.Control?.LoadedUrl?.splice(indx, 1);
              }

              this.key.Control?.LoadedUrl?.push({ Url: url, List: data.Data });

              if (this.key.Control?.InputType === 'multi') {
                this.setMultiSelectedList();
              }

              // if (this.key.Control.Type === 'typeahead') {
              //   this.SetTypeHeadList();
              // }

            }

            this.dispLoader = false;

            this.cdRef.detectChanges();

            if (!this.initedit || showdropdown) {
              setTimeout(() => {
                if (!isNullOrUndefined(this.selectctrl)) {
                  this.selectctrl.open();
                }
              }, 20);
            }

          },
            err => { console.log('error @ control load'); this.dispLoader = false; }
          );
        }

      }


    }
  }


  ctrlClick(): void {

    //console.log('ctrlclick -- Start');

    if (this.initedit && this.ParallelEditCellIsNotLocked()) {
      this.ctrlLoad();
    }

    /// Anomonous data for Click emit event -- Start
    let eventdata = {
      fieldname: this.key.FieldName,
      item: this.item
    } as FieldEventData;
    this.fldClick.emit(eventdata);

    let eventdata1 = {
      
      fieldname: this.item[this.key.FieldName],
      item: this.key.Cssnames
    } as FieldEventData;
    //this.dealclick.emit(eventdata1);
    if (eventdata1.item == 'cellLink') {
      

    //this.dealclick.emit(this.item[this.key.FieldName]);
    this.NextActionclick.emit(eventdata1);

    }
  }
  iconClick() {
    let eventdata = {
      fieldname: 'ShowDocuments',
      item: this.item
    } as FieldEventData;
    this.fldClick.emit(eventdata);

  }
  FileUpload(event: any) {
    let eventdata = {
      fieldname: 'UploadDocuments',
      item: this.item,
      inputfile: event,
    } as FieldEventData;
    this.fldClick.emit(eventdata);
    this.uploadedFile = null;
  }
  
  DeleteFolderClick(data:any){
    let eventdata = {
      fieldname: 'DeleteDocuments',
      item: this.item,
      inputfile:data
    } as FieldEventData;
    this.fldClick.emit(eventdata);
  }

  DownloadDoc(data:any){
    let eventdata = {
      fieldname: 'DocumentsDownLoad',
      item: this.item,
      inputfile:data
    } as FieldEventData;
    this.fldClick.emit(eventdata);
  }

  // ctrlIconClick1(): void {
  //   console.log('inside icon click');
  // }

  // iconClick(event): void {
  //   console.log('inside icon click');

  //   if (!isNullOrUndefined(event)) {
  //     console.log('event >> ' + JSON.stringify(event));

  //     let eventdata = {
  //       fieldname: this.key.FieldName,
  //       item: this.item,
  //       icon: { 'IconCSS': event.iconname, 'Value': '0' }
  //     };

  //     this.fldClick.emit(eventdata);
  //   }
  // }

  ctrlIconClick(icondetail: any): void {
    if (this.initedit && this.ParallelEditCellIsNotLocked()) {
      this.ctrlLoad();
    }

    /// Anomonous data for Click emit event -- Start
    let eventdata = {
      fieldname: this.key.FieldName,
      item: this.item,
      icon: icondetail
    };

    this.fldClick.emit(eventdata);
    /// Anomonous data for Click emit event -- End

  }
  public onAddComment(event: any): void {
    let eventdata = { fieldname: this.key.FieldName, item: this.item, oldValue: this.prevValue } as FieldEventData;
    this.fldChange.emit(eventdata);
  }
  ctrlChange(): void {
    let validchange: boolean = true;

    if (!isNullOrUndefined(this.key.Control) && (this.key.Control?.Type.toLowerCase() === 'textbox' &&
      this.key.Control?.InputType?.toLowerCase() === 'number')) {
      validchange = this.numberValidation();
    }
    /// Setting Dropdown data to linked field
    if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type === 'dropdown') {

      if (this.item[this.key.FieldName] === 'null') {
        this.item[this.key.FieldName] = null;
      }
    }
    /// Setting Dropdown data to linked field
    if (!isNullOrUndefined(this.key.Control) && (this.key.Control?.Type === 'dropdown' ||
      this.key.Control?.Type === 'typeahead' || this.key.Control?.Type === 'radio') &&
      !isNullOrUndefined(this.key.LinkedField)) {

      if (!isNullOrUndefined(this.key.LinkedField) && this.key.LinkedField!.length > 0) {
        // this.item[this.key.LinkedField] = this.key.Control.List.filter(x => x.Code === this.item[this.key.FieldName])[0].CodeDescription;

        let listkey: string; let listdesc: string;
        if (!isNullOrUndefined(this.key.Control.Source)) {
          listkey = this.key.Control?.Source?.KeyField?.toString() || '';
          listdesc = this.key.Control?.Source?.ValueField?.toString() || '';
        }
        else {
          listkey = 'Code'; listdesc = 'CodeDescription';
        }

        this.item[this.key.LinkedField!] = null;
        // this.item[this.key.LinkedField] = this.key.Control.List.filter(x =>
        //   x[listkey] === this.item[this.key.FieldName])[0][listdesc];

        if (!isNullOrUndefined(this.item[this.key.FieldName])) {
          let curlistitem: any[] = this.key.Control?.List?.filter((x: any) => x[listkey].toString() === this.item[this.key.FieldName].toString()) || [];
          if (!isNullOrUndefined(curlistitem) && curlistitem.length > 0) {
            this.item[this.key.LinkedField!] = curlistitem[0][listdesc];
          }
        }
      }

    }

    /// TODO: Clear dependent fields data
    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.DependentControl) &&
      this.key.Control!.DependentControl!.length > 0) {

      // for (let index in this.key.Control?.DependentControl) {
      //   this.item[this.key.Control?.DependentControl[index]] = null;
      // }
      this.key.Control?.DependentControl?.forEach((index: any) => {
        this.item[index] = null;
      })

      if (!isNullOrUndefined(this.key.Control) && (this.key.Control?.Type.toLowerCase() === 'dropdown' &&
        !isNullOrUndefined(this.key.Control.InputType) && this.key.Control.InputType?.toLowerCase() === 'multi')) {
      }
    }



    // if (validchange) {
    /// Anomonous data for Click emit event -- Start
    let eventdata = { fieldname: this.key.FieldName, item: this.item, oldValue: this.prevValue } as FieldEventData;
    this.fldChange.emit(eventdata);
    /// Anomonous data for Click emit event -- End
    // }
    this.prevValue = this.item[this.key.FieldName];
  }

  numberValidation(): boolean {
    let validchange: boolean = true;

    if (!isNullOrUndefined(this.key.EditValidate.Min) && !isNaN(this.key.EditValidate.Min)) {
      validchange = (this.item[this.key.FieldName] >= parseInt(this.key.EditValidate.Min) ? true : false);
      // if (!validchange) { this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Min) }

      // set default value on validation failure
      if (!validchange) {
        // DefaultValue?: string;    // options - 'MinMax'(default prop), 'Previous', 'Empty'
        if (!isNullOrUndefined(this.key.EditValidate.DefaultValue)) {
          switch (this.key.EditValidate?.DefaultValue?.toLowerCase()) {
            case 'previous':
              this.item[this.key.FieldName] = this.prevValue;
              break;
            case 'empty':
              this.item[this.key.FieldName] = null;
              break;
            case 'minmax':
            default:
              this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Min);
              break;
          }
        }
        else {
          this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Min);
        }
      }

    }

    if (validchange && !isNullOrUndefined(this.key.EditValidate.Max) && !isNaN(this.key.EditValidate.Max)) {
      validchange = (this.item[this.key.FieldName] <= parseInt(this.key.EditValidate.Max) ? true : false);
      // if (!validchange) { this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Max) }

      // set default value on validation failure
      if (!validchange) {
        // DefaultValue?: string;    // options - 'MinMax'(default prop), 'Previous', 'Empty'
        if (!isNullOrUndefined(this.key.EditValidate.DefaultValue)) {
          switch (this.key.EditValidate?.DefaultValue?.toLowerCase()) {
            case 'previous':
              this.item[this.key.FieldName] = this.prevValue;
              break;
            case 'empty':
              this.item[this.key.FieldName] = null;
              break;
            case 'minmax':
            default:
              this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Max);
              break;
          }
        }
        else {
          this.item[this.key.FieldName] = parseInt(this.key.EditValidate.Max);
        }
      }
    }

    // set current value as previous value for validating on next change
    if (validchange) { this.prevValue = this.item[this.key.FieldName]; }

    return validchange;
  }

  cellOnFocus(event: any) { }
  cellOnFocusOut(event: any) { }

  ctrlOnEditStart() {
    /// Anomonous data for Edit Start emit event -- Start
    let eventdata = {
      fieldname: this.key.FieldName,
      item: this.item
    } as FieldEventData;

    this.fldonEditStart.emit(eventdata);
    /// Anomonous data for Edit Start emit event -- End
  }

  ctrlKey(event: any) {
    //console.log('onkey event in fields >> ' + JSON.stringify(event.keyCode));

    // event.preventDefault();

    // let eventdata = { row: this.item_indx, col: this.key_indx, keyCode: event.keyCode };
    // this.fldNav.emit(eventdata);
  }

  resetEditMode(event: any) {
    this.parentNode.editMode = false;
    this.editCell = false;
    this.enableCell();

    event.stopPropagation();
  }

  setCtrlEdit() {
    this.parentNode.editMode = true;
    this.setSelectedCell.emit({ row: this.item_indx, col: this.key_indx, itemdata: this.item });
    this.showEditCtrl();
  }

  setSelected() {
    this.setSelectedCell.emit({ row: this.item_indx, col: this.key_indx, itemdata: this.item });
  }

  showEditCtrl() {
    if (this.parentNode.editMode) {
      // this.dispMulti = false;
      // this.resetMultisel();

      // Checkif both row and column are editable.
      if (this.ParallelEditCellIsNotLocked() && !isNullOrUndefined(this.key) && !isNullOrUndefined(this.item)
        && this.key.Editable && this.item.IsEditable) {
        // Check for cell level logic if defined in the template
        if (isNullOrUndefined(this.key.DataCell) || isNullOrUndefined(this.key.DataCell?.CustomLock)) {
          this.editCell = true;
        }
        else {
          // In this case, call the parent function which returns if the cell is editable based
          // on the row data and other custom conditions built in parent component.
          if (!isNullOrUndefined(this.item['CellLock'])) {
            this.editCell = !(this.item['CellLock'].filter((x: any) => x.FieldName === this.key.FieldName).map((y: any) => y.Lock)[0]);
          }
          else {
            this.editCell = !this.cellLock(this.key.FieldName, this.item, this.parent);
          }

        }

        // // for testing
        // this.editCell = !this.cellLock(this.key.FieldName, this.item, this.parent);

        if (this.editCell) {
          // check if any other cell is editable and revert it
          this.setSelectedCell.emit({ row: this.item_indx, col: this.key_indx, itemdata: this.item });

          // Load the drop-down list on enabling the cell.
          this.ctrlLoad();

          // if (!isNullOrUndefined(this.key.Control) && this.key.Control.Type === 'dropdown' &&
          //   this.key.Control.InputType === 'multi') {
          if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type === 'dropdown') {

            if (this.key.Control.InputType === 'multi') {
              // this.dispMulti = true;
              // this.showMultisel();
              this.setMultiSelectedList();
            }

            if (!this.initedit) {
              setTimeout(() => {
                if (!isNullOrUndefined(this.selectctrl)) {
                  this.selectctrl.open();
                }
              }, 20);
            }

          }

          if (!isNullOrUndefined(this.key.Control) && this.key.Control?.Type === 'datepicker' &&
            !isNullOrUndefined(this.key.EditValidate.DisableDates)) {
            setTimeout(() => {
              if (!isNullOrUndefined(this.datectrl)) {
                this.updateDisabledDates();
              }
            }, 20);
          }

          this.ctrlOnEditStart();
        }
        else {
          this.parentNode.editMode = false;

          if (this.showcontrolalways) {
            // Load the drop-down list on enabling the cell.
            this.ctrlLoad();
          }
        }
      }
      else {
        this.editCell = false;
        // this.initedit = false;
        // // check if any other cell is editable and revert it
        // this.setSelectedCell.emit();
        this.parentNode.editMode = false;
      }
    }

    this.disableCtrl = !this.editCell;
  }

  enableCell(): boolean {
    // let flag = true;
    // let flag = false;

    // Taking this prop from parent

    // let flag = this.initedit && this.editCell && this.ParallelEditCellIsNotLocked();
    let flag = (this.initedit && this.editCell) || (this.editCell && this.ParallelEditCellIsNotLocked());

    // console.log('enableCell selectedcell >> ' + this.selectedCell);

    if (!isNullOrUndefined(this.selectedCell)) {
      // flag = false;

      if (this.selectedCell === this.item_indx + '-' + this.key_indx && this.editCell && this.parentNode.editMode) {
        flag = true;
      }
    }

    let td = (this.hostElement.nativeElement).closest('td') as HTMLTableCellElement;
    if (!isNullOrUndefined(td)) {
      if (flag) {
        if (!td.classList.contains('editableCell')) {
          td.classList.add('editableCell');
        }
      }
      else {
        if (td.classList.contains('editableCell')) {
          td.classList.remove('editableCell');
        }
      }
    }

    this.disableCtrl = !flag;


    return flag;
  }

  ParallelEditCellIsNotLocked(): boolean {
    let parallelEditCellIsNotLocked: boolean = true;

    // if (!isNullOrUndefined(this.item) && !isNullOrUndefined(this.item.DataRowlock) &&
    // this.item.DataRowlock.RowLockStatus === RowLockStatus.Locked) {
    if (!isNullOrUndefined(this.item) && this.item.RowLockStatus === RowLockStatus.Locked) {
      parallelEditCellIsNotLocked = false;
    }

    return parallelEditCellIsNotLocked;
  }

  enableAction(): boolean {
    // Save => Should check for any change in the grid cells
    // Delete => Should be enabled always
    let retflg = true;

    if (this.key.Control?.Type.toLowerCase() === 'icon'
      || this.key.Control?.Type.toLowerCase() === 'radio'
      || this.key.Control?.Type.toLowerCase() === 'checkbox' || this.key.Control?.Type.toLowerCase() === 'button') {

      // Checkif both row and column are editable.
      if (this.key.Editable && this.item.IsEditable) {
        // Check for cell level logic if defined in the template
        if (isNullOrUndefined(this.key.DataCell) || isNullOrUndefined(this.key.DataCell?.CustomLock)) {
          this.editCell = true;
        }
        else {
          // // In this case, call the parent function which returns if the cell is editable based
          // // on the row data and other custom conditions built in parent component.
          if (!isNullOrUndefined(this.item['CellLock'])) {
            this.editCell = !(this.item['CellLock'].filter((x: any) => x.FieldName === this.key.FieldName).map((y: any) => y.Lock)[0]);
          }
          else {
            this.editCell = !this.cellLock(this.key.FieldName, this.item, this.parent);
          }
        }

        // if (!this.editCell) {
        //   this.parentNode.editMode = false;
        // }
      }
      else {
        this.editCell = false;
        // this.parentNode.editMode = false;
      }

      retflg = this.editCell;
    }

    this.disableCtrl = !this.editCell;
    return retflg;
  }

  // // Convert date string to date
  // StringToDate(dateStr: string) {
  //   return (!isNullOrUndefined(dateStr) ? new Date(dateStr) : '');
  // }

  setMultiSelectedList() {
    let selstr: string = this.item[this.key.FieldName];

    this.selectedMulti = [];

    // // After upgrading the ng-select component to ver 7.2.0, the selectedMulti should be bound 
    // // with Id array itself instead of Desc array.
    // // Hence commented the below code as it is no more required 

    // let listkey: string; let listdesc: string;
    // if (!isNullOrUndefined(this.key.Control?.Source)) {
    //   listkey = this.key.Control?.Source?.KeyField?.toString() || '';
    //   listdesc = this.key.Control?.Source?.ValueField?.toString() || '';
    // }
    // else {
    //   listkey = 'Code'; listdesc = 'CodeDescription';
    // }

    // if (!isNullOrUndefined(selstr) && !isNullOrUndefined(this.key.Control)
    //   && !isNullOrUndefined(this.key.Control?.List) && this.key.Control!.List!.length > 0) {
    //   let temp: any = this.key.Control?.List?.filter((x: any) => (',' + selstr + ',')
    //     .indexOf(',' + x[listkey] + ',') >= 0);
    //   if (temp.length > 0) {
    //     // this.selectedMulti = temp;
    //     // this.selectedMulti = temp.map((x: any) => x[listdesc]);
    //     this.selectedMulti = temp.map((x: any) => x[listkey]);
    //   }
    // }

    if (!(isNullOrUndefined(selstr)) && selstr.length > 0) {
      this.selectedMulti = selstr.split(',');
    }

  }

  // SetTypeHeadList() {
  //   if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control.List) && this.key.Control.List.length > 0) {
  //     this.TypeAheadlist = this.key.Control.List;
  //   }
  // }

  setMultiSelectedStr() {
    let selCode: string = '';
    let selCodeDesc: string = '';
    let selList: any = [];
    //console.log('selectedMulti >>' + JSON.stringify(this.selectedMulti));


    // if (this.key.Control.InputType !== 'multi') {
    //   selList.push(this.selectedMulti);
    // }
    // else {
    selList = this.selectedMulti;
    // }

    let listkey: string; let listdesc: string;
    if (!isNullOrUndefined(this.key.Control?.Source)) {
      listkey = this.key.Control?.Source?.KeyField?.toString() || '';
      listdesc = this.key.Control?.Source?.ValueField?.toString() || '';
    }
    else {
      listkey = 'Code'; listdesc = 'CodeDescription';
    }

    // this.selectedMulti.forEach(row => {
    //   // selCode = selCode + ',' + row[this.key.Control.Source.KeyField];
    //   // selCodeDesc = selCodeDesc + ',' + row[this.key.Control.Source.ValueField];
    selList.forEach((row: any) => {
      selCode = selCode + ',' + row;
      let temp: any = this.key.Control?.List?.filter((x: any) => x[listkey] === row)[0];
      selCodeDesc = selCodeDesc + ',' + temp[listdesc];
    });

    this.item[this.key.FieldName] = selCode.substring(1);

    if (!isNullOrUndefined(this.key.LinkedField) && this.key.LinkedField!.length > 0) {
      this.item[this.key.LinkedField!] = selCodeDesc.substring(1);
    }
  }

  multiOnChange($event: any) {
    //console.log($event);

    this.setMultiSelectedStr();

    /// TODO: Clear dependent fields data
    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.DependentControl)
      && this.key.Control!.DependentControl!.length > 0) {
      // for (let index in this.key.Control?.DependentControl) {
      //   this.item[this.key.Control?.DependentControl[index]] = null;
      // }
      this.key.Control?.DependentControl?.forEach((index: any) => {
        this.item[index] = null;
      })
    }

    /// Anomonous data for Click emit event -- Start
    let eventdata = { fieldname: this.key.FieldName, item: this.item } as FieldEventData;
    this.fldChange.emit(eventdata);
    /// Anomonous data for Click emit event -- End

  }

  displayPageTab(url: string, title: string): void {
    url = this.parseURL();
    this.renderer2.addClass(document.body, 'bodyOverflow');
    this.pagedisplay = true;
    this.compdata = { View: 'page', Title: title, Type: 1, Content: url };
  }

  closePageTab(url: string): void {
    this.renderer2.removeClass(document.body, 'bodyOverflow');
    this.pagedisplay = false;
  }

  getIconCSSClass(): any {
    let iconclass: string = '';
    let temp;

    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.IconList)
      && this.key.Control!.IconList!.length > 0) {

      if (!isNullOrUndefined(this.key.LinkedField) && this.key.LinkedField!.length > 0) {
        // tslint:disable-next-line: triple-equals
        temp = this.key.Control!.IconList!.filter(x => x.Value == this.item[this.key.LinkedField!]);
      }
      else {
        // NOTE: Disabled type checking since the template value and item value may of different types.
        // let temp = this.key.Control.IconList.filter(x => x.Value === this.item[this.key.FieldName]);
        // tslint:disable-next-line: triple-equals
        temp = this.key.Control!.IconList!.filter(x => x.Value == this.item[this.key.FieldName]);
      }

      if (temp.length > 0) {
        iconclass = temp[0].IconCSS;
      }
    }

    return iconclass;
  }

  getIconCSSClassList(): any {

    let icondetail: any[] = [];
    let valuelist: string[];

    if (!isNullOrUndefined(this.key.LinkedField) && this.key.LinkedField!.length > 0) {
      if (!isNullOrUndefined(this.item[this.key.LinkedField!])) {
        valuelist = (this.item[this.key.LinkedField!]).toString().split(',');

        if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.IconList)
          && this.key.Control!.IconList!.length > 0) {
          icondetail = this.key.Control!.IconList!.filter(x => valuelist.includes(x.Value));
          // icondetail = this.key.Control.IconList.filter(x => { valuearray.includes(x.Value) });

        }

      }
    }
    else {
      if (!isNullOrUndefined(this.item[this.key.FieldName])) {
        valuelist = (this.item[this.key.FieldName]).toString().split(',');

        // let valuearray: any[] = [];
        // valuelist.forEach(val => { valuearray.push({ Value: val }) });

        if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.IconList)
          && this.key.Control!.IconList!.length > 0) {
          icondetail = this.key.Control!.IconList!.filter(x => valuelist.includes(x.Value));
          // icondetail = this.key.Control.IconList.filter(x => { valuearray.includes(x.Value) });
        }

      }
    }

    return icondetail;
  }

  getToolTipContent(): string {
    // let tooltiphtml = '<h6>hello world - ' + this.item.StartDate + ' to ' +  this.item.EndDate + '</h6>';
    let tooltiphtml: string;

    if (isNullOrUndefined(this.key.ToolTipTemplate) || this.key.ToolTipTemplate!.length === 0) {
      let val = this.item[this.key.FieldName];

      // Show Linkedfield value if present
      if (!isNullOrUndefined(this.key.LinkedField) && this.key.LinkedField!.length > 0) {
        val = this.item[this.key.LinkedField!];
      }
      else {
        // Format Date
        if (this.key.Control?.Type === 'datepicker') {
          // val = this.dp.transform(val, this.key.Control.Format);
          // val = this.dp.transform(val);
          val = (isNullOrUndefined(val) ? '' :
            ((this.key.Control.Format != null && this.key.Control.Format != undefined && (this.key.Control.Format.length > 0 &&
              this.key.Control.Format.toLowerCase()) == 'true') ?
              this.datePipe.transform(val, (this.translateService as any)['AppDateTimeFormat'])
              : this.datePipe.transform(val, (this.translateService as any)['AppDateFormat'])));

          // this.datePipe.transform(val, (this.translateService as any)['AppDateFormat']));
          // this.datePipe.transform(val, (this.translateService as any)['AppDateTimeFormat']));
        }
        //commeneted by Anitha
        /////////////////////////////////////////
        // if (this.key.Control?.Type === 'textbox' && this.key.Control.InputType === 'number'
        //   && (isNullOrUndefined(this.key.Control.Format) ||
        //     (this.key.Control.Format!.length > 0 && this.key.Control.Format!.toLowerCase() === 'true'))) {
        //  val = this.np.transform(val);
        // }
        if (this.key.Control?.Type === 'textbox' && this.key.Control.InputType === 'text'
          && this.key.FieldName == 'TCV') {
          val = 'tcv';
          tooltiphtml = val;

        }
        if (this.key.Control?.Type === 'icon' && this.key.Control.InputType === 'info') {
          if (!isNullOrUndefined(this.key.Control.IconList)) {
            // tslint:disable-next-line: triple-equals
            let temp: any = this.key.Control?.IconList?.filter(x => x.Value == this.item[this.key.FieldName]);

            if (temp.length > 0) {
              val = temp[0].Tooltip;
            }
          }

        }

      }

      tooltiphtml = val;
    }
    else {
      tooltiphtml = this.frameTemplate(this.key.ToolTipTemplate);
    }

    return tooltiphtml;
  }


  getLabelContent(): string {
    return (this.frameTemplate(this.key.LabelTemplate));
  }

  frameTemplate(template: any): string {
    let keys: Array<string> = [];
    let temp = template;
    while (temp.indexOf('{{$Item.') >= 0) {
      let name = temp.substring(temp.indexOf('{{$Item.'), temp.indexOf('}}') + 2);
      name = name.replace('{{$Item.', '');
      name = name.replace('}}', '');
      keys.push(name);
      temp = temp.substr(temp.indexOf('}}') + 2);
    }

    let templatestr = template;
    // loop for each Key to be replaced
    for (let j = 0; j < keys.length; j++) {
      templatestr = templatestr.replace('{{$Item.' + keys[j] + '}}', this.item[keys[j]]);
    }

    return templatestr;
  }

  // getTypeAheadAsObservable(token: string): Observable<any> {
  //   const query = new RegExp(token, 'ig');

  //   return of(
  //     this.TypeAheadlist.filter((user: any) => {
  //       return query.test(user.EmployeeName);
  //     })
  //   );
  // }

  typeaheadNoResults(event: boolean): void {
    // this.noResult = event;
    //console.log(event);
  }

  // typeaheadOnSelect(event: TypeaheadMatch): void {
  typeaheadOnSelect(event: any): void {
    // console.log('selected item >>' + JSON.stringify(event.item));

    if (!isNullOrUndefined(event)) {
      // this.item[this.key.FieldName] = event.item[this.key.Control.Source.KeyField];
      // this.item[this.key.LinkedField] = event.item[this.key.Control.Source.ValueField];
      this.item[this.key.FieldName] = event[this.key.Control?.Source?.KeyField?.toString() || ''];
      this.item[this.key.LinkedField!] = event[this.key.Control?.Source?.ValueField?.toString() || ''];
    }
    else {
      this.item[this.key.FieldName] = '';
      this.item[this.key.LinkedField!] = '';
    }

    /// TODO: Clear dependent fields data
    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.DependentControl) &&
      this.key.Control!.DependentControl!.length > 0) {

      // for (let index in this.key.Control?.DependentControl) {
      //   this.item[this.key.Control?.DependentControl[index]] = null;
      // }
      this.key.Control?.DependentControl?.forEach((index: any) => {
        this.item[index] = null;
      })
    }

    /// Anomonous data for Click emit event -- Start
    let eventdata = { fieldname: this.key.FieldName, item: this.item } as FieldEventData;
    this.fldChange.emit(eventdata);
    /// Anomonous data for Click emit event -- End
  }

  typeaheadOnBlur(event: TypeaheadMatch): void {
    // console.log('selected item >>' + JSON.stringify(event.item));

    let listkey: string; let listdesc: string;
    if (!isNullOrUndefined(this.key.Control?.Source)) {
      listkey = this.key.Control?.Source?.KeyField?.toString() || '';
      listdesc = this.key.Control?.Source?.ValueField?.toString() || '';
    }
    else {
      listkey = 'Code'; listdesc = 'CodeDescription';
    }

    // this.item[this.key.LinkedField] = null;
    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.List)
      && this.key.Control!.List!.length > 0) {
      let curlistitem: any = this.key.Control?.List?.filter((x: any) => x[listdesc] === this.item[this.key.LinkedField!]);
      if (curlistitem.length === 0) {
        // Clear Code when given Value not in the list
        this.item[this.key.FieldName] = '';

        this.key.Control!.AllowNewListItem = (isNullOrUndefined(this.key.Control?.AllowNewListItem) ?
          false : this.key.Control?.AllowNewListItem);

        if (!this.key.Control?.AllowNewListItem) {
          this.item[this.key.LinkedField!] = '';
        }
      }
      /// Anomonous data for Click emit event -- Start
      let eventdata = { fieldname: this.key.FieldName, item: this.item } as FieldEventData;
      this.fldChange.emit(eventdata);
      /// Anomonous data for Click emit event -- End
    }

  }

  typeaheadAddTag(name: string) {
    console.log('name = ' + name);

    let listkey: string; let listdesc: string;
    if (!isNullOrUndefined(this.key.Control?.Source)) {
      listkey = this.key.Control?.Source?.KeyField?.toString() || '';
      listdesc = this.key.Control?.Source?.ValueField?.toString() || '';
    }
    else {
      listkey = 'Code'; listdesc = 'CodeDescription';
    }

    let newitem: any = {};

    if (!isNullOrUndefined(this.key.Control)) {
      if (!isNullOrUndefined(this.key.Control?.List) && this.key.Control!.List!.length > 0) {
        let curlistitem: any = this.key.Control?.List?.filter((x: any) => x[listdesc] === name);
        // let newitem: any = Object.assign({}, this.key.Control.List[0]);

        if (curlistitem.length === 0) {
          this.key.Control!.AllowNewListItem = (isNullOrUndefined(this.key.Control?.AllowNewListItem) ?
            false : this.key.Control!.AllowNewListItem);

          // for testing
          this.key.Control!.AllowNewListItem = true;

          if (!this.key.Control?.AllowNewListItem) {
            this.item[this.key.FieldName] = '';
            this.item[this.key.LinkedField!] = '';
          }
          else {
            newitem = Object.assign({}, this.key.Control!.List![0]);
            newitem[listkey] = name;
            newitem[listdesc] = name;
            this.key.Control!.List!.push(newitem);
          }

        }
        else {
          newitem = curlistitem[0];
        }

      }
      else {  // No items in list but still we need to add
        this.key.Control!.AllowNewListItem = (isNullOrUndefined(this.key.Control?.AllowNewListItem) ?
          false : this.key.Control?.AllowNewListItem);

        if (!this.key.Control?.AllowNewListItem) {
          this.item[this.key.FieldName] = '';
          this.item[this.key.LinkedField!] = '';
        }
        else {
          newitem[listkey] = name;
          newitem[listdesc] = name;

          this.key.Control.List = [];
          this.key.Control.List.push(newitem);
        }

      }
    }

    // return { name: name, addTag: true };
    return newitem;

  }

  onDatePickerClose(event: any) {
    let td = (this.hostElement.nativeElement).closest('td') as HTMLTableCellElement;
    if (!isNullOrUndefined(td)) {
      td.focus();

      // // On closing the datepicker pop-up, change the grid cell to non-editable
      // let x = td.querySelector('div.reseteditdiv') as HTMLElement;
      // x.click();

    }
  }

  forceResizeWindow() {
    window.dispatchEvent(new Event('resize'));
  }

  updateDisabledDates() {
    // const randomDay = Math.floor(Math.random() * 100) % 30;
    // this.markDisabled = (date: NgbDate) => {
    //   return date.day === randomDay;
    // };

    this.datectrl.markDisabled = (date: NgbDate) => {
      const d = new Date(date.year, date.month - 1, date.day);
      // return d.getDay() === 0 || d.getDay() === 6;

      let bFlag = false;

      if (!isNullOrUndefined(this.key.EditValidate) && !isNullOrUndefined(this.key.EditValidate.DisableDates)) {
        for (let i = 0; i < this.key.EditValidate.DisableDates!.length; i++) {
          let d1: Date, d2: Date;
          if (!isNullOrUndefined(this.key.EditValidate.DisableDates![i].StartDt) &&
            !isNullOrUndefined(this.key.EditValidate.DisableDates![i].EndDt)) {

            let stdt: Date = new Date(this.key.EditValidate.DisableDates![i].StartDt);
            let endt: Date = new Date(this.key.EditValidate.DisableDates![i].EndDt);

            d1 = new Date(stdt.getFullYear(), stdt.getMonth(), stdt.getDate());
            d2 = new Date(endt.getFullYear(), endt.getMonth(), endt.getDate());

            if (d >= d1 && d <= d2) {
              bFlag = true;
              break;
            }
          }
        }
      }

      return bFlag;
    };

  }

  // dynamic load ng-select
  typeaheadOnSearch(event: any): void {
    // console.log('event >>' + JSON.stringify(event));
    this.item[this.key.FieldName + 'SearchText'] = event.term;

    if ((!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.DynamicLoad) && this.key.Control?.DynamicLoad)
      && (!isNullOrUndefined(event.term) && event.term.length > 2) && (isNullOrUndefined(this.key.Control.AllowNewListItem) || this.key.Control.AllowNewListItem != true)) {
      this.ctrlLoad(true);
    }
  }

  checkUrlDataExists(url: string) {
    let flag = false;

    if (!isNullOrUndefined(this.key.Control) && !isNullOrUndefined(this.key.Control?.LoadedUrl)
      && !isNullOrUndefined(this.key.Control?.LoadedUrl) && this.key.Control!.LoadedUrl!.length > 0) {
      let indx: any = this.key.Control?.LoadedUrl?.findIndex(x => x.Url === url);
      if (indx >= 0) {
        this.key.Control!.List = this.key.Control!.LoadedUrl![indx].List!;
        flag = true;
      }
    }

    return flag;
  }

  ShowRoundPill(item: any): boolean {
    if (item.RoleType == "A") {
      return true;
    }
    return false;
  }

}

@Directive({
  selector: '[AutoFocus]'
})
export class AutofocusDirective implements OnInit {
  // @Input() appAutofocus: boolean;
  private el: any;
  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.focus();
  }

}

@Component({
  selector: 'icon-button',
  template: `<div (click)='onClick()' [class]='classlist'>
             <span class="sr-only">vertical top icon</span>
              <i style='vertical-align: top;' [class]='iconname'></i></div>`,
})
export class IconButtonComponent implements OnInit {
  @Input() classlist!: string;
  @Input() iconname!: string;
  @Input() iconvalue: any;
  @Input() parent: any;

  ngOnInit() {
    // console.log(`onInit: ${this.iconname}`);
  }

  onClick() {
    // alert(`clicked: ${this.iconname}`);

    if (!isNullOrUndefined(this.iconname)) {
      // console.log(`clicked: ${this.iconname}`);
      let eventdata = {
        fieldname: this.parent.key.FieldName,
        item: this.parent.item,
        icon: { IconCSS: this.iconname, Value: this.iconvalue }   // like in iconlist e.g. {'IconCSS': 'fa fa-tasks', 'Value': '1'}
      };
      this.parent.fldClick.emit(eventdata);
    }
  }
}
